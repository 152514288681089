/**
 * Functions directly related to ag-grid, for example event listeners
 */
import { Dispatch, SetStateAction } from "react"

import {
  CellClickedEvent,
  CellContextMenuEvent,
  CellDoubleClickedEvent,
  CellRange,
  FillOperationParams,
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  ProcessDataFromClipboardParams,
  RangeSelectionChangedEvent,
} from "@ag-grid-community/core"

import { DataPoint, DataPoints } from "src/common/dataPoints"

import { SOVAsset, SOVAssets } from "../types"

import {
  fillDown,
  insertRowsAbove,
  insertRowsBelow,
  removeRows,
  setDataAndInsertRows,
} from "./data"
import { adjustRangeSelection, calculateTotalSum } from "src/common/grid"
import { RowData } from "./columns"
import { ROW_INDEX_COLUMN_FIELD } from "src/common/agGrid"

/**
 * Get the range of cells the user has selected. There will be at most one of
 * these, because we disallow multiple simultaneous range selections in the grid
 * configuration
 */
const getRangeSelection = (api: GridApi): CellRange | null => {
  const ranges = api.getCellRanges() ?? []
  return ranges[0] ?? null
}

/**
 * Return menu items for the right-click context menu
 */
export const getContextMenuItems =
  (dataPoints: DataPoints, setAssets: Dispatch<SetStateAction<SOVAssets>>) =>
  (params: GetContextMenuItemsParams<RowData>): (string | MenuItemDef)[] => {
    if (!params.node) {
      return []
    }

    const numSelectedRows = params.api.getSelectedRows().length

    return [
      "autoSizeAll",
      "copy",
      {
        name: "Insert row above",
        cssClasses: ["custom-action-insert-row-above"],
        action: () => {
          const rowIdx = params.node?.data?.rowIndex
          if (rowIdx === undefined) {
            return
          }

          setAssets(as => insertRowsAbove(dataPoints, rowIdx, 1, as))
        },
      },
      {
        name: "Insert row below",
        cssClasses: ["custom-action-insert-row-below"],
        action: () => {
          const rowIdx = params.node?.data?.rowIndex
          if (rowIdx === undefined) {
            return
          }

          setAssets(as => insertRowsBelow(dataPoints, rowIdx, 1, as))
        },
      },
      {
        name: "Remove selected row(s)",
        cssClasses: ["custom-action-remove-selected-rows"],
        disabled: numSelectedRows === 0,
        action: () => onRemoveRows(dataPoints, params, setAssets),
      },
    ]
  }

/**
 * Handler to run when the user triggers the 'remove rows' action via the
 * context menu. Exported separately for testing
 */
export const onRemoveRows = (
  dataPoints: DataPoints,
  params: GetContextMenuItemsParams<RowData>,
  setAssets: Dispatch<SetStateAction<SOVAssets>>,
): void => {
  const selectedRows = params.api.getSelectedRows()

  setAssets(as => removeRows(dataPoints, selectedRows, as))
}

export const onRangeSelectionChanged = (
  e: RangeSelectionChangedEvent<RowData>,
  setSumOfValues: (sum: string) => void,
): void => {
  const range = getRangeSelection(e.api)

  if (!range) {
    return
  }

  setSumOfValues(calculateTotalSum(range, e.api))
  adjustRangeSelection(e, range)
}

/**
 * Listen for double-click events on the fill handle in order to provide
 * auto-fill-down behaviour like Excel
 */
export const onCellDoubleClicked =
  (dataPoints: DataPoints, setAssets: Dispatch<SetStateAction<SOVAssets>>) =>
  (params: CellDoubleClickedEvent<RowData>): void => {
    // @ts-expect-error TS doesn't know the `className` prop will exist here
    if (params.event?.target?.className !== "ag-fill-handle") {
      return
    }

    const range = getRangeSelection(params.api)
    if (!range) {
      return
    }

    const { columns, startRow, endRow } = range
    if (startRow === undefined || endRow === undefined) {
      return
    }

    // The row index out of all the rows on screen i.e. not counting
    // hidden rows
    const visibleRowIndex = params.node?.rowIndex ?? undefined

    // The 'real' row index i.e. taking into account hidden rows
    const actualRowIndex = params.node?.data?.rowIndex

    if (actualRowIndex === undefined || visibleRowIndex === undefined) {
      return
    }

    const numHiddenRowsBeforeThisOne = actualRowIndex - visibleRowIndex

    const startIndex = startRow.rowIndex + numHiddenRowsBeforeThisOne
    const endIndex = endRow.rowIndex + numHiddenRowsBeforeThisOne

    const selectedDataPointIds: DataPoint["field"][] = columns
      .map(c => c.getColDef().field)
      .filter(isString)

    const selectedDataPoints: DataPoint[] = dataPoints.filter(
      dp => selectedDataPointIds.indexOf(dp.field) !== -1,
    )

    setAssets(as => fillDown(selectedDataPoints, startIndex, endIndex, as))
  }

const isString = (s: string | undefined): s is string => s !== undefined

/*
 * If the user pastes near the end of the grid and we don't have enough rows to
 * accommodate all their clipboard data, we add the necessary number of blank
 * rows. Unfortunately this requires taking over the whole clipboard process
 * from ag-grid and handling it manually
 */
export const processDataFromClipboard =
  (dataPoints: DataPoints, setAssets: Dispatch<SetStateAction<SOVAssets>>) =>
  (e: ProcessDataFromClipboardParams<RowData>): string[][] | null => {
    const { api, data } = e

    const targetCell = api.getFocusedCell()
    if (!targetCell) {
      return null
    }

    // TODO This is the visible row index, which does not take hidden rows into
    // account. We will therefore paste into the wrong cell if any rows above
    // the target row are hidden. See: APP-1327
    const targetRow = targetCell.rowIndex

    const targetDataPointId = targetCell.column.getColDef().field
    if (!targetDataPointId) {
      return null
    }

    const targetFieldIdx = dataPoints.findIndex(
      dp => dp.field === targetDataPointId,
    )
    if (targetFieldIdx < 0) {
      return null
    }

    const dataPointIdsToFill = (data[0] ?? []).reduce<DataPoint["field"][]>(
      (acc, _, i) => {
        const idx = i + targetFieldIdx
        return idx > dataPoints.length - 1
          ? acc
          : [...acc, dataPoints[idx].field]
      },
      [],
    )

    const dataToFill = data.reduce<Partial<SOVAsset>[]>((acc, row) => {
      const partialAsset = dataPointIdsToFill.reduce<Partial<SOVAsset>>(
        (dpAcc, dpId, i) => ({ ...dpAcc, [dpId]: row[i] }),
        {},
      )
      return [...acc, partialAsset]
    }, [])

    setAssets(as => setDataAndInsertRows(dataPoints, dataToFill, targetRow, as))

    // Returning null means ag-grid won't do anything with the clipboard
    // data, which is OK because we've handled it ourselves
    return null
  }

/**
 * Listen for all cell click events and allows row selection only on the
 * rowIndex column in order to provide similar row selection behaviour like Excel
 *
 * TODO There are some known bugs with this implementation: see APP-1452 and
 * APP-1453
 */
export const onCellClicked = (e: CellClickedEvent<RowData>): void => {
  // This is to prevent any flickering when any cell other than the rowIndex one
  // is clicked
  e.api.setSuppressRowClickSelection(true)

  const event = e.event as PointerEvent
  const isSelected = e.node.isSelected()
  const isCtrlOrCmdKeyPressed = event.metaKey || event.ctrlKey
  const hasNoSelectedRows = e.api.getSelectedRows().length === 0

  // I had to go down the route of deselected instead of selected due to some AG
  // Grid intricacies.
  const shouldBeDeselected = !isSelected && isCtrlOrCmdKeyPressed

  const isRowIndexColumn = e.column.getColId() === ROW_INDEX_COLUMN_FIELD

  if (isRowIndexColumn) {
    e.api.setSuppressRowClickSelection(false)
    e.node.setSelected(!shouldBeDeselected || hasNoSelectedRows)
  } else {
    // This covers an edge case where a cell has focus, the user selects all
    // then clicks on another or the same cell
    e.api.deselectAll()
  }
}

/**
 * Listen for all cell right-click events and allows row selection only on the
 * rowIndex column in order to add a custom row selection behaviour
 */
export const onCellContextMenu = (e: CellContextMenuEvent<RowData>): void => {
  const hasOneRowSelected = e.api.getSelectedRows().length === 1
  const isRowIndexColumn = e.column.getColId() === ROW_INDEX_COLUMN_FIELD

  if (isRowIndexColumn) {
    // Having the boolean hasOneRowSelected to clear, allows the user to right
    // click and select multiple rows without loosing their selection or one
    // only one row if only one has been selected.
    e.node.setSelected(true, hasOneRowSelected)
  }
}

/**
 * A callback to handle saving values to the Ag Grid when copying cells
 * via the fill handle.
 *
 * Copied cell values via the fill handle do not always respect the desired
 * type of the column, due to Ag Grid's auto inference for the type of the
 * data.This callback ensures we save a `string` for columns of type "text",
 * since the grid will always call the `fillOperation` callback when cells are
 * copied via the fill handle.
 * For columns of other types, we let Ag Grid handle saving the value as normal.
 * @param fillOperationParams The Ag Grid parameters object passed to the `fillOperation` callback.
 * @param fieldToTypeMap An object mapping column field names to our custom `DataPoint` types.
 */
export const fillOperation = (
  fillOperationParams: FillOperationParams<RowData>,
  fieldToTypeMap: { [field: string]: DataPoint["type"] },
): string | false => {
  const { column, values, currentIndex } = fillOperationParams
  const field = column.getColDef().field

  // We also check that are actually going to copy a string,
  // since an empty cell will contain the value null.
  // `values[currentIndex]` corresponds to the value that we will save to the current cell,
  // which accounts for copying a multi-row selection to multiple rows, via the fill handle.
  if (
    !!field &&
    fieldToTypeMap[field] === "text" &&
    0 <= currentIndex &&
    currentIndex < values.length &&
    typeof values[currentIndex] === "string"
  ) {
    return String(values[currentIndex])
  }

  // See https://www.ag-grid.com/javascript-data-grid/range-selection-fill-handle/#filloperationparams
  // Return false "to allow the grid to process the values as it normally would."
  return false
}

import { ColDef } from "@ag-grid-community/core"

export const ROW_INDEX_COLUMN_FIELD = "rowIndex" as const

export const rowIndexColumnDef = (
  hasHeaderCheckbox: boolean,
): ColDef<number> => ({
  field: ROW_INDEX_COLUMN_FIELD,
  headerName: "", // Don't show a name for this column

  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,

  width: 50,
  pinned: "left",
  cellClass: "otto-row-number",

  valueFormatter: "x + 1",
  headerCheckboxSelection: hasHeaderCheckbox,
  headerCheckboxSelectionFilteredOnly: true,
})

import {
  CellRange,
  GridApi,
  RangeSelectionChangedEvent,
} from "@ag-grid-community/core"
import { ROW_INDEX_COLUMN_FIELD } from "src/common/agGrid"

import { formatDecimal2DP } from "./utils"
import { parseNumber } from "./validation"

/**
 * Prevent the user from selecting the cells containing the row numbers by
 * automatically adjusting the range selection
 */
export const adjustRangeSelection = <T extends Record<string, unknown>>(
  e: RangeSelectionChangedEvent<T>,
  range: CellRange,
): void => {
  if (!e.finished) {
    return
  }

  if (
    range.columns.some(col => col.getColDef().field === ROW_INDEX_COLUMN_FIELD)
  ) {
    const rowStartIndex = range.startRow?.rowIndex
    const rowEndIndex = range.endRow?.rowIndex

    if (rowStartIndex === undefined || rowEndIndex === undefined) {
      return
    }

    e.api.clearRangeSelection()

    e.api.addCellRange({
      rowStartIndex,
      rowEndIndex,
      columns: range.columns.filter(
        c => c.getColDef().field !== ROW_INDEX_COLUMN_FIELD,
      ),
    })
  }
}

/**
 * Calculates and returns the sum of all the highlighted cells with a number value
 */
export const calculateTotalSum = <T extends Record<string, unknown>>(
  range: CellRange,
  api: GridApi<T>,
): string => {
  let sum = 0
  let valueAsString = ""
  const rowStartIndex = Math.min(
    range?.startRow?.rowIndex ?? 0,
    range?.endRow?.rowIndex ?? 0,
  )

  const rowEndIndex = Math.max(
    range.startRow?.rowIndex ?? 0,
    range.endRow?.rowIndex ?? 0,
  )

  for (let rowIndex = rowStartIndex; rowIndex <= rowEndIndex; rowIndex++) {
    range.columns.forEach(column => {
      const rowModel = api.getModel()
      const rowNode = rowModel.getRow(rowIndex)

      if (!rowNode) {
        return undefined
      }

      const value = api.getValue(column, rowNode)
      const isRowIndexColumn =
        column.getColDef().field === ROW_INDEX_COLUMN_FIELD

      const parseResult = parseNumber(value)
      if (
        (parseResult.status === "valid" || parseResult.status === "invalid") &&
        !isRowIndexColumn
      ) {
        sum += parseResult.parsedValue
        valueAsString = formatDecimal2DP(sum)
      }
    })
  }

  return valueAsString
}

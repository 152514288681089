export type RemoteData<E, A> = NotAsked | Loading | Success<A> | Failure<E>

export interface NotAsked {
  _type: "NotAsked"
}

export const NotAsked: RemoteData<never, never> = { _type: "NotAsked" }

export const isNotAsked = <E, A>(rd: RemoteData<E, A>): rd is NotAsked =>
  rd._type === "NotAsked"

export interface Loading {
  _type: "Loading"
}

export const Loading: RemoteData<never, never> = { _type: "Loading" }

export const isLoading = <E, A>(rd: RemoteData<E, A>): rd is Loading =>
  rd._type === "Loading"

export interface Success<A> {
  _type: "Success"
  data: A
}

export const Success = <A>(data: A): RemoteData<never, A> => ({
  _type: "Success",
  data,
})

export const isSuccess = <E, A>(rd: RemoteData<E, A>): rd is Success<A> =>
  rd._type === "Success"

export interface Failure<E> {
  _type: "Failure"
  error: E
}

export const Failure = <E>(error: E): RemoteData<E, never> => ({
  _type: "Failure",
  error,
})

export const isFailure = <E, A>(rd: RemoteData<E, A>): rd is Failure<E> =>
  rd._type === "Failure"

export const match = <E, A, T>(
  rd: RemoteData<E, A>,
  whenNotAsked: T,
  whenLoading: T,
  whenSuccess: (data: A) => T,
  whenFailure: (error: E) => T,
): T => {
  switch (rd._type) {
    case "NotAsked":
      return whenNotAsked
    case "Loading":
      return whenLoading
    case "Success":
      return whenSuccess(rd.data)
    case "Failure":
      return whenFailure(rd.error)
  }
}

type ExtractErrs<RDs> = RDs extends [
  RemoteData<infer E, unknown>,
  ...infer Rest,
]
  ? [E | undefined, ...ExtractErrs<Rest>]
  : []

type ExtractData<RDs> = RDs extends [
  RemoteData<unknown, infer A>,
  ...infer Rest,
]
  ? [A, ...ExtractData<Rest>]
  : []

// Like `match` but for multiple requests at once, similar to `Promise.all`
export const matchAll = <
  RDs extends [RemoteData<unknown, unknown>, ...RemoteData<unknown, unknown>[]],
  T,
>(
  rds: RDs,
  whenNotAsked: T,
  whenLoading: T,
  whenSuccess: (...data: ExtractData<RDs>) => T,
  whenFailure: (...errors: ExtractErrs<RDs>) => T,
): T => {
  // Fail if any requests fail
  if (rds.some(isFailure)) {
    const errs = rds.map(rd =>
      isFailure(rd) ? rd.error : undefined,
    ) as ExtractErrs<RDs>

    return whenFailure(...errs)
  }
  // Return `NotAsked` if any requests are `NotAsked`
  else if (rds.some(isNotAsked)) {
    return whenNotAsked
  }
  // Return `Loading` if any requests are `Loading`
  else if (rds.some(isLoading)) {
    return whenLoading
  }
  // Succeed only if all requests succeed
  else {
    // @ts-expect-error We can't easily prove to TS that all items are
    // `Success`es by this point
    return whenSuccess(...rds.map(rd => rd.data))
  }
}

export const map = <E, A, B>(
  f: (a: A) => B,
  rd: RemoteData<E, A>,
): RemoteData<E, B> => (isSuccess(rd) ? Success(f(rd.data)) : rd)

import type { SVGComponent } from "./IconWrapper"

const ExclamationPageSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.4C2 1.623 2.61187 1 3.375 1H10.5625L14 4.5V13.6C14 14.377 13.3881 15 12.625 15H3.375C2.61187 15 2 14.377 2 13.6V2.4ZM10.5625 2.4H3.375V13.6H12.625V4.5H10.5625V2.4ZM7.25 5.4663V8.2036C7.25 8.63375 7.60822 8.98569 8.04605 8.98569C8.48388 8.98569 8.84211 8.63375 8.84211 8.2036V5.4663C8.84211 5.03615 8.48388 4.68421 8.04605 4.68421C7.60822 4.68421 7.25 5.03615 7.25 5.4663ZM8.84211 10.5498V12.114H7.25V10.5498H8.84211Z"
      fill="currentColor"
    />
  </svg>
)

export default ExclamationPageSVG

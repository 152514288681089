import { FC, ReactNode } from "react"

interface BasePillProps {
  bgColor: string
  textColor: string
  icon?: ReactNode
  label: ReactNode
}

export const BasePill: FC<BasePillProps> = ({
  bgColor,
  textColor,
  icon,
  label,
}) => {
  return (
    <div
      className={`inline-block w-full whitespace-nowrap rounded-full px-4 py-1 ${bgColor} forced-colors:border forced-colors:border-transparent`}
    >
      <div className={`text-center text-base font-bold ${textColor}`}>
        {icon && (
          <div className="mr-1 inline-block w-5 align-middle">{icon}</div>
        )}
        <span className="align-middle">{label}</span>
      </div>
    </div>
  )
}

export type PillType =
  | "disabled"
  | "error"
  | "neutral"
  | "pop"
  | "success"
  | "warning"
  | "night"

const Pill: FC<{
  icon?: BasePillProps["icon"]
  label: BasePillProps["label"]
  type: PillType
}> = ({ icon, label, type }) => {
  let bgColor: string, textColor: string
  switch (type) {
    case "disabled":
      bgColor = "bg-otto-grey-700"
      textColor = "text-otto-grey-200"
      break
    case "error":
      bgColor = "bg-otto-red-50"
      textColor = "text-otto-red-600"
      break
    case "neutral":
      bgColor = "bg-otto-grey-200"
      textColor = "text-otto-grey-800"
      break
    case "pop":
      bgColor = "bg-otto-pop-50"
      textColor = "text-otto-pop-900"
      break
    case "success":
      bgColor = "bg-otto-green-50"
      textColor = "text-otto-green-900"
      break
    case "warning":
      bgColor = "bg-otto-yellow-50"
      textColor = "text-otto-yellow-700"
      break
    case "night":
      bgColor = "bg-otto-night"
      textColor = "text-white"
      break
  }

  return (
    <BasePill
      icon={icon}
      label={label}
      bgColor={bgColor}
      textColor={textColor}
    />
  )
}

export default Pill

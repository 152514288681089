import { FC, useId } from "react"

interface Props {
  sumOfValues: string | undefined
}

const SpreadsheetStatusBar: FC<Props> = ({ sumOfValues }) => {
  const spreadsheetStatusBarId = useId()

  return (
    <section
      aria-labelledby={spreadsheetStatusBarId}
      className="flex h-6 w-full items-center justify-end"
    >
      <h3 className="sr-only" id={spreadsheetStatusBarId}>
        Spreadsheet Status Bar
      </h3>
      {sumOfValues && (
        <p className="mr-3">
          Sum: <span className="font-medium tabular-nums">{sumOfValues}</span>
        </p>
      )}
    </section>
  )
}
export default SpreadsheetStatusBar

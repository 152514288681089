import { FC, RefObject, useCallback, useEffect, useMemo, useState } from "react"

import { Table } from "src/common/types"
import { TableBounds } from "../types"
import { DataPoint, DataPoints } from "src/common/dataPoints"

import { rowIsActiveTableHeader, rowWithinActiveTable } from "../utils"
import { adjustRangeSelection, calculateTotalSum } from "src/common/grid"

import { makeColumnDefs } from "./columns"
import { RowData, makeRowData } from "./rowData"

import SpreadsheetStatusBar from "src/components/SpreadsheetStatusBar"

import {
  RangeSelectionChangedEvent,
  RowHeightParams,
} from "@ag-grid-community/core"
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import { AgGridReact } from "@ag-grid-community/react"

import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection"
import { ClipboardModule } from "@ag-grid-enterprise/clipboard"

import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-alpine.css"
import "src/common/agGrid.css"
import "./index.css"

// This is the height of our `Select` component plus space for one line of text
const HEADER_CELL_HEIGHT = 80

const Spreadsheet: FC<{
  activeDataPointId: DataPoint["field"] | null
  activeTableId: Table["id"] | null
  data: unknown[][]
  dataPoints: DataPoints
  gridRef: RefObject<AgGridReact>
  onExtract: (tableId: Table["id"], extracted: Table["extracted"]) => void
  tablePreview: TableBounds | null
  tables: Table[]
}> = ({
  activeDataPointId,
  activeTableId,
  data,
  dataPoints,
  gridRef,
  onExtract,
  tablePreview,
  tables,
}) => {
  const [sumOfValues, setSumOfValues] = useState<string>("")

  const activeTable = tables.find(t => t.id === activeTableId) ?? null

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      // Redraw when the activeTable is altered to ensure the row heights are
      // properly adjusted for the dropdowns
      gridRef.current.api.resetRowHeights()
    }
  }, [activeTable, gridRef])

  const rowData = useMemo<RowData[]>(() => makeRowData(data), [data])

  const maxRowLength = Math.max(...data.map(r => r.length), 0)

  const columnDefs = makeColumnDefs(
    tables,
    tablePreview,
    activeTable,
    dataPoints,
    activeDataPointId,
    maxRowLength,
    onExtract,
  )

  const onRangeSelectionChanged = useCallback(
    (e: RangeSelectionChangedEvent<RowData>) => {
      const ranges = e.api.getCellRanges()

      if (!ranges || ranges.length < 1) {
        return
      }

      const range = ranges[0]

      setSumOfValues(calculateTotalSum(range, e.api))
      adjustRangeSelection(e, range)
    },
    [setSumOfValues],
  )

  const getRowHeight = useCallback(
    (params: RowHeightParams<RowData>) => {
      const { rowIndex } = params.node

      if (rowIndex === null || !activeTable) {
        return
      }

      if (
        rowIsActiveTableHeader(activeTable, rowIndex) ||
        (activeTable.orientation === "horizontal" &&
          rowWithinActiveTable(activeTable, rowIndex))
      ) {
        return HEADER_CELL_HEIGHT
      }
    },
    [activeTable],
  )

  return (
    <div className="otto-extraction-screen ag-theme-alpine flex h-full w-full flex-col">
      <AgGridReact<RowData>
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        // Don't highlight the row the user is hovering over
        suppressRowHoverHighlight
        // Allow the user to select ranges of cells
        enableRangeSelection
        // Don't allow the user to select multiple simultaneous ranges of cells
        suppressMultiRangeSelection
        // Manually prevent the user from selecting row number cells
        onRangeSelectionChanged={onRangeSelectionChanged}
        getRowHeight={getRowHeight}
        // Don't show the context menu
        suppressContextMenu
        modules={[
          ClientSideRowModelModule,
          ClipboardModule,
          RangeSelectionModule,
        ]}
      />
      <SpreadsheetStatusBar sumOfValues={sumOfValues} />
    </div>
  )
}

export default Spreadsheet

import { FC } from "react"

const KiLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    focusable={false}
    viewBox="0 0 479.73 362.86"
    fill="currentColor"
    aria-label="Ki Insurance"
  >
    <path d="M227.03 0L76.4 171.44l-19.7-19.18 37.52-44.13L94.33 0H0v362.86h94.33V246.75l44.06-45.62 113 161.73h99.01L192.3 145.15 332.78 0zM384.87 0v362.86h94.86V79.5l-27.99 31.99-19.44-19.44 47.43-55.2V0z"></path>
  </svg>
)

export default KiLogo

import { FC, SVGProps } from "react"

export type SVGComponent = FC<SVGProps<SVGSVGElement>>

interface IconProps {
  className?: string
  label?: string
}

export type Icon = FC<IconProps>

export const IconWrapper: FC<IconProps & { icon: SVGComponent }> = ({
  icon: Icon,
  className,
  label,
  ...props
}) => (
  <Icon
    role="img"
    focusable={false}
    aria-label={label}
    aria-hidden={!label}
    className={className}
    {...props}
  />
)

import { FC, useState } from "react"

import OverviewSidebar, { CollapsibleSectionKey } from "./OverviewSidebar"
import BoundedErrorCorrectionSidebar from "./ErrorCorrectionSidebars/BoundedErrorCorrectionSidebar"
import InvalidRowsErrorCorrectionSidebar from "./ErrorCorrectionSidebars/InvalidRowsErrorCorrectionSidebar"
import NumberErrorCorrectionSidebar from "./ErrorCorrectionSidebars/NumberErrorCorrectionSidebar"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { CellStatus, EditingView, SOVAssets, TotalsPerCurrency } from "../types"

import { calcRowStatusCounts } from "./statuses"

const Sidebar: FC<{
  assets: SOVAssets
  cellStatuses: CellStatus[]
  dataPoints: DataPoints
  editingView: EditingView
  onBulkUpdate: (f: (a: SOVAssets) => SOVAssets) => void
  onContinue: () => void
  scrollToColumn: (field: DataPoint["field"]) => void
  setEditingView: (v: EditingView) => void
  totalsPerCurrency: TotalsPerCurrency
}> = ({
  assets,
  cellStatuses,
  dataPoints,
  editingView,
  onBulkUpdate,
  onContinue,
  scrollToColumn,
  setEditingView,
  totalsPerCurrency,
}) => {
  const [collapsibleStates, setCollapsibleStates] = useState<
    Record<CollapsibleSectionKey, boolean>
  >({ summary: true, validation: true })

  const rowStatusCounts = calcRowStatusCounts(dataPoints, cellStatuses)

  const onBack = (): void => setEditingView({ mode: "default" })

  const onFixErrorsClick = (selectedView: EditingView): void => {
    setEditingView(selectedView)

    switch (selectedView.mode) {
      case "dataPoint":
        scrollToColumn(selectedView.dataPoint.field)
        break

      case "allInvalid": {
        const firstColumnWithInvalidCell = dataPoints.reduce<
          DataPoint["field"] | null
        >((acc, dp) => {
          if (acc !== null) return acc

          const errorsForColumn = cellStatuses.filter(
            c => c.status === "invalid" && c.dataPointId === dp.field,
          )
          return errorsForColumn.length > 0 ? dp.field : null
        }, null)

        if (firstColumnWithInvalidCell) {
          scrollToColumn(firstColumnWithInvalidCell)
        }
        break
      }
    }
  }

  switch (editingView.mode) {
    case "default":
      return (
        <OverviewSidebar
          cellStatuses={cellStatuses}
          collapsibleStates={collapsibleStates}
          dataPoints={dataPoints}
          onFixErrorsClick={onFixErrorsClick}
          onContinue={onContinue}
          rowStatusCounts={rowStatusCounts}
          setCollapsibleStates={setCollapsibleStates}
          totalsPerCurrency={totalsPerCurrency}
        />
      )

    case "allInvalid":
      return (
        <InvalidRowsErrorCorrectionSidebar
          cellStatuses={cellStatuses}
          dataPoints={dataPoints}
          onApply={onBulkUpdate}
          onBack={onBack}
          rowStatusCounts={rowStatusCounts}
        />
      )

    case "dataPoint": {
      const { dataPoint } = editingView

      const hasErrors = rowStatusCounts.dataPoints[dataPoint.field] > 0

      switch (dataPoint.type) {
        case "number":
          return (
            <NumberErrorCorrectionSidebar
              dataPoint={dataPoint}
              dataPoints={dataPoints}
              hasErrors={hasErrors}
              onApply={onBulkUpdate}
              onBack={onBack}
            />
          )

        case "country":
          return (
            <BoundedErrorCorrectionSidebar
              assets={assets}
              cellStatuses={cellStatuses}
              dataPoint={dataPoint}
              hasErrors={hasErrors}
              mapResultToLabel={res => res.value}
              mapResultToReturnValue={res => res.value}
              onApply={onBulkUpdate}
              onBack={onBack}
              placeholder="Select a country"
            />
          )

        case "currency":
          return (
            <BoundedErrorCorrectionSidebar
              assets={assets}
              cellStatuses={cellStatuses}
              dataPoint={dataPoint}
              hasErrors={hasErrors}
              mapResultToLabel={res => res.key}
              mapResultToReturnValue={res => res.key}
              onApply={onBulkUpdate}
              onBack={onBack}
              placeholder="Select a currency"
            />
          )
      }
    }
  }
}

export default Sidebar

import { FC } from "react"

import { TargetIcon } from "@appia/ui-components"

import { DataPoint } from "src/common/dataPoints"

import classNames from "classnames"

const LabelWithJumpButton: FC<{
  dataPoint: DataPoint
  inputId: string
  onGoToDataPoint: (dpId: DataPoint["field"]) => void
  showJumpButton: boolean
}> = ({ dataPoint, inputId, onGoToDataPoint, showJumpButton }) => (
  <>
    {/* The linting is correct that this is a weird thing to do, but it should
        be OK because the adjacent button has identical behaviour, and the
        onClick handler doesn't interfere with the label's natural functionality
        (i.e. it still focuses the input field when clicked)
    */}
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
    <label
      className={classNames(
        "label-with-jump-button flex w-fit items-end border-b border-transparent",
        {
          "cursor-pointer border-b-otto-grey-700 pb-0.5": showJumpButton,
        },
      )}
      data-cy={`${dataPoint.label}-label`}
      htmlFor={inputId}
      onClick={() => {
        onGoToDataPoint(dataPoint.field)
      }}
      title={`Jump to ${dataPoint.label}`}
    >
      <span
        className={classNames("text-sm font-normal", {
          "text-otto-grey-600": !showJumpButton,
        })}
      >
        {dataPoint.label}
      </span>

      {showJumpButton && <TargetIcon className="ml-1 w-5 text-otto-grey-700" />}
    </label>
    {showJumpButton && (
      <button
        className="sr-only"
        aria-label={`Jump to ${dataPoint.label}`}
        onClick={() => {
          onGoToDataPoint(dataPoint.field)
        }}
      />
    )}
  </>
)

export default LabelWithJumpButton

import { FC, ReactNode, useId } from "react"

import { Toast } from "@appia/ui-components"

import usePageLoad from "src/common/hooks"

const PageLayout: FC<{
  pageHeading: string
  sidebar: ReactNode
  sidebarSectionHeading: string
  spreadsheet: ReactNode
  spreadsheetSectionHeading: string
  toolbar: ReactNode
}> = ({
  pageHeading,
  sidebar,
  sidebarSectionHeading,
  spreadsheet,
  spreadsheetSectionHeading,
  toolbar,
}) => {
  const headingRef = usePageLoad(pageHeading)

  const sidebarSectionId = useId()
  const spreadsheetSectionId = useId()

  return (
    <>
      <div className="grid w-full grid-cols-[clamp(350px,26%,600px),minmax(0,1fr)] gap-4 p-4">
        <h1 ref={headingRef} tabIndex={-1} className="sr-only">
          {pageHeading}
        </h1>

        <section aria-labelledby={sidebarSectionId}>
          <h2 className="sr-only" id={sidebarSectionId}>
            {sidebarSectionHeading}
          </h2>

          {sidebar}
        </section>

        <section
          data-cy="spreadsheet-section"
          aria-labelledby={spreadsheetSectionId}
        >
          <h2 className="sr-only" id={spreadsheetSectionId}>
            {spreadsheetSectionHeading}
          </h2>

          <div className="grid h-full grid-cols-1 grid-rows-[auto,1fr]">
            {toolbar}

            <div className="relative rounded-b-md border-x border-b border-otto-grey-400">
              {spreadsheet}
            </div>
          </div>
        </section>
      </div>

      <Toast.Viewport className="fixed bottom-0 left-1/2 translate-x-[-50%]" />
    </>
  )
}

export default PageLayout

import { FC, LabelHTMLAttributes, ReactNode, useState } from "react"
import classNames from "classnames"

import { IconButton } from "./IconCallToAction"
import { VisibilityIcon, VisibilityOffIcon } from "./Icons"

export interface InputProps {
  onChange: (s: string) => void

  "aria-describedby"?: string
  "aria-labelledby"?: string
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autoComplete?: string
  disabled?: boolean
  hasError?: boolean
  id?: string
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
  inputMode?: "decimal" | "email" | "text" | "url"
  onBlur?: () => void
  onFocus?: () => void
  placeholder?: string
  required?: boolean
  size?: number
  title?: string
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
  type?: "date" | "email" | "password" | "text" | "url"
  value?: string
  testId?: string
}

export const Input: FC<InputProps> = ({
  onChange,

  "aria-describedby": ariaDescribedBy,
  "aria-labelledby": ariaLabelledBy,
  autoComplete = "off",
  disabled = false,
  hasError = false,
  id,
  inputMode,
  onBlur,
  onFocus,
  placeholder,
  required = false,
  size,
  title,
  type: initialType,
  value,
  testId,
}) => {
  const [type, setType] = useState<InputProps["type"]>(initialType)

  return (
    <div className="relative rounded">
      <input
        className={classNames(
          "otto-focus-inset block h-8 w-full rounded border bg-white p-2 text-base",
          {
            "border-otto-grey-400 placeholder-otto-grey-700": !hasError,
            "otto-focus-bright-red rounded border-otto-bright-red text-otto-deep-red placeholder-otto-deep-red focus:border-otto-bright-red":
              hasError,
            "bg-otto-grey-200": disabled,
            "pr-8": initialType === "password",
          },
        )}
        // Reset for Safari
        style={{ fontVariant: "initial" }}
        aria-describedby={ariaDescribedBy}
        aria-labelledby={ariaLabelledBy}
        aria-invalid={hasError ? true : undefined}
        aria-required={required}
        autoComplete={autoComplete}
        disabled={disabled}
        id={id}
        inputMode={inputMode}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        placeholder={placeholder}
        required={required}
        size={size}
        title={title}
        type={type}
        value={value}
        data-testid={testId}
      />

      {initialType === "password" && (
        <IconButton
          // The `switch` role requires `aria-checked`
          aria-checked={type === "text"}
          className="otto-focus absolute top-1/2 right-2 -translate-y-1/2 rounded-sm"
          label="Show password"
          role="switch"
          size={5}
          icon={
            type === "password" ? (
              <VisibilityOffIcon className="text-otto-grey-700" />
            ) : (
              <VisibilityIcon className="text-otto-grey-700" />
            )
          }
          onClick={() => {
            setType(type === "password" ? "text" : "password")
          }}
        />
      )}
    </div>
  )
}

export const InputLabel: FC<
  {
    children: ReactNode
    className?: string
    label: ReactNode
  } & LabelHTMLAttributes<HTMLLabelElement>
> = ({ children, className, label, ...props }) => (
  <label {...props}>
    <span className={classNames("mb-1 block text-base font-normal", className)}>
      {label}
    </span>
    {children}
  </label>
)

import { ValidatedData } from "@appia/form-data"
import { SheetCoords } from "src/common/types"
import { columnToIndex, indexToColumn } from "src/common/utils"

/**
 * Try to parse a string like "A1" into a SheetCoords object
 */
export const parseCoordsString = (s: string): SheetCoords | null => {
  const coordsRegex = /(?<column>[A-Z]+)(?<row>[0-9]+)/

  const matchRes = s.match(coordsRegex)

  if (
    matchRes &&
    matchRes.groups &&
    "column" in matchRes.groups &&
    "row" in matchRes.groups
  ) {
    return {
      column: matchRes.groups.column,
      row: parseInt(matchRes.groups.row, 10),
    }
  } else {
    return null
  }
}

type BoundsStatus = "tooLow" | "tooHigh" | "inRange"

/**
 * Validate a string representing coordinates, like "A1" or "X25"
 */
export const validateCoords = (
  minCoords: SheetCoords,
  maxCoords: SheetCoords,
  s: string,
): ValidatedData<SheetCoords> => {
  const { column: minCol, row: minRow } = minCoords
  const { column: maxCol, row: maxRow } = maxCoords

  // Empty strings are clearly invalid
  if (s === "") {
    return { valid: false, error: "Enter a value" }
  }

  // Try to parse the string; if it can't be parsed, it's invalid
  const parsed = parseCoordsString(s)
  if (!parsed) {
    return { valid: false, error: 'Enter a value in the format "A1"' }
  }

  const { column, row } = parsed

  const colBoundsStatus: BoundsStatus =
    columnToIndex(column) > columnToIndex(maxCol)
      ? "tooHigh"
      : columnToIndex(column) < columnToIndex(minCol)
      ? "tooLow"
      : "inRange"

  const rowBoundsStatus: BoundsStatus =
    row > maxRow ? "tooHigh" : row < minRow ? "tooLow" : "inRange"

  // Both the column and row must be within the acceptable range for the whole
  // lot to be valid
  if (colBoundsStatus === "inRange" && rowBoundsStatus === "inRange") {
    return { valid: true, data: { column, row } }
  }

  const upperBoundCol = indexToColumn(columnToIndex(maxCol) + 1)
  const lowerBoundCol = indexToColumn(columnToIndex(minCol) - 1)

  const colError =
    colBoundsStatus === "tooHigh"
      ? `Enter a column less than ${upperBoundCol}`
      : colBoundsStatus === "tooLow"
      ? `Enter a column greater than ${lowerBoundCol}`
      : null

  const rowError =
    rowBoundsStatus === "tooHigh"
      ? `Enter a row less than ${maxRow + 1}`
      : rowBoundsStatus === "tooLow"
      ? `Enter a row greater than ${minRow - 1}`
      : null

  // Otherwise the string was invalid
  return {
    valid: false,
    error: [colError, rowError].filter(e => e !== null).join(". "),
  }
}

export const isBottomRightSmallerThanTopLeft = (
  bottom_right: ValidatedData<SheetCoords>,
  top_left: ValidatedData<SheetCoords>,
): boolean =>
  top_left.valid &&
  bottom_right.valid &&
  (bottom_right.data.row < top_left.data.row ||
    columnToIndex(bottom_right.data.column) <
      columnToIndex(top_left.data.column))

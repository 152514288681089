import type { SVGComponent } from "./IconWrapper"

const ExclamationPageSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.375 1C2.61187 1 2 1.623 2 2.4V13.6C2 14.377 2.61187 15 3.375 15H12.625C13.3881 15 14 14.377 14 13.6V4.5L10.5625 1M3.375 2.4H10.5625V4.5H12.625V13.6H3.375V2.4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19629 9.7998H8.40012V10.9998H7.19629V9.7998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.69549 6.05269C9.41494 5.46124 8.78967 5.03339 7.94267 5.00011L7.93706 5C7.42537 5 6.9334 5.19415 6.56968 5.54166C6.20574 5.88938 6 6.36242 6 6.85712V6.99997H7.18281V6.85712C7.18281 6.66983 7.26062 6.48883 7.4014 6.35432C7.54118 6.22077 7.73165 6.14417 7.93182 6.14286C8.38107 6.17805 8.60692 6.39319 8.66637 6.6323C8.72804 6.88032 8.62685 7.21396 8.29497 7.50941C8.22163 7.56468 8.13916 7.61518 8.04726 7.67145L8.04726 7.67146C7.99032 7.70633 7.92975 7.74341 7.8655 7.78522C7.70673 7.88854 7.5374 8.01447 7.42208 8.18122C7.30368 8.35026 7.2489 8.54774 7.22233 8.75578C7.20454 8.89506 7.19883 9.04471 7.197 9.19971H8.40426C8.41457 9.01035 8.44943 8.85083 8.5666 8.71215L8.5678 8.71069C8.65225 8.608 8.7632 8.5123 8.9243 8.39566C9.07795 8.28441 9.25396 8.15669 9.3906 7.99939L9.39308 7.99637C9.90115 7.37813 9.97616 6.64438 9.69549 6.05269Z"
      fill="currentColor"
    />
  </svg>
)

export default ExclamationPageSVG

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"

export const MAX_HISTORY_SIZE = 10

interface UndoRedoState<T> {
  value: T
  redoStack: T[]
  undoStack: T[]
}

// Push a value onto a stack, taking into account the maximum size
const push = <T>(val: T, stack: T[]): T[] =>
  stack.length < MAX_HISTORY_SIZE ? [...stack, val] : [...stack.slice(1), val]

/**
 * Like a regular `useState` hook, but augments it with undo and redo histories
 */
const useUndoRedoState = <T>(
  initialValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<UndoRedoState<T>>(() => {
    const value =
      initialValue instanceof Function ? initialValue() : initialValue

    return { value, redoStack: [], undoStack: [] }
  })

  // Handle undo / redo key combinations using the undo and redo histories
  const handleUndoRedo = useCallback<(e: KeyboardEvent) => void>(
    e => {
      const isCtrlOrCmdY = e.key === "y" && (e.ctrlKey || e.metaKey)
      const isCtrlOrCmdZ = e.key === "z" && (e.ctrlKey || e.metaKey)

      const isUndo = isCtrlOrCmdZ && !e.shiftKey
      const isRedo = (isCtrlOrCmdZ && e.shiftKey) || isCtrlOrCmdY

      setState(state => {
        const { undoStack, redoStack, value } = state

        // Undo
        if (isUndo && undoStack.length > 0) {
          return {
            value: undoStack[undoStack.length - 1],
            redoStack: push(value, redoStack),
            undoStack: undoStack.slice(0, -1),
          }
        }
        // Redo
        else if (isRedo && redoStack.length > 0) {
          return {
            value: redoStack[redoStack.length - 1],
            redoStack: redoStack.slice(0, -1),
            undoStack: push(value, undoStack),
          }
        }
        // Either it wasn't an undo/redo, or we didn't have sufficient undo/redo
        // history to complete the request
        else {
          return state
        }
      })
    },
    [setState],
  )

  // Add / remove key listeners
  useEffect(() => {
    document.addEventListener("keydown", handleUndoRedo)
    return () => document.removeEventListener("keydown", handleUndoRedo)
  }, [handleUndoRedo])

  // Create a setter that mimics what would normally be returned from
  // `useState`, so that the caller doesn't see the undo/redo histories
  const setValue = useCallback<Dispatch<SetStateAction<T>>>(
    (funcOrVal): void =>
      setState(({ undoStack, value }) => {
        const updatedValue: T =
          funcOrVal instanceof Function ? funcOrVal(value) : funcOrVal

        // State changes (e.g. user edits) reset the redo history
        return {
          undoStack: push(value, undoStack),
          redoStack: [],
          value: updatedValue,
        }
      }),
    [setState],
  )

  return [state.value, setValue]
}

export default useUndoRedoState

import { FC, memo } from "react"

export interface StackedListItemSkeletonProps {
  min?: number
  max?: number
}

const StackedListItemSkeleton: FC<StackedListItemSkeletonProps> = ({
  min = 2,
  max = 5,
}) => {
  const randomWidth = Math.floor(Math.random() * (max - min + 1) + min)

  return (
    <div className="bg-white" data-cy="list-item-loader">
      <div className="flex animate-pulse space-x-6 space-y-6">
        <div className="flex-1 px-4 py-8 sm:px-6">
          <div
            className={`h-4 rounded bg-otto-grey-300 w-${randomWidth}/${max}`}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default memo(StackedListItemSkeleton)

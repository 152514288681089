import { Ref, useEffect, useRef } from "react"

/**
 * Focus a heading element when it loads
 */
export const useFocusHeading = (): Ref<HTMLHeadingElement> => {
  const headingRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.focus()
    }
  }, [headingRef])

  return headingRef
}

/**
 * Set the document title and focus the <h1> when the page loads
 */
const usePageLoad = (documentTitle: string): Ref<HTMLHeadingElement> => {
  const headingRef = useFocusHeading()

  useEffect(() => {
    document.title = documentTitle
  }, [documentTitle])

  return headingRef
}

export default usePageLoad

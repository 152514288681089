import { useCallback, useEffect, useId, useState } from "react"
import { SuccessResponse, Uppy, UppyFile } from "@uppy/core"
import { AwsS3UploadParameters } from "@uppy/aws-s3"
import { StorageProvider } from "./storageProvider"
import { initialiseAwsS3Uppy } from "./initialiseAwsS3Uppy"
import { initialiseGcsUppy } from "./initialiseGcsUppy"
import { UppyError } from "./uppyError"

interface UseUppyAwsS3Props {
  maxMegabytes: number
  acceptedDocumentTypes: string[]
  getUploadParameters: (
    file: UppyFile,
    uppy: Uppy,
  ) => Promise<AwsS3UploadParameters>
  onStartLoad: () => void
  onProgress?: (percentage: number) => void
  onError: (e: UppyError) => Promise<void>
  onSuccess: (response: SuccessResponse, file?: UppyFile) => Promise<void>
  provider: StorageProvider.AWS_S3
}

interface UseUppyGcsProps {
  maxMegabytes: number
  acceptedDocumentTypes: string[]
  getUploadParameters: (
    file: UppyFile,
    uppy: Uppy,
  ) => Promise<{
    url: string
    headers: Record<string, string>
    method: "PUT"
  }>
  onStartLoad: () => void
  onProgress?: (percentage: number) => void
  onError: (e: UppyError) => Promise<void>
  onSuccess: (response: SuccessResponse, file?: UppyFile) => Promise<void>
  provider: StorageProvider.GCS
}

type UseUppyProps = UseUppyAwsS3Props | UseUppyGcsProps

const useUppy = (props: UseUppyProps): Uppy => {
  const id = useId()
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  const mkUppy = useCallback(() => {
    const _onStartLoad = (): void => {
      setHasLoaded(false)
      props.onStartLoad()
    }

    const _onSuccess = (response: SuccessResponse, file?: UppyFile): void => {
      setTimeout(() => {
        setHasLoaded(true)
        props.onSuccess(response, file)
      }, 1000)
    }

    const _onProgress = (percentage: number): void => {
      props.onProgress?.(percentage)
    }

    const _onError = (e: UppyError): void => {
      setHasLoaded(true)
      props.onError(e)
    }

    if (props.provider === StorageProvider.AWS_S3) {
      const awsProps = props
      return initialiseAwsS3Uppy(
        id,
        awsProps.maxMegabytes,
        awsProps.acceptedDocumentTypes,
        awsProps.getUploadParameters,
        _onStartLoad,
        _onProgress,
        _onError,
        _onSuccess,
      )
    } else {
      const gcsProps = props
      return initialiseGcsUppy(
        id,
        gcsProps.maxMegabytes,
        gcsProps.acceptedDocumentTypes,
        gcsProps.getUploadParameters,
        _onStartLoad,
        _onProgress,
        _onError,
        _onSuccess,
      )
    }
  }, [props, id])

  const [uppy, setUppy] = useState<Uppy>(mkUppy())

  useEffect(() => {
    if (hasLoaded) {
      setHasLoaded(false)
      setUppy(mkUppy())
    }
  }, [mkUppy, hasLoaded])

  return uppy
}

export default useUppy
export { StorageProvider } from "./storageProvider"

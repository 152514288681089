import type { SVGComponent } from "./IconWrapper"

const CalculateSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.87461 18.2751H9.12461V16.0751H11.3246V14.8251H9.12461V12.6251H7.87461V14.8251H5.67461V16.0751H7.87461V18.2751ZM13.1996 17.4001H18.2246V16.1751H13.1996V17.4001ZM13.1996 14.7251H18.2246V13.4751H13.1996V14.7251ZM14.1246 10.7001L15.6496 9.1751L17.1746 10.7001L18.0746 9.8001L16.5496 8.2751L18.0746 6.7501L17.1746 5.8501L15.6496 7.3751L14.1246 5.8501L13.2246 6.7501L14.7496 8.2751L13.2246 9.8001L14.1246 10.7001ZM6.04961 8.8751H10.9496V7.6251H6.04961V8.8751ZM4.54961 21.1501C4.09961 21.1501 3.70378 20.9793 3.36211 20.6376C3.02044 20.2959 2.84961 19.9001 2.84961 19.4501V4.5501C2.84961 4.08343 3.02044 3.68343 3.36211 3.3501C3.70378 3.01676 4.09961 2.8501 4.54961 2.8501H19.4496C19.9163 2.8501 20.3163 3.01676 20.6496 3.3501C20.9829 3.68343 21.1496 4.08343 21.1496 4.5501V19.4501C21.1496 19.9001 20.9829 20.2959 20.6496 20.6376C20.3163 20.9793 19.9163 21.1501 19.4496 21.1501H4.54961ZM4.54961 19.4501H19.4496V4.5501H4.54961V19.4501Z"
      fill="currentColor"
    />
  </svg>
)

export default CalculateSVG

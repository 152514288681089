import { DataPoint, DataPoints } from "src/common/dataPoints"
import { CellStatus, ValidationStatus } from "../types"

export type RowStatusCounts = Record<ValidationStatus, number> & {
  dataPoints: Record<DataPoint["field"], number>
}

// Calculate the numbers of valid rows; invalid rows; and rows with errors for a
// particular data point
export const calcRowStatusCounts = (
  dataPoints: DataPoints,
  cellStatuses: CellStatus[],
): RowStatusCounts => {
  const numRows =
    cellStatuses.length === 0
      ? 0
      : Math.max(...cellStatuses.map(c => c.rowIdx)) + 1

  const invalidCells = cellStatuses.filter(c => c.status === "invalid")

  const numInvalidRows = new Set(invalidCells.map(c => c.rowIdx)).size

  const dataPointCounts = dataPoints.reduce(
    (acc, { field }) => ({
      ...acc,
      [field]: new Set(invalidCells.filter(c => c.dataPointId === field)).size,
    }),
    {},
  )

  return {
    invalid: numInvalidRows,
    valid: numRows - numInvalidRows,
    dataPoints: dataPointCounts,
  }
}

import type { SVGComponent } from "./IconWrapper"

const EmptySVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.625 2V5H13.375V2H11.625ZM6.77197 6.48537L4.77197 3.48537L6.22806 2.51465L8.22806 5.51465L6.77197 6.48537ZM18.228 6.48537L20.228 3.48537L18.7719 2.51465L16.7719 5.51465L18.228 6.48537ZM3.25 15.875V19.75H20.75V15.875H16.1266C15.9032 16.5779 15.5019 17.1537 14.9572 17.577C14.2394 18.1348 13.3416 18.375 12.4318 18.375C11.5221 18.375 10.6242 18.1348 9.90641 17.577C9.36172 17.1537 8.96047 16.5779 8.73708 15.875H3.25ZM19.9844 14.125H14.6504L14.5555 14.8927C14.4766 15.5314 14.2148 15.9377 13.8835 16.1951C13.5383 16.4633 13.0449 16.625 12.4318 16.625C11.8188 16.625 11.3253 16.4633 10.9802 16.1951C10.6488 15.9377 10.387 15.5314 10.3081 14.8927L10.2133 14.125H4.01556L6.51556 9.75H17.4844L19.9844 14.125ZM5.5 8H18.5L22.5 15V21.5H1.5V15L5.5 8Z"
      fill="currentColor"
    />
  </svg>
)

export default EmptySVG

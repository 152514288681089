/**
 * ag-grid column definitions and related functions
 */
import { ApiClient } from "src/api"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { ASSET_UNIQUE_ID_FIELD, CellStatus, SOVAsset } from "../types"

import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
} from "@ag-grid-community/core"
import { ROW_INDEX_COLUMN_FIELD, rowIndexColumnDef } from "src/common/agGrid"

import CellRenderer from "./CellRenderer"
import SelectCellEditor, { SelectCellEditorParams } from "./SelectCellEditor"
import { parseNumber } from "src/common/validation"

export type RowData = Record<DataPoint["field"], unknown> &
  Record<typeof ROW_INDEX_COLUMN_FIELD, number> &
  Pick<SOVAsset, typeof ASSET_UNIQUE_ID_FIELD>

// https://www.ag-grid.com/react-data-grid/cell-editing-start-stop/#stop-editing
const GRID_FINISH_EDITING_KEYS = ["Enter", "Tab"]

type DefaultCellEditorSettings = Record<string, never>

interface SelectCellEditorSettings {
  cellEditor: typeof SelectCellEditor
  cellEditorParams: SelectCellEditorParams
  cellEditorPopup: ColDef<RowData>["cellEditorPopup"]
  suppressKeyboardEvent: ColDef<RowData>["suppressKeyboardEvent"]
}

interface NumberCellEditorSettings {
  valueParser: ColDef<RowData>["valueParser"]
}

type CellEditorSettings =
  | DefaultCellEditorSettings
  | SelectCellEditorSettings
  | NumberCellEditorSettings

const mkSelectCellEditorSettings = (
  cellEditorParams: SelectCellEditorParams,
): SelectCellEditorSettings => ({
  // Render our custom cell editor
  cellEditor: SelectCellEditor,
  cellEditorParams,
  // Render the editor in a popup, rather than directly inside the cell, so that
  // we doh't need to deal with `overflow` and `z-index` issues
  cellEditorPopup: true,
  // By default ag-grid will end cell editing (and thus unmount our custom cell
  // editor) whenever one of these keys is pressed. Unfortunately it does this
  // so aggressively that we have no way to intercept it, even with a listener
  // like `onKeyDown`. This is a problem because if the user presses one of
  // these keys (like Enter) to select a value, the cell editor will unmount
  // before the user's selected value can be stored. Therefore we instruct
  // ag-grid to suppress its usual behaviour, and let our custom editor tell it
  // when to end cell editing instead.
  suppressKeyboardEvent: params =>
    params.editing && GRID_FINISH_EDITING_KEYS.includes(params.event.key),
})

export const makeColumnDefs = (
  apiClient: ApiClient,
  dataPoints: DataPoints,
  cellStatuses: CellStatus[],
): ColDef<RowData>[] => {
  return dataPoints.reduce<ColDef[]>(
    (acc, dp, colIdx) => {
      let cellEditorSettings: CellEditorSettings

      switch (dp.type) {
        case "number":
          cellEditorSettings = {
            valueParser: ({ newValue }) => {
              const parseResult = parseNumber(newValue)
              return parseResult.status === "valid"
                ? parseResult.parsedValue
                : newValue
            },
          }
          break

        case "country": {
          const placeholder = "Select a country"
          cellEditorSettings = mkSelectCellEditorSettings({
            apiClient,
            dataPoint: dp,
            mapResultToLabel: res => res.value,
            mapResultToReturnValue: res => res.value,
            placeholder,
          })
          break
        }

        case "currency": {
          const placeholder = "Select a currency"
          cellEditorSettings = mkSelectCellEditorSettings({
            apiClient,
            dataPoint: dp,
            mapResultToLabel: res => res.key,
            mapResultToReturnValue: res => res.key,
            placeholder,
          })
          break
        }

        default:
          cellEditorSettings = {}
          break
      }

      const shouldRenderCellAsInvalid = (rowIdx?: number): boolean => {
        const cellStatus = cellStatuses.find(
          c => c.colIdx === colIdx && c.rowIdx === rowIdx,
        )

        return (cellStatus && cellStatus.status === "invalid") || false
      }

      return [
        ...acc,
        {
          field: dp.field,
          headerName: dp.label,

          // This width was chosen by the product team
          initialWidth: 150,

          editable: true,
          resizable: true,
          suppressMenu: true,
          suppressMovable: true,

          cellRenderer: (params: ICellRendererParams<RowData>) => {
            const rowIndex = params.data?.rowIndex
            return (
              <CellRenderer
                dataPoint={dp}
                isInvalidCell={shouldRenderCellAsInvalid(rowIndex)}
                {...params}
              />
            )
          },

          cellClass: (params: CellClassParams<RowData>) => {
            const rowIndex = params.data?.rowIndex
            return shouldRenderCellAsInvalid(rowIndex)
              ? "otto-highlight-invalid"
              : ""
          },

          ...cellEditorSettings,
        },
      ]
    },
    [rowIndexColumnDef(true)],
  )
}

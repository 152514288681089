import { DataPoint } from "src/common/dataPoints"
import { parseNumber, parseValue } from "src/common/validation"

import currency from "currency.js"

export const combineCells = (
  cells: unknown[],
  dataPoint: DataPoint,
): string | number | null => {
  switch (dataPoint.type) {
    case "text":
    case "date":
      return cells
        .map(c => (typeof c === "string" ? c.trim() : c))
        .filter(c => parseValue(dataPoint, c).status !== "empty")
        .join(", ")
    case "country":
    case "currency":
      return [
        ...new Set(
          cells
            .map(c => (typeof c === "string" ? c.trim() : c))
            .filter(c => parseValue(dataPoint, c).status !== "empty"),
        ),
      ].join(", ")
    case "number": {
      const numbers = cells.reduce<number[]>((numAcc, cell) => {
        const parseResult = parseNumber(cell)

        if (
          parseResult.status === "valid" ||
          parseResult.status === "invalid"
        ) {
          return [...numAcc, parseResult.parsedValue]
        } else {
          return numAcc
        }
      }, [])

      return numbers.length > 0
        ? currency(
            numbers.reduce((acc, n) => acc + n, 0),
            { precision: 2 },
          ).value
        : null
    }
  }
}

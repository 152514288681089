import { FC, Fragment, useId } from "react"

import { SelectMultiple, SelectOption, TipBanner } from "@appia/ui-components"

import LabelWithJumpButton from "./LabelWithJumpButton"
import { CurrencyLayout, ManualOrMappedCurrency } from "./CurrencyLayout"
export type { ManualOrMappedCurrency } from "./CurrencyLayout"

import { DataPoint, DataPointCurrency, DataPoints } from "src/common/dataPoints"
import { SheetWithContents, Table } from "src/common/types"

import { columnToIndex, indexToColumn, isNumber } from "src/common/utils"
import { getTableHeader } from "../utils"

const DataPointsControls: FC<{
  dataPoints: DataPoints
  onChangeCurrency: (c: ManualOrMappedCurrency) => void
  onChangeMapping: (field: DataPoint["field"], indices: number[]) => void
  onGoToDataPoint: (id: DataPoint["field"]) => void
  setActiveTableId: (tableId: Table["id"]) => void
  table: Table
  sheet: SheetWithContents
}> = ({
  dataPoints,
  onChangeCurrency,
  onChangeMapping,
  onGoToDataPoint,
  setActiveTableId,
  table,
  sheet,
}) => {
  const { orientation, extracted, top_left, bottom_right } = table

  const tableWidth =
    columnToIndex(bottom_right.column) - columnToIndex(top_left.column) + 1
  const tableHeight = bottom_right.row - top_left.row + 1

  let idxToString: (i: number) => string
  let stringToIdx: (s: string) => number
  switch (orientation) {
    case "vertical":
    case "unknown": {
      const offset = columnToIndex(top_left.column)
      idxToString = i => indexToColumn(i + offset)
      stringToIdx = s => columnToIndex(s) - offset
      break
    }
    case "horizontal": {
      const offset = top_left.row
      idxToString = i => (i + offset).toString()
      stringToIdx = s => parseInt(s, 10) - offset
      break
    }
  }

  const tableHeaders = getTableHeader(sheet, table)
  const tableHeaderTitles: Record<string, string> = tableHeaders.reduce(
    (preValue: Record<string, string>, columnTitle, index) => {
      const columnLetter = idxToString(index)

      return {
        ...preValue,
        [columnLetter]: `${columnLetter} - ${columnTitle}`,
      }
    },
    {},
  )

  const allExtractedIndices = Object.values(extracted)
    .flat()
    .filter(isNumber)
    .map(idxToString)

  const selectBaseId = useId()

  const toCurrencyOptions = (dataPoint: DataPointCurrency): SelectOption[] =>
    Object.keys(dataPoint.options).map(currencyKey => ({
      label: currencyKey.toUpperCase(),
      value: currencyKey.toUpperCase(),
    }))

  const restOfDataPoints = dataPoints.filter(dp => dp.type !== "currency")

  const currencyDataPoint =
    dataPoints.filter(dp => dp.type === "currency") ?? []

  const dataPointsWithCurrencyLast = [
    ...restOfDataPoints,
    ...(currencyDataPoint ?? []),
  ]

  return (
    <>
      <hr aria-hidden className="border-top col-span-2 border-otto-grey-300" />
      <TipBanner className="col-span-2 my-2">
        <strong className="mr-2">Step 2</strong>
        <span>Check mapping (all fields are optional)</span>
      </TipBanner>

      <fieldset data-cy="data-points-fieldset" className="contents">
        <legend className="sr-only">Data points</legend>

        {dataPointsWithCurrencyLast.map(dataPoint => {
          const extractedIndices = extracted[dataPoint.field] ?? []

          const selectedValues = extractedIndices.map(idxToString)

          const options: SelectOption[] = [
            ...new Array(
              orientation === "horizontal" ? tableHeight : tableWidth,
            ),
          ]
            .map((_, i) => i)
            .map(idxToString)
            // Remove indices that have already been selected, unless
            // they're selected by this dropdown, in which case keep them so
            // that they still show up
            .filter(
              i =>
                !(
                  allExtractedIndices.includes(i) && !selectedValues.includes(i)
                ),
            )
            .map(c => ({ label: tableHeaderTitles[c], value: c }))

          const dpIsCurrency = dataPoint.type === "currency"

          const showJumpButton = selectedValues.length > 0

          const selectId = `${selectBaseId}-${dataPoint.field}`

          return (
            <Fragment key={dataPoint.field}>
              {!dpIsCurrency ? (
                <>
                  <LabelWithJumpButton
                    dataPoint={dataPoint}
                    inputId={selectId}
                    onGoToDataPoint={onGoToDataPoint}
                    showJumpButton={showJumpButton}
                  />

                  <SelectMultiple
                    id={selectId}
                    placeholder={`Select ${
                      orientation === "horizontal" ? "rows" : "columns"
                    }`}
                    options={options}
                    selectedValues={selectedValues}
                    onFocus={() => setActiveTableId(table.id)}
                    onSelect={vs => {
                      const indices: number[] = vs
                        .map(s => s.trim())
                        .map(stringToIdx)

                      onChangeMapping(dataPoint.field, indices)
                    }}
                  />
                </>
              ) : (
                <CurrencyLayout
                  dataPoint={dataPoint}
                  manualCurrencyOptions={toCurrencyOptions(dataPoint)}
                  mappedCurrencyConfig={{ options, selectedValues }}
                  onChange={onChangeCurrency}
                  onGoToDataPoint={onGoToDataPoint}
                  stringToIdx={stringToIdx}
                  setActiveTableId={setActiveTableId}
                  table={table}
                />
              )}
            </Fragment>
          )
        })}
      </fieldset>
    </>
  )
}

const DataPointsForm: FC<{
  dataPoints: DataPoints
  onChangeCurrency: (c: ManualOrMappedCurrency) => void
  onChangeMapping: (field: DataPoint["field"], indices: number[]) => void
  onGoToDataPoint: (dpId: DataPoint["field"]) => void
  setActiveTableId: (tableId: Table["id"]) => void
  table: Table
  sheet: SheetWithContents
  tableName: string
}> = ({
  dataPoints,
  onChangeCurrency,
  onChangeMapping,
  onGoToDataPoint,
  setActiveTableId,
  table,
  sheet,
  tableName,
}) => (
  <form aria-label={`Edit ${tableName} data points`} className="contents">
    <DataPointsControls
      dataPoints={dataPoints}
      onChangeCurrency={onChangeCurrency}
      onChangeMapping={onChangeMapping}
      onGoToDataPoint={onGoToDataPoint}
      setActiveTableId={setActiveTableId}
      table={table}
      sheet={sheet}
    />
  </form>
)

export default DataPointsForm

import { Table } from "src/common/types"
import { TableBounds } from "../types"
import { DataPoint, DataPoints } from "src/common/dataPoints"

import { columnToIndex, indexToColumn } from "src/common/utils"
import { cellWithinActiveTableHeader, makeCellClassNames } from "../utils"

import { ColDef } from "@ag-grid-community/core"
import { rowIndexColumnDef } from "src/common/agGrid"
import { RowData } from "./rowData"

import { SelectOption } from "@appia/ui-components"
import classNames from "classnames"

import HeaderCellRenderer from "./HeaderCellRenderer"

const HEADER_CELL_CLASS = "!overflow-y-visible !overflow-x-clip"

export const makeColumnDefs = (
  tables: Table[],
  tablePreview: TableBounds | null,
  activeTable: Table | null,
  dataPoints: DataPoints,
  activeDataPointId: DataPoint["field"] | null,
  maxRowLength: number,
  onExtract: (tableId: Table["id"], extracted: Table["extracted"]) => void,
): ColDef<RowData>[] => {
  const activeTableId = activeTable?.id ?? null

  const cellClassNameMapping = makeCellClassNames(
    tables,
    tablePreview,
    activeTableId,
    dataPoints,
    activeDataPointId,
  )

  const cellDropdownOptions: SelectOption[] = [
    { label: "None", value: "none" },
    ...dataPoints.map(dp => ({ label: dp.label, value: dp.field })),
  ]

  // When a data point is mapped to a column (or the mapping is removed)
  const onMapRowOrColumn = (
    idx: number,
    dataPointId: DataPoint["field"] | null,
  ): void => {
    if (!activeTable) {
      return
    }

    const { extracted, orientation, top_left } = activeTable

    const minRow = top_left.row - 1
    const minCol = columnToIndex(top_left.column)
    const offset = orientation === "horizontal" ? minRow : minCol
    const adjustedIdx = idx - offset

    const updatedExtracted = dataPoints.reduce((acc, dp) => {
      const filteredIndices = (extracted[dp.field] ?? []).filter(
        i => i !== adjustedIdx,
      )

      const updatedIndices =
        dp.field === dataPointId
          ? [...filteredIndices, adjustedIdx]
          : filteredIndices

      return { ...acc, [dp.field]: updatedIndices }
    }, {})

    onExtract(activeTable.id, updatedExtracted)
  }

  return Array.from(new Array(maxRowLength)).reduce<ColDef[]>(
    (acc, _, colIdx) => [
      ...acc,
      {
        field: indexToColumn(colIdx),

        resizable: true,
        suppressMenu: true,
        suppressMovable: true,

        cellClass: params => {
          const activeHeaderClassName = cellWithinActiveTableHeader(
            activeTable,
            params.rowIndex,
            colIdx,
          )
            ? HEADER_CELL_CLASS
            : ""

          const cellClassName =
            cellClassNameMapping[params.rowIndex]?.[colIdx] ?? ""

          return classNames(activeHeaderClassName, cellClassName)
        },

        cellRendererSelector: params => {
          if (
            activeTable &&
            cellWithinActiveTableHeader(activeTable, params.rowIndex, colIdx)
          ) {
            const rowOrColIdx =
              activeTable.orientation === "horizontal"
                ? params.rowIndex
                : colIdx

            return {
              component: HeaderCellRenderer,
              params: {
                activeTable,
                options: cellDropdownOptions,
                onMapRowOrColumn: (dpId: DataPoint["field"] | null) =>
                  onMapRowOrColumn(rowOrColIdx, dpId),
              },
            }
          } else {
            return undefined
          }
        },
      },
    ],
    [rowIndexColumnDef(false)],
  )
}

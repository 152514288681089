import { FC } from "react"

import { ICellRendererParams } from "@ag-grid-community/core"

import { ExclamationCircleIcon } from "@appia/ui-components"

import { DataPoint } from "src/common/dataPoints"
import { formatDecimal2DP } from "src/common/utils"
import { parseValue } from "src/common/validation"

import { RowData } from "./columns"

import classNames from "classnames"

interface Props extends ICellRendererParams<RowData> {
  dataPoint: DataPoint
  isInvalidCell: boolean
}

const CellRenderer: FC<Props> = ({ value, dataPoint, isInvalidCell }) => {
  const parseResult = parseValue(dataPoint, value)

  const formattedValue =
    (parseResult.status === "valid" || parseResult.status === "invalid") &&
    dataPoint.type === "number"
      ? formatDecimal2DP(parseResult.parsedValue as number)
      : value

  const className =
    dataPoint.type === "number" ? "w-full justify-between text-right" : ""

  return (
    <>
      {isInvalidCell ? (
        <div className={classNames("align-center flex", className)}>
          <ExclamationCircleIcon
            className="mr-1 w-5 shrink-0 text-otto-red-600"
            label="Invalid cell"
          />
          <p>{formattedValue}</p>
        </div>
      ) : (
        <p className={className}>{formattedValue}</p>
      )}
    </>
  )
}

export default CellRenderer

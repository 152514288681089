import { Component, ReactNode } from "react"

import { Callout, Card } from "@appia/ui-components"

import * as Sentry from "@sentry/react"

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error)
  }

  render(): ReactNode {
    const { error } = this.state
    const { children } = this.props

    return error ? (
      <Card className="m-4">
        <Callout type="error">
          <p>The page has encountered an error:</p>
          <code className="text-sm">{`${error}`}</code>
        </Callout>
      </Card>
    ) : (
      children
    )
  }
}

export default ErrorBoundary

import type { SVGComponent } from "./IconWrapper"

const ArrowRightAltSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11Z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowRightAltSVG

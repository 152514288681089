import { Dispatch, RefObject, SetStateAction } from "react"
import { AgGridReact } from "@ag-grid-community/react"

import {
  ApiClient,
  createTable,
  deleteTable,
  extractHeaderMapping,
  updateTables as updateTablesApi,
} from "src/api"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { SOV, SheetCoords, SheetWithContents, Table } from "src/common/types"
import { TableBounds } from "./types"

import { columnToIndex, indexToColumn } from "src/common/utils"
import {
  adjustExtractedIndices,
  getTableHeader,
  mkEmptyTable,
  tableHeaderHasChanged,
} from "./utils"

import { ManualOrMappedCurrency } from "./TableCard"

export type RunAsyncActionWithToast = (
  action: () => Promise<void>,
  successMsg: string,
  errorMsg: string,
  callbacks?: {
    onSuccess?: () => void
    onError?: () => void
    onFinally?: () => void
  },
) => Promise<void>

export type UpdateTable = (id: Table["id"], t: Partial<Table>) => void

export const scrollToCell = (
  gridRef: RefObject<AgGridReact>,
  coords: SheetCoords,
): void => {
  if (gridRef.current && gridRef.current.api) {
    gridRef.current.api.ensureIndexVisible(coords.row - 1, "top")
    gridRef.current.api.ensureColumnVisible(coords.column, "middle")
  }
}

export const onAddTable = async (
  apiClient: ApiClient,
  sovId: SOV["id"],
  setTables: Dispatch<SetStateAction<Table[]>>,
  runAsyncActionWithToast: RunAsyncActionWithToast,
  sheet: SheetWithContents,
): Promise<void> => {
  const numSheetRows = sheet.contents.length
  const numSheetColumns = sheet.contents[0]?.length ?? 0

  const maxPossibleCoords: SheetCoords = {
    column: indexToColumn(numSheetColumns - 1),
    row: numSheetRows,
  }

  const tableWithoutId = mkEmptyTable({
    sheet_id: sheet.id,
    top_left: { row: 1, column: "A" },
    bottom_right: maxPossibleCoords,
  })

  await runAsyncActionWithToast(
    async () => {
      const { data: tableWithId } = await createTable(
        apiClient,
        sovId,
        tableWithoutId,
      )

      setTables(ts => [...ts, tableWithId])
    },
    "Table created",
    "Failed to add new table",
  )
}

export const onChangeTableCurrency = (
  dataPoints: DataPoints,
  updateTable: UpdateTable,
  table: Table,
  manualOrMapped: ManualOrMappedCurrency,
): void => {
  // If the user set a manual / table-level currency, clear out
  // the mappings from any currency data points
  if (manualOrMapped.type === "manualCurrency") {
    const extractedWithCurrenciesRemoved = dataPoints
      .filter(dp => dp.type === "currency")
      .reduce(
        (acc, dp) => ({
          ...acc,
          [dp.field]: [],
        }),
        table.extracted,
      )

    updateTable(table.id, {
      currency: manualOrMapped.value,
      extracted: extractedWithCurrenciesRemoved,
    })
  }
  // Otherwise the user added a mapping for a currency data
  // point, so clear out the manual currency
  else {
    updateTable(table.id, {
      currency: undefined,
      extracted: {
        ...table.extracted,
        [manualOrMapped.field]: manualOrMapped.indices,
      },
    })
  }
}

export const onDeleteTable = async (
  apiClient: ApiClient,
  sovId: SOV["id"],
  setTables: Dispatch<SetStateAction<Table[]>>,
  runAsyncActionWithToast: RunAsyncActionWithToast,
  tableId: Table["id"],
): Promise<void> => {
  await runAsyncActionWithToast(
    async () => {
      await deleteTable(apiClient, sovId, tableId)
      setTables(ts => ts.filter(t => t.id !== tableId))
    },
    "Table deleted",
    "Failed to delete table",
  )
}

export const onGoToDataPoint = (
  gridRef: RefObject<AgGridReact>,
  dataPointId: DataPoint["field"],
  table: Table,
): void => {
  const firstExtractedIdx: number | undefined =
    table.extracted[dataPointId]?.[0]
  if (firstExtractedIdx === undefined) {
    return
  }

  let coords: SheetCoords
  switch (table.orientation) {
    case "vertical":
    case "unknown": {
      const offset = columnToIndex(table.top_left.column)
      coords = {
        row: table.top_left.row,
        column: indexToColumn(firstExtractedIdx + offset),
      }
      break
    }
    case "horizontal": {
      const offset = table.top_left.row
      coords = {
        row: firstExtractedIdx + offset,
        column: table.top_left.column,
      }
      break
    }
  }

  scrollToCell(gridRef, coords)
}

export const onSaveTables =
  (
    apiClient: ApiClient,
    sovId: SOV["id"],
    updateTable: UpdateTable,
    runAsyncActionWithToast: RunAsyncActionWithToast,
  ) =>
  async (tables: Table[]): Promise<void> => {
    const hasMultipleTables = tables.length > 1
    const successMessage = `${hasMultipleTables ? "Tables" : "Table"} confirmed`
    const failMessage = `Failed to save ${
      hasMultipleTables ? "tables" : "table"
    }`

    await runAsyncActionWithToast(
      async () => {
        await updateTablesApi(apiClient, sovId, tables).then(
          ({ data: tables }) =>
            tables.forEach(table => updateTable(table.id, table)),
        )
      },
      successMessage,
      failMessage,
    )
  }

export const onSetTableBounds = async (
  apiClient: ApiClient,
  sovId: SOV["id"],
  dataPoints: DataPoints,
  updateTable: UpdateTable,
  runAsyncActionWithToast: RunAsyncActionWithToast,
  sheet: SheetWithContents,
  table: Table,
  bounds: TableBounds,
): Promise<void> => {
  const newTable: Table = { ...table, ...bounds }

  // Update the table with the new bounds regardless of anything else
  updateTable(table.id, newTable)

  // If the header hasn't changed, there's nothing else to do
  if (!tableHeaderHasChanged(table, newTable)) {
    return
  }

  await runAsyncActionWithToast(
    // Try to fetch a new header mapping from the API to replace the current one
    async () => {
      const {
        data: { extracted },
      } = await extractHeaderMapping(
        apiClient,
        sovId,
        getTableHeader(sheet, newTable),
      )
      updateTable(table.id, { extracted })
    },
    "Header suggestions applied",
    "Failed to apply header suggestions",
    {
      // If the API call failed and we didn't get a new header mapping, we
      // preserve the old one but adjust it such that the mapped rows/columns
      // don't appear to move
      onError: () => {
        // If the orientation has changed then it doesn't make sense to preserve
        // the header mapping, so we remove it
        if (table.orientation !== newTable.orientation) {
          updateTable(table.id, { extracted: {} })
        }
        // Otherwise, adjust the indices
        else {
          updateTable(table.id, {
            extracted: adjustExtractedIndices(
              dataPoints,
              table.top_left,
              newTable,
            ),
          })
        }
      },
    },
  )
}

import { FC, useId } from "react"

import { Callout } from "@appia/ui-components"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { formatCurrencyWithSymbol } from "src/common/utils"

import { TotalsPerCurrency } from "../../types"
import { dataPointTotalsMatch } from "../../money"

const cellCss = "font-normal border border-otto-grey-200 py-1 px-2"

const TotalsTable: FC<{
  currency: string
  dataPoints: DataPoints
  dataPointTotals: Record<DataPoint["field"], number>
}> = ({ currency, dataPoints, dataPointTotals }) => {
  const calloutId = useId()
  const totalsAreMismatched = !dataPointTotalsMatch(dataPointTotals)

  return (
    <article>
      <table aria-describedby={calloutId} className="w-full text-left">
        <caption className="pb-2 text-left font-bold">
          {currency} totals
        </caption>

        <thead className="sr-only">
          <tr>
            <th className={cellCss}>Data point</th>
            <th className={cellCss}>Total</th>
          </tr>
        </thead>
        <tbody>
          {dataPoints
            .filter(dp => dp.type === "number")
            .map(dp => (
              <tr key={dp.field}>
                <td className={cellCss}>{dp.label}</td>
                <td className={cellCss}>
                  {formatCurrencyWithSymbol(
                    dataPointTotals[dp.field],
                    currency,
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {totalsAreMismatched && (
        <Callout
          id={calloutId}
          className="mt-2"
          size="small"
          style="outlined"
          type="warning"
          role="status"
        >
          <p>
            <span className="sr-only">{currency} </span>BI + PD does not equal
            TIV
          </p>
        </Callout>
      )}
    </article>
  )
}

const SummariesCard: FC<{
  dataPoints: DataPoints
  totalsPerCurrency: TotalsPerCurrency
}> = ({ dataPoints, totalsPerCurrency }) => {
  const headingId = useId()

  const sortedTotals = Object.entries(totalsPerCurrency)
    .map(([currency, totalsPerField]) => [currency, totalsPerField] as const)
    .sort((a, b) => {
      const largestA = Math.max(...Object.values(a[1]))
      const largestB = Math.max(...Object.values(b[1]))
      return largestB - largestA
    })

  if (sortedTotals.length === 0) {
    return (
      <p className="py-4 px-2 text-sm">
        Assign currencies to rows in order to see summaries of the data.
      </p>
    )
  }

  return (
    <section aria-labelledby={headingId}>
      <h3 className="sr-only" id={headingId}>
        Currency summaries
      </h3>

      <div className="[&>*]:basis-[48%] flex flex-wrap gap-4 p-2">
        {sortedTotals.map(([currency, totals]) => (
          <TotalsTable
            key={currency}
            currency={currency}
            dataPoints={dataPoints}
            dataPointTotals={totals}
          />
        ))}
      </div>
    </section>
  )
}

export default SummariesCard

import { FC } from "react"

import { VisibilityIcon } from "@appia/ui-components"

import { EditingView } from "./types"

import classNames from "classnames"

const Pill: FC<{ editingView: EditingView }> = ({ editingView }) => {
  if (editingView.mode === "default") {
    return <span className="sr-only">Showing all rows</span>
  }

  const filterText =
    editingView.mode === "allInvalid"
      ? "invalid rows"
      : `invalid ${editingView.dataPoint.label} rows`

  return (
    <span className="inline-flex items-center gap-1 whitespace-nowrap rounded-full bg-otto-red-50 px-2 text-sm text-error forced-colors:border forced-colors:border-transparent">
      <VisibilityIcon className="w-4 shrink-0 text-otto-red-600" />

      <span data-cy="filter-pill-text">
        Showing <b>{filterText}</b> only
      </span>
    </span>
  )
}

const SpreadsheetToolbar: FC<{ editingView: EditingView }> = ({
  editingView,
}) => (
  <div
    className={classNames(
      "rounded-t-md bg-otto-night py-2 px-2 text-white",
      "forced-colors:border forced-colors:border-otto-grey-400",
      editingView.mode === "default" ? "bg-otto-night" : "bg-otto-red-900",
    )}
  >
    <p className="flex flex-wrap items-center gap-x-4 gap-y-2 truncate text-base">
      <span>Extracted data</span>

      <span role="status">
        <Pill editingView={editingView} />
      </span>
    </p>
  </div>
)

export default SpreadsheetToolbar

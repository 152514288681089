import { DataPoint, DataPoints } from "src/common/dataPoints"
import { SOVAsset, SOVAssets, TotalsPerCurrency } from "./types"

import { ParseResult, parseValue } from "src/common/validation"

// Round a number two 2 decimal places, to account for floating point weirdness,
// and return 0 instead of -0 if it occurs
const handleFloatingPoints = (n: number): number => {
  const res = Math.round((n + Number.EPSILON) * 100) / 100
  return Object.is(-0, res) ? 0 : res
}

// TODO We should avoid hard-coding these field identifiers because data points
// are configurable and so these could change. See APP-1362 and related tickets
export const BI_FIELD = "business_indemnity"
export const PD_FIELD = "property_damage"
export const TIV_FIELD = "total_insured_value"

interface AutoFillCallbackParams {
  biParseResult: ParseResult
  pdParseResult: ParseResult
  tivParseResult: ParseResult
  asset: SOVAsset
}

const autoFillMoneyValue = (
  dataPoints: DataPoints,
  assets: SOVAssets,
  f: (p: AutoFillCallbackParams) => SOVAsset,
): SOVAssets => {
  const biDp = dataPoints.find(({ field }) => field === BI_FIELD)
  const pdDp = dataPoints.find(({ field }) => field === PD_FIELD)
  const tivDp = dataPoints.find(({ field }) => field === TIV_FIELD)

  if (!biDp || !pdDp || !tivDp) {
    return assets
  }

  return assets.map(asset => {
    const biParseResult = parseValue(biDp, asset[BI_FIELD])
    const pdParseResult = parseValue(pdDp, asset[PD_FIELD])
    const tivParseResult = parseValue(tivDp, asset[TIV_FIELD])

    return f({ biParseResult, pdParseResult, tivParseResult, asset })
  })
}

export const autoFillBi = (
  dataPoints: DataPoints,
  assets: SOVAssets,
): SOVAssets =>
  autoFillMoneyValue(
    dataPoints,
    assets,
    ({ biParseResult, pdParseResult, tivParseResult, asset }) => {
      if (
        biParseResult.status === "empty" &&
        (pdParseResult.status === "valid" ||
          pdParseResult.status === "invalid") &&
        typeof pdParseResult.parsedValue === "number" &&
        (tivParseResult.status === "valid" ||
          tivParseResult.status === "invalid") &&
        typeof tivParseResult.parsedValue === "number"
      ) {
        return {
          ...asset,
          [BI_FIELD]: handleFloatingPoints(
            tivParseResult.parsedValue - pdParseResult.parsedValue,
          ),
        }
      } else return asset
    },
  )

export const autoFillPd = (
  dataPoints: DataPoints,
  assets: SOVAssets,
): SOVAssets =>
  autoFillMoneyValue(
    dataPoints,
    assets,
    ({ biParseResult, pdParseResult, tivParseResult, asset }) => {
      if (
        (biParseResult.status === "valid" ||
          biParseResult.status === "invalid") &&
        typeof biParseResult.parsedValue === "number" &&
        pdParseResult.status === "empty" &&
        (tivParseResult.status === "valid" ||
          tivParseResult.status === "invalid") &&
        typeof tivParseResult.parsedValue === "number"
      ) {
        return {
          ...asset,
          [PD_FIELD]: handleFloatingPoints(
            tivParseResult.parsedValue - biParseResult.parsedValue,
          ),
        }
      } else return asset
    },
  )

export const autoFillTiv = (
  dataPoints: DataPoints,
  assets: SOVAssets,
): SOVAssets =>
  autoFillMoneyValue(
    dataPoints,
    assets,
    ({ biParseResult, pdParseResult, tivParseResult, asset }) => {
      if (
        (biParseResult.status === "valid" ||
          biParseResult.status === "invalid") &&
        typeof biParseResult.parsedValue === "number" &&
        (pdParseResult.status === "valid" ||
          pdParseResult.status === "invalid") &&
        typeof pdParseResult.parsedValue === "number" &&
        tivParseResult.status === "empty"
      ) {
        return {
          ...asset,
          [TIV_FIELD]: handleFloatingPoints(
            biParseResult.parsedValue + pdParseResult.parsedValue,
          ),
        }
      } else return asset
    },
  )

export const calculateTotalsPerCurrency = (
  dataPoints: DataPoints,
  assets: SOVAssets,
): TotalsPerCurrency => {
  // TODO What if there are multiple currency data points? This should be
  // resolved by APP-1246
  const currencyDataPoint = dataPoints.find(dp => dp.type === "currency")

  if (!currencyDataPoint) {
    return {}
  }

  const numericDataPoints = dataPoints.filter(dp => dp.type === "number")

  const assetsWithCurrencies = assets.filter(
    asset =>
      parseValue(currencyDataPoint, asset[currencyDataPoint.field]).status ===
      "valid",
  )

  return assetsWithCurrencies.reduce<TotalsPerCurrency>((acc, asset) => {
    // This assertion is OK because we previously filtered to only include
    // valid currencies
    const currencyValue = asset[currencyDataPoint.field] as string

    return {
      ...acc,
      [currencyValue]: numericDataPoints.reduce((currencyAcc, dp) => {
        const numericValue = asset[dp.field]
        const total = currencyAcc[dp.field] ?? 0

        const parseResult = parseValue(dp, numericValue)
        return parseResult.status === "valid" ||
          parseResult.status === "invalid"
          ? {
              ...currencyAcc,
              [dp.field]: total + (parseResult.parsedValue as number),
            }
          : { ...currencyAcc, [dp.field]: total }
      }, acc[currencyValue] || {}),
    }
  }, {})
}

export const dataPointTotalsMatch = (
  dataPointTotals: Record<DataPoint["field"], number>,
): boolean =>
  handleFloatingPoints(
    dataPointTotals[BI_FIELD] + dataPointTotals[PD_FIELD],
  ) === handleFloatingPoints(dataPointTotals[TIV_FIELD])

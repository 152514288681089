import { FC } from "react"

import {
  ArrowRightIcon,
  BasePill,
  Button,
  Card,
  CheckFilledCircleIcon,
  ChevronRightIcon,
  ExclamationPageIcon,
  QuestionPageIcon,
  TipBanner,
} from "@appia/ui-components"

import SidebarStatus from "src/components/SidebarStatus"

import { Sheet, Table } from "src/common/types"
import { SheetStatus } from "./types"

import { countExtractedRowsOrColumns, toSheetStatus } from "./utils"
import { printCount } from "src/common/utils"

import { useFocusHeading } from "src/common/hooks"

const ContinueButton: FC<{
  onContinue: () => void
  tables: Table[]
}> = ({ onContinue, tables }) => {
  const noTablesConfirmed = tables.every(t => !t.confirmed)

  return (
    <Card className="flex flex-col items-center justify-between gap-4 lg:flex-row">
      {noTablesConfirmed ? (
        <SidebarStatus
          Icon={ExclamationPageIcon}
          iconColor="text-otto-yellow-500"
          textColor="text-otto-yellow-900"
          message="Confirm at least one sheet to continue"
          label="In progress"
        />
      ) : (
        <SidebarStatus
          Icon={CheckFilledCircleIcon}
          iconColor="text-otto-green-900"
          textColor="text-otto-green-900"
          message="Continue to review & submit your SOV"
          label="Complete"
        />
      )}

      <Button
        data-cy="sidebar-status-button"
        disabled={noTablesConfirmed}
        icon={{ position: "right", icon: <ArrowRightIcon /> }}
        label="Continue"
        onClick={onContinue}
        size="small"
        style="filled"
        theme="night"
      />
    </Card>
  )
}

const numExtractedClass: Record<SheetStatus, string> = {
  confirmed: "bg-otto-green-50",
  incomplete: "bg-otto-yellow-50",
  empty: "bg-otto-grey-100",
}

const statusToPill: Record<SheetStatus, JSX.Element> = {
  confirmed: (
    <BasePill
      icon={
        <CheckFilledCircleIcon
          className="text-otto-green-900"
          label="Complete"
        />
      }
      label="Confirmed"
      bgColor="bg-otto-green-50"
      textColor="text-otto-green-900"
    />
  ),
  incomplete: (
    <BasePill
      icon={
        <ExclamationPageIcon
          className="text-otto-yellow-400"
          label="In progress"
        />
      }
      label="SOV data identified"
      bgColor="bg-otto-yellow-50"
      textColor="text-otto-yellow-900"
    />
  ),
  empty: (
    <BasePill
      icon={<QuestionPageIcon className="text-otto-grey-600" label="Empty" />}
      label="No SOV data identified"
      bgColor="bg-otto-grey-200"
      textColor="text-otto-grey-800"
    />
  ),
}

const Sidebar: FC<{
  sheets: Sheet[]
  tables: Table[]
  setActiveSheetIdx: (i: number) => void
  setActiveTableId: (tableId: Table["id"] | null) => void
  setExtractionModalOpen: (isExtractionModalOpen: boolean) => void
  populateSheetContentsAndSelect: (sheet: Sheet, selectSheet: boolean) => void
}> = ({
  sheets,
  tables,
  setActiveSheetIdx,
  setActiveTableId,
  setExtractionModalOpen,
  populateSheetContentsAndSelect,
}) => {
  const headingRef = useFocusHeading()

  return (
    <div className="grid h-full grid-rows-[auto,1fr] gap-2">
      <h3 ref={headingRef} tabIndex={-1} className="sr-only">
        Select sheet
      </h3>

      <TipBanner>Select sheets containing SOV data</TipBanner>

      <div className="grid grid-rows-[1fr,auto] gap-2">
        <div className="relative overflow-y-auto">
          <div className="absolute h-full w-full">
            {sheets.map((sheet, sheetIdx) => {
              const sheetTables = tables.filter(t => t.sheet_id === sheet.id)
              const sheetHasNoTables = sheetTables.length < 1
              const sheetFullyConfirmed = sheetTables.every(t => !!t.confirmed)

              const sheetStatus: SheetStatus = toSheetStatus(
                sheetFullyConfirmed,
                sheetHasNoTables,
              )

              const numExtracted = countExtractedRowsOrColumns(sheetTables)

              const buttonLabel = `Go to the ${sheet.name} sheet`

              return (
                <Card
                  key={sheet.id}
                  className="mb-8 hover:drop-shadow-md active:border active:border-black"
                  padding={0}
                >
                  <button
                    data-cy="sheet-select"
                    aria-label={buttonLabel}
                    title={buttonLabel}
                    onClick={() => {
                      setActiveSheetIdx(
                        sheets.findIndex(s => s.id === sheet.id),
                      )
                      const newActiveTableId =
                        tables.find(t => t.sheet_id === sheet.id)?.id ?? null
                      setActiveTableId(newActiveTableId)
                      populateSheetContentsAndSelect(sheet, true)
                    }}
                    className="h-full w-full text-left"
                  >
                    <div className="my-3 mx-4 flex items-center justify-between">
                      <div>
                        <span className="block text-sm">
                          Sheet {sheetIdx + 1}
                        </span>
                        <span className="mb-1 block truncate font-bold">
                          {sheet.name}
                        </span>

                        {statusToPill[sheetStatus]}
                      </div>

                      <ChevronRightIcon className="w-6" />
                    </div>

                    <p
                      className={`${numExtractedClass[sheetStatus]} rounded-b-[calc(theme('borderRadius.md')-1px)] border-t px-4 py-1`}
                    >
                      {printCount(numExtracted, "row")} included
                    </p>
                  </button>
                </Card>
              )
            })}
          </div>
        </div>

        <ContinueButton
          onContinue={() => setExtractionModalOpen(true)}
          tables={tables}
        />
      </div>
    </div>
  )
}

export default Sidebar

import { SOV } from "./common/types"

// Ref: https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions
export interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Record<string, unknown>) => void
  addEventProperties: (properties: Record<string, unknown>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  config: any
}

// Heap events that we track manually
export type HeapEvent =
  | { type: "FileUploaded"; sovId: SOV["id"] }
  | { type: "UploadSkipped"; sovId: SOV["id"] }
  | { type: "JourneyCompleted"; sovId: SOV["id"]; isManualJourney: boolean }

export const trackHeapEvent = ({ type, ...rest }: HeapEvent): void => {
  if (window?.heap) {
    window.heap.track(type, rest)
  }
}

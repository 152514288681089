import { FC, forwardRef, useRef, useState } from "react"

import {
  Card,
  CheckFilledCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationPageIcon,
  IconButton,
  TargetIcon,
  TipBanner,
  TrashIcon,
} from "@appia/ui-components"
import * as Popover from "@radix-ui/react-popover"
import * as Collapsible from "@radix-ui/react-collapsible"

import TableBoundsForm from "./TableBoundsForm"
import DataPointsForm, { ManualOrMappedCurrency } from "./DataPointsForm"
export type { ManualOrMappedCurrency } from "./DataPointsForm"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { SheetWithContents, Table } from "src/common/types"
import { TableBounds } from "../types"

import { printCoords } from "src/common/utils"

import classNames from "classnames"
import "./index.css"

const PopoverContainer = forwardRef<HTMLDivElement, Record<string, unknown>>(
  (_, ref) => (
    <div
      ref={ref}
      className={classNames(
        // Line up with the grid
        "z-10 col-start-2",
        // The popover has `position: fixed`, which is normally relative to the
        // viewport. By setting `will-change: transform` we make the fixed
        // positioning relative to this parent element instead
        // See: https://developer.mozilla.org/en-US/docs/Web/CSS/position
        "will-change-transform",
        // Override the popover's min-width
        "[&>*]:!min-w-[auto]",
      )}
    />
  ),
)

const TableBoundsPopover: FC<{
  numSheetColumns: number
  numSheetRows: number
  onJumpToTable: () => void
  setTableBounds: (tb: TableBounds) => void
  setTablePreview: (tp: TableBounds | null) => void
  setActiveTableId: (tableId: Table["id"]) => void
  table: Table
  tableName: string
}> = ({
  numSheetColumns,
  numSheetRows,
  onJumpToTable,
  setTableBounds,
  setTablePreview,
  setActiveTableId,
  table,
  tableName,
}) => {
  const currentRange = `${printCoords(table.top_left)}:${printCoords(
    table.bottom_right,
  )}`

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const buttonTitle = `Jump to ${tableName}`

  return (
    <Popover.Root
      open={popoverOpen}
      onOpenChange={() => {
        setPopoverOpen(!popoverOpen)
        setActiveTableId(table.id)
      }}
    >
      <button
        className="range-label-container flex w-fit items-end border-b border-b-otto-grey-700 pb-0.5"
        onClick={onJumpToTable}
        aria-label={buttonTitle}
        title={buttonTitle}
      >
        <span className="cursor-pointer text-sm">Range</span>

        <TargetIcon className="ml-1 w-5 text-otto-grey-700" />
      </button>

      <Popover.Trigger
        data-cy="range-select"
        aria-label={`Edit table range. Current range: ${currentRange}`}
        className="otto-focus flex w-full items-center justify-between gap-2 rounded border border-otto-grey-300 p-1"
      >
        <span className="lining-nums">{currentRange}</span>

        {popoverOpen ? (
          <ChevronUpIcon className="w-6" />
        ) : (
          <ChevronDownIcon className="w-6" />
        )}
      </Popover.Trigger>

      <PopoverContainer ref={containerRef} />

      <Popover.Portal container={containerRef.current}>
        <Popover.Content sideOffset={10}>
          <TableBoundsForm
            numSheetColumns={numSheetColumns}
            numSheetRows={numSheetRows}
            onSubmit={bounds => {
              setTableBounds(bounds)
              setPopoverOpen(false)
            }}
            setTablePreview={setTablePreview}
            table={table}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const TableCard: FC<{
  dataPoints: DataPoints
  numGlobalTables: number
  onChangeCurrency: (c: ManualOrMappedCurrency) => void
  onChangeMapping: (field: DataPoint["field"], indices: number[]) => void
  onDeleteTable: () => void
  onGoToDataPoint: (dpId: DataPoint["field"]) => void
  onJumpToTable: () => void
  setTableBounds: (tb: TableBounds) => void
  setTablePreview: (tp: TableBounds | null) => void
  setActiveTableId: (tableId: Table["id"]) => void
  sheet: SheetWithContents
  table: Table
  tableName: string
}> = ({
  dataPoints,
  numGlobalTables,
  onChangeCurrency,
  onChangeMapping,
  onDeleteTable,
  onGoToDataPoint,
  onJumpToTable,
  setTableBounds,
  setTablePreview,
  setActiveTableId,
  sheet,
  table,
  tableName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const numSheetRows = sheet.contents.length
  const numSheetColumns = sheet.contents[0]?.length ?? 0
  const cardHeaderBgColour = table.confirmed
    ? "bg-otto-green-50"
    : "bg-otto-yellow-50"

  return (
    <Card padding={0}>
      <Collapsible.Root
        key={table.id}
        open={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
      >
        <div
          className={classNames(
            `flex items-center justify-between gap-2 rounded-t-[calc(theme('borderRadius.md')-1px)] p-2 ${cardHeaderBgColour}`,
            {
              "border-b border-otto-grey-400": isOpen,
              "rounded-b-[calc(theme('borderRadius.md')-1px)]": !isOpen,
            },
          )}
        >
          <span className="block w-6">
            {table.confirmed ? (
              <CheckFilledCircleIcon
                className="text-otto-green-900"
                label="Confirmed"
              />
            ) : (
              <ExclamationPageIcon
                className="text-otto-yellow-400"
                label="Not confirmed"
              />
            )}
          </span>

          <h3 className="mr-auto text-base font-bold text-primary">
            {tableName}
          </h3>

          <IconButton
            color="text-otto-red-700"
            label={`Remove ${tableName}`}
            icon={<TrashIcon />}
            size={5}
            onClick={onDeleteTable}
            disabled={numGlobalTables < 2}
          />

          <Collapsible.Trigger
            aria-label="Expand table card"
            className="otto-focus rounded text-otto-grey-900"
          >
            {isOpen ? (
              <ChevronUpIcon className="w-6" />
            ) : (
              <ChevronDownIcon className="w-6" />
            )}
          </Collapsible.Trigger>
        </div>

        <Collapsible.Content>
          <TipBanner className="m-2 mt-3">
            <strong className="mr-2">Step 1</strong>
            <span>Check & confirm your range</span>
          </TipBanner>

          <div className="extraction-screen-form-container grid grid-cols-[minmax(7rem,auto),1fr] items-center justify-start gap-2 p-2">
            <TableBoundsPopover
              numSheetColumns={numSheetColumns}
              numSheetRows={numSheetRows}
              onJumpToTable={onJumpToTable}
              setTableBounds={setTableBounds}
              setTablePreview={setTablePreview}
              setActiveTableId={setActiveTableId}
              table={table}
              tableName={tableName}
            />

            <DataPointsForm
              dataPoints={dataPoints}
              onChangeCurrency={onChangeCurrency}
              onChangeMapping={onChangeMapping}
              onGoToDataPoint={onGoToDataPoint}
              setActiveTableId={setActiveTableId}
              table={table}
              sheet={sheet}
              tableName={tableName}
            />
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  )
}

export default TableCard

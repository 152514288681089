import { Dispatch, FC, ReactNode, SetStateAction } from "react"

import { DataPoints } from "src/common/dataPoints"
import { CellStatus, EditingView, TotalsPerCurrency } from "../../types"
import { RowStatusCounts } from "../statuses"

import {
  ArrowRightIcon,
  Button,
  Card,
  CheckFilledCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationFilledCircleIcon,
  TipBanner,
} from "@appia/ui-components"

import * as Collapsible from "@radix-ui/react-collapsible"

import SidebarStatus from "src/components/SidebarStatus"

import SummariesCard from "./SummariesCard"
import ValidationCard from "./ValidationCard"

import classNames from "classnames"

const ContinueButton: FC<{
  cellStatuses: CellStatus[]
  onContinue: () => void
}> = ({ cellStatuses, onContinue }) => {
  const someCellsAreInvalid = cellStatuses.some(c => c.status === "invalid")

  return (
    <div className="flex flex-col items-center justify-between gap-4 lg:flex-row">
      {someCellsAreInvalid ? (
        <SidebarStatus
          Icon={ExclamationFilledCircleIcon}
          iconColor="text-otto-bright-red"
          textColor="text-error"
          message="Fix or remove invalid rows"
          label="In progress"
        />
      ) : (
        <SidebarStatus
          Icon={CheckFilledCircleIcon}
          iconColor="text-otto-green-900"
          textColor="text-otto-green-900"
          message="Your SOV is ready to be submitted"
          label="Complete"
        />
      )}

      <Button
        data-cy="sidebar-status-button"
        disabled={someCellsAreInvalid}
        icon={{ position: "right", icon: <ArrowRightIcon /> }}
        label="Continue"
        onClick={() => {
          onContinue()
        }}
        size="small"
        style="filled"
        theme="night"
      />
    </div>
  )
}

const CollapsibleCard: FC<{
  children: ReactNode
  label: string
  onOpenChange: (open: boolean) => void
  open: boolean
}> = ({ children, label, onOpenChange, open }) => (
  <Card padding={0} className="rounded-lg border-otto-grey-300 shadow-none">
    <Collapsible.Root open={open} onOpenChange={onOpenChange}>
      <Collapsible.Trigger
        className={classNames(
          "otto-focus-inset flex w-full items-center justify-between gap-2 rounded-t-lg py-2 px-4 text-left",
          { "border-b border-b-otto-grey-300": open, "rounded-b-lg": !open },
        )}
      >
        <span className="font-bold">{label}</span>

        <span className="block w-8">
          {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </span>
      </Collapsible.Trigger>

      <Collapsible.Content>{children}</Collapsible.Content>
    </Collapsible.Root>
  </Card>
)

export type CollapsibleSectionKey = "summary" | "validation"

const OverviewSidebar: FC<{
  cellStatuses: CellStatus[]
  collapsibleStates: Record<CollapsibleSectionKey, boolean>
  dataPoints: DataPoints
  onFixErrorsClick: (v: EditingView) => void
  onContinue: () => void
  rowStatusCounts: RowStatusCounts
  setCollapsibleStates: Dispatch<
    SetStateAction<Record<CollapsibleSectionKey, boolean>>
  >
  totalsPerCurrency: TotalsPerCurrency
}> = ({
  cellStatuses,
  collapsibleStates,
  dataPoints,
  onFixErrorsClick,
  onContinue,
  rowStatusCounts,
  setCollapsibleStates,
  totalsPerCurrency,
}) => (
  <div className="grid h-full grid-rows-[auto,1fr] gap-4">
    <TipBanner>Review and validate your SOV data to continue</TipBanner>

    <div className="grid grid-rows-[1fr,auto]">
      <div className="relative overflow-y-auto">
        <div className="absolute grid h-full w-full auto-rows-min items-start gap-8">
          <CollapsibleCard
            label="Validation"
            open={collapsibleStates["validation"]}
            onOpenChange={open =>
              setCollapsibleStates(cs => ({ ...cs, validation: open }))
            }
          >
            <ValidationCard
              dataPoints={dataPoints}
              onFixErrorsClick={onFixErrorsClick}
              rowStatusCounts={rowStatusCounts}
            />
          </CollapsibleCard>

          <CollapsibleCard
            label="Summaries"
            open={collapsibleStates["summary"]}
            onOpenChange={open =>
              setCollapsibleStates(cs => ({ ...cs, summary: open }))
            }
          >
            <SummariesCard
              dataPoints={dataPoints}
              totalsPerCurrency={totalsPerCurrency}
            />
          </CollapsibleCard>
        </div>
      </div>

      <Card className="rounded-lg shadow-lg">
        <ContinueButton cellStatuses={cellStatuses} onContinue={onContinue} />
      </Card>
    </div>
  </div>
)

export default OverviewSidebar

import palettes from "./palette.json"

type Shade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

type Palette = Record<Shade, string>

interface KiPalette {
  light: string
  dark: string
}

export const ki: KiPalette = palettes.ki
export const ottoGreen: Palette = palettes.ottoGreen
export const ottoGrey: Palette = palettes.ottoGrey
export const ottoPop: Palette = palettes.ottoPop
export const ottoRed: Palette = palettes.ottoRed
export const ottoYellow: Palette = palettes.ottoYellow

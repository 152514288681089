import { PropsWithChildren, forwardRef } from "react"
import classNames from "classnames"

export interface CardProps {
  className?: string
  padding?: number
}

const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  ({ className = "", padding = 4, children }, ref) => (
    <div
      ref={ref}
      className={classNames(
        "rounded-md border border-otto-grey-400 bg-white text-base shadow-sm",
        className,
      )}
      style={{
        // TODO This is not ideal because it's duplicating the TW spacing scale;
        // it would be better to maintain one source of truth. But TW doesn't
        // support dynamically-generated classes.
        padding: `calc(${padding} * 0.25rem)`,
      }}
    >
      {children}
    </div>
  ),
)

export default Card

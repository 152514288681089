import { FC, PropsWithChildren, Suspense } from "react"
import {
  LoaderFunctionArgs,
  Navigate,
  Params,
  RouteObject,
  createBrowserRouter,
} from "react-router-dom"
import { Router } from "@remix-run/router"

import * as Sentry from "@sentry/react"

import { ApiClient, getConfig, getExtractions } from "src/api"
import { orderTablesByScore } from "./common/utils"
import { DataPoints } from "./common/dataPoints"

import { SpinnerIcon } from "@appia/ui-components"

import type { ExtractionScreenLoader } from "./ExtractionScreen"
import ErrorScreen from "./ErrorScreen"
import ExtractionScreen from "./ExtractionScreen"
import SummaryScreen from "./SummaryScreen"
import UploadScreen from "./UploadScreen"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const SuspenseLoader: FC<PropsWithChildren> = ({ children }) => (
  <Suspense
    fallback={<SpinnerIcon label="Loading" className="mx-auto mt-8 w-8" />}
  >
    {children}
  </Suspense>
)

export const routes = (apiClient: ApiClient): RouteObject[] => [
  {
    path: "",
    element: (
      <Navigate to={`/upload` + window.location.search} replace={true} />
    ),
  },
  {
    path: "upload",
    element: (
      <SuspenseLoader>
        <UploadScreen />
      </SuspenseLoader>
    ),
  },
  {
    id: "withSov",
    path: ":sovId",
    loader: async ({ params }: LoaderFunctionArgs): Promise<DataPoints> => {
      const { sovId }: Params<"sovId"> = params
      const dataPoints = await getConfig(apiClient, sovId as string)
      return dataPoints.data.datapoints
    },
    errorElement: (
      <ErrorScreen message="Failed to load data point configuration" />
    ),
    children: [
      {
        path: "extraction",
        element: (
          <SuspenseLoader>
            <ExtractionScreen />
          </SuspenseLoader>
        ),
        loader: async ({
          params,
        }: LoaderFunctionArgs): Promise<ExtractionScreenLoader> => {
          const { sovId }: Params<"sovId"> = params
          const extractions = await getExtractions(apiClient, sovId as string)
          return {
            tables: orderTablesByScore(extractions.data.tables),
            sheets: extractions.data.sheets,
          }
        },
        errorElement: (
          <ErrorScreen message="Unable to load extracted information from your document" />
        ),
      },
      {
        path: "summary",
        element: (
          <SuspenseLoader>
            <SummaryScreen />
          </SuspenseLoader>
        ),
      },
      {
        path: "manual",
        element: (
          <SuspenseLoader>
            <SummaryScreen />
          </SuspenseLoader>
        ),
      },
    ],
  },
]

const appRouter = (apiClient: ApiClient): Router =>
  sentryCreateBrowserRouter(routes(apiClient))

export default appRouter

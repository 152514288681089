export const init = (): void => {
  const siteId = 2998346
  const cookiePolicyId = 73348839

  const _iub = window._iub || []
  _iub.csConfiguration = {
    cookiePolicyInOtherWindow: true,
    enableRemoteConsent: true,
    lang: "en",
    logLevel: "warn",
    perPurposeConsent: true,
    purposes: "1,4",
    siteId,
    whitelabel: false,
    cookiePolicyId,
    floatingPreferencesButtonCaption: true,
    floatingPreferencesButtonCaptionColor: "#FFFFFF",
    floatingPreferencesButtonColor: "#030F21",
    floatingPreferencesButtonDisplay: "anchored-center-right",
    floatingPreferencesButtonIcon: false,
    banner: {
      acceptButtonColor: "#030F21",
      acceptButtonDisplay: true,
      backgroundColor: "#FFFFFF",
      backgroundOverlay: true,
      closeButtonDisplay: false,
      customizeButtonColor: "#FFFFFF",
      customizeButtonCaptionColor: "#030F21",
      customizeButtonDisplay: true,
      linksColor: "#030F21",
      position: "float-top-center",
      rejectButtonColor: "#030F21",
      rejectButtonDisplay: true,
      textColor: "#030F21",
      showPurposesToggles: true,
      content:
        '<div id="iubenda-cs-title">Notice</div>\n<div id="iubenda-cs-paragraph">\nWe and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified in the %{cookie_policy_link}.\nDenying consent may make related features unavailable.\n<br />\nUse the “Accept all” button to consent.\nUse the “Reject all” button to continue without accepting.\n</div>',
    },
  }
  window._iub = _iub

  const script = document.createElement("script")
  script.src = "https://cdn.iubenda.com/cs/iubenda_cs.js"
  script.async = true
  script.type = "text/javascript"
  document.body.appendChild(script)
}

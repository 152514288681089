import { FC, ReactElement, ReactNode } from "react"
import { Link as RouterLink } from "react-router-dom"

import { SpinnerIcon } from "./Icons"
import classNames from "classnames"

export type CTATheme = "pop" | "night" | "destructive"

export type CTASize = "large" | "regular" | "small"

export type CTAStyle = "filled" | "outlined" | "text"

export type CTAStretch = "space-around" | "space-between" | "none"

interface IconLayout {
  position: "left" | "right"
  icon: ReactElement
}

interface BaseProps {
  label: string
  style: CTAStyle
  theme: CTATheme

  className?: string
  icon?: IconLayout
  size?: CTASize
  stretch?: CTAStretch
}

export interface ButtonProps extends BaseProps {
  id?: string
  disabled?: boolean
  form?: string
  isLoading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button: FC<ButtonProps> = ({
  id,
  className,
  disabled = false,
  form,
  icon,
  isLoading = false,
  label,
  onClick,
  size = "regular",
  stretch = "none",
  style,
  theme,
  ...props
}) => (
  <button
    {...props}
    id={id}
    type={onClick ? "button" : "submit"}
    className={mkClassNames(
      theme,
      size,
      stretch,
      style,
      disabled,
      isLoading,
      icon,
      className,
    )}
    disabled={disabled || isLoading}
    aria-disabled={disabled}
    onClick={onClick}
    form={form}
  >
    {isLoading ? (
      <>
        <IconWrapper>
          <SpinnerIcon />
        </IconWrapper>

        <span>Loading...</span>
      </>
    ) : (
      <CTAInner label={label} icon={icon} />
    )}
  </button>
)

export interface LinkProps extends BaseProps {
  href: string
  download?: boolean
  target?: "_self" | "_blank" | "_parent" | "_top"
  // Necessary for analytics tracking
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Link: FC<LinkProps> = ({
  className,
  download,
  href,
  icon,
  label,
  onClick,
  size = "regular",
  stretch = "none",
  style,
  target,
  theme,
  ...props
}) => (
  <RouterLink
    {...props}
    to={href}
    target={target}
    download={download}
    className={mkClassNames(
      theme,
      size,
      stretch,
      style,
      false,
      false,
      icon,
      className,
    )}
    onClick={onClick}
  >
    <CTAInner label={label} icon={icon} />
  </RouterLink>
)

const CTAInner: FC<{ label: string; icon?: IconLayout }> = ({
  label,
  icon,
}) => (
  <>
    {icon ? (
      <>
        {icon.position === "left" && <IconWrapper>{icon.icon}</IconWrapper>}
        {label}
        {icon.position === "right" && <IconWrapper>{icon.icon}</IconWrapper>}
      </>
    ) : (
      label
    )}
  </>
)

const IconWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <span className="w-6 flex-shrink-0">{children}</span>
)

const mkClassNames = (
  theme: CTATheme,
  size: CTASize,
  stretch: CTAStretch,
  style: CTAStyle,
  disabled: boolean,
  loading: boolean,
  icon?: IconLayout,
  className?: string,
): string =>
  classNames(
    "w-full whitespace-nowrap inline-flex items-center gap-2 rounded-md shadow-sm border text-lg px-4 otto-focus",
    {
      // Pop filled
      "bg-otto-pop border-otto-pop font-bold text-primary hover:brightness-[0.9] active:brightness-[0.6]":
        theme === "pop" && style === "filled" && !(disabled || loading),
      // Pop outlined
      "bg-transparent border-otto-pop font-normal text-otto-pop hover:bg-otto-pop hover:text-primary active:brightness-[0.6] active:text-primary":
        theme === "pop" && style === "outlined" && !(disabled || loading),
      // Night filled
      "bg-otto-night border-otto-night font-normal text-otto-day otto-focus-night hover:opacity-90 active:opacity-40":
        theme === "night" && style === "filled" && !(disabled || loading),
      // Night outlined
      "bg-transparent border-otto-night font-normal text-otto-night otto-focus-night hover:bg-otto-night hover:text-otto-day active:bg-otto-night active:text-otto-day active:opacity-40":
        theme === "night" && style === "outlined" && !(disabled || loading),
      // Destructive (filled; there is no outlined version)
      "bg-otto-red-700 border-otto-red-700 font-normal text-otto-day otto-focus-red hover:brightness-[0.9] active:bg-otto-red-800":
        theme === "destructive" && !(disabled || loading),
      // General text
      "bg-transparent shadow-transparent border-none hover:bg-black hover:bg-opacity-10 active:bg-otto-grey-900 active:bg-opacity-25":
        style === "text",
      // Pop text
      "text-otto-pop hover:text-otto-pop":
        theme === "pop" && style === "text" && !(disabled || loading),
      // Night text without icon
      "underline underline-offset-2":
        theme === "night" &&
        style === "text" &&
        !(disabled || loading) &&
        !icon,
      // Night text
      "text-otto-night hover:text-otto-night":
        theme === "night" && style === "text" && !(disabled || loading),
      // Common sizing
      "h-12": size === "large",
      "h-11": size === "regular",
      "h-9": size === "small",
      // Common stretching
      "max-w-max justify-center": stretch === "none",
      "justify-center": stretch === "space-around",
      "justify-between": stretch === "space-between",
      // Common disabled / loading
      "bg-otto-mist text-otto-grey-700 border-none": disabled || loading,
      "cursor-not-allowed": disabled,
      "cursor-wait": loading,
      // Text disabled / loading
      "!bg-transparent !shadow-transparent !border-none":
        style === "text" && (disabled || loading),
    },
    className,
  )

/**
 * From TailwindUI: https://tailwindui.com/components/application-ui/overlays/modals#component-31555aec01de5027d6696629eb60f673
 */
import { FC, Fragment, ReactNode, RefObject } from "react"
import { Dialog, Transition } from "@headlessui/react"

import classNames from "classnames"

export const ModalOverlay: FC<{
  bgColor?: string
  children: ReactNode
  initialFocus?: RefObject<HTMLElement>
  isOpen: boolean
  onClose: () => void
}> = ({
  bgColor = "bg-otto-grey-600",
  children,
  initialFocus,
  isOpen,
  onClose,
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[1000] overflow-y-auto"
      initialFocus={initialFocus}
      onClose={onClose}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={classNames(
              "fixed inset-0 bg-opacity-75 transition-opacity",
              bgColor,
            )}
          />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          {children}
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
)

export const Modal: FC<{
  allowOverflow?: boolean
  bgColor?: string
  children: ReactNode
  className?: string
  initialFocus?: RefObject<HTMLElement>
  isOpen: boolean
  onClose: () => void
}> = ({
  allowOverflow = false,
  bgColor,
  children,
  className,
  initialFocus,
  isOpen,
  onClose,
}) => (
  <ModalOverlay
    bgColor={bgColor}
    initialFocus={initialFocus}
    isOpen={isOpen}
    onClose={onClose}
  >
    <div
      className={classNames(
        "inline-block transform rounded-lg bg-white text-left align-bottom text-base shadow-xl transition-all",
        { "overflow-hidden": !allowOverflow },
        "sm:my-8 sm:w-full sm:max-w-sm sm:align-middle",
        "lg:w-auto lg:min-w-[32rem] lg:max-w-2xl",
        "forced-colors:border",
        className,
      )}
    >
      {children}
    </div>
  </ModalOverlay>
)

export const ModalTitle = Dialog.Title

export const ModalDescription = Dialog.Description

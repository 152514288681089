import { FC, useMemo, useRef, useState } from "react"
import {
  useLocation,
  useMatch,
  useParams,
  useRouteLoaderData,
} from "react-router-dom"

import {
  FinalisedAsset,
  completeFlow,
  finaliseSOVData,
  finaliseSOVStubData,
} from "src/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import { CellStatus, EditingView, SOVAssets } from "./types"
import { SheetWithContents, Table } from "src/common/types"
import { DataPoint, DataPoints } from "src/common/dataPoints"

import useUndoRedoState from "./undoRedo"

import {
  diffAssets,
  mkCellStatuses,
  mkEmptyAssets,
  prettyPrintAssetDiff,
  sheetsToAssets,
} from "./assets"
import { calculateTotalsPerCurrency } from "./money"

import { Button, Callout, Toast } from "@appia/ui-components"

import { AgGridReact } from "@ag-grid-community/react"

import PageWrapper from "src/components/PageWrapper"
import PageLayout from "src/components/PageLayout"
import ModalTemplate from "src/components/ModalTemplate"

import Sidebar from "./Sidebar"
import Spreadsheet from "./Spreadsheet"
import { RowData } from "./Spreadsheet/columns"
import SpreadsheetToolbar from "./SpreadsheetToolbar"

import useApiClient from "src/contexts/ApiClientContext"
import useConfig from "src/contexts/ConfigContext"

import { trackHeapEvent } from "src/heap"
import { parseValue } from "src/common/validation"

interface SummaryTransitionState {
  sheets: SheetWithContents[]
  tables: Table[]
  populateBlankAssets: boolean
}

const SummaryScreen: FC = () => {
  const { sovId } = useParams<{ sovId: string }>()
  const { state } = useLocation()
  const {
    sheets = [],
    tables = [],
    populateBlankAssets = false,
  } = state as SummaryTransitionState

  if (sovId === undefined) {
    throw new Error("Unknown SOV")
  }

  const isManualJourney = useMatch("/:sovId/manual") !== null

  const apiClient = useApiClient()
  const config = useConfig()
  const dataPoints = useRouteLoaderData("withSov") as DataPoints

  const [assets, setAssets] = useUndoRedoState<SOVAssets>(() =>
    populateBlankAssets
      ? mkEmptyAssets(dataPoints, 1)
      : sheetsToAssets(dataPoints, sheets, tables),
  )

  const cellStatuses: CellStatus[] = useMemo(
    () => mkCellStatuses(dataPoints, assets),
    [dataPoints, assets],
  )

  const totalsPerCurrency = useMemo(
    () => calculateTotalsPerCurrency(dataPoints, assets),
    [dataPoints, assets],
  )

  const [editingView, setEditingView] = useState<EditingView>({
    mode: "default",
  })

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isApiErrorModalOpen, setIsApiErrorModalOpen] = useState<boolean>(false)
  const [completionReq, setCompletionReq] = useState<
    RD.RemoteData<Error, unknown>
  >(RD.NotAsked)

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<string>("")
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: string,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  const gridRef = useRef<AgGridReact<RowData>>(null)

  const scrollToColumn = (field: DataPoint["field"]): void => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.ensureColumnVisible(field, "middle")
    }
  }

  return (
    <PageWrapper>
      <PageLayout
        pageHeading="Review and submit SOV data"
        sidebarSectionHeading="Data summaries and validation"
        sidebar={
          <Sidebar
            assets={assets}
            cellStatuses={cellStatuses}
            dataPoints={dataPoints}
            editingView={editingView}
            onBulkUpdate={f => {
              const updatedAssets = f(assets)
              setAssets(updatedAssets)

              const diffMessage = prettyPrintAssetDiff(
                diffAssets(assets, updatedAssets),
              )

              setAndTriggerToast("success", diffMessage)
            }}
            onContinue={async () => {
              const assetList = assets.reduce<FinalisedAsset[][]>(
                (acc, asset) => {
                  const finalisedAssets: FinalisedAsset[] = dataPoints
                    .map(dp => {
                      // We have an edge case where you can paste in a valid value e.g $120000000 however
                      // the value is never parsed as pasting skips that, so we ran a final parse to ensure we catch that
                      // We should never been in a case where there is invalid data at this point
                      const parseValueResult = parseValue(dp, asset[dp.field])
                      const parsedAssets =
                        parseValueResult.status === "valid"
                          ? parseValueResult.parsedValue
                          : null

                      return {
                        field: dp.field,
                        type: dp.type,
                        value: parsedAssets,
                      }
                    })
                    // Remove all null/undefined/empty answers for send
                    .filter(a => a.value != null)

                  return [...acc, finalisedAssets]
                },
                [],
              )
              try {
                await (isManualJourney
                  ? finaliseSOVStubData(apiClient, sovId, assetList)
                  : finaliseSOVData(apiClient, sovId, assetList))
                setModalOpen(true)
              } catch (e) {
                setIsApiErrorModalOpen(true)
                Sentry.captureException(e)
              }
            }}
            scrollToColumn={scrollToColumn}
            setEditingView={setEditingView}
            totalsPerCurrency={totalsPerCurrency}
          />
        }
        toolbar={<SpreadsheetToolbar editingView={editingView} />}
        spreadsheetSectionHeading="Extracted data"
        spreadsheet={
          <Spreadsheet
            apiClient={apiClient}
            assets={assets}
            cellStatuses={cellStatuses}
            dataPoints={dataPoints}
            editingView={editingView}
            gridRef={gridRef}
            setAssets={setAssets}
          />
        }
      />

      <ModalTemplate
        actions={[
          <Button
            key="continue"
            style="filled"
            label="Continue"
            theme="night"
            disabled={RD.isFailure(completionReq)}
            isLoading={RD.isLoading(completionReq)}
            onClick={async () => {
              try {
                setCompletionReq(RD.Loading)
                await completeFlow(apiClient, sovId)
                setCompletionReq(RD.Success(null))

                trackHeapEvent({
                  type: "JourneyCompleted",
                  sovId,
                  isManualJourney,
                })

                if (config.redirectUri) {
                  sessionStorage.removeItem("sov_token")
                  window.location.replace(config.redirectUri)
                }
              } catch (e) {
                Sentry.captureException(e)

                if (e instanceof Error) {
                  setCompletionReq(RD.Failure(e))
                  setAndTriggerToast(
                    "error",
                    "Unable to complete your journey. Please try again.",
                  )
                }
              }
            }}
          />,
        ]}
        content={<Callout type="success">SOV upload complete</Callout>}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="SOV complete"
      />

      <ModalTemplate
        actions={[
          <Button
            key="error-modal"
            style="filled"
            label="Close"
            theme="night"
            disabled={RD.isFailure(completionReq)}
            isLoading={RD.isLoading(completionReq)}
            onClick={() => setIsApiErrorModalOpen(false)}
          />,
        ]}
        content={
          <Callout type="error">
            There was an error with your Schedule of Values.
            <br />
            Please reach out to the team in chat for support.
          </Callout>
        }
        isOpen={isApiErrorModalOpen}
        onClose={() => setIsApiErrorModalOpen(false)}
        title="Unknown Error"
      />

      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />
    </PageWrapper>
  )
}

export default SummaryScreen

import { ROW_INDEX_COLUMN_FIELD } from "src/common/agGrid"

import { indexToColumn } from "src/common/utils"

export type RowData = Record<string, unknown> &
  Record<typeof ROW_INDEX_COLUMN_FIELD, number>

export const makeRowData = (data: unknown[][]): RowData[] =>
  data.reduce<RowData[]>((acc, rowValues, rowIdx) => {
    const row = rowValues.reduce<RowData>(
      (rowAcc, cellValue, i) => ({
        ...rowAcc,
        [indexToColumn(i)]: cellValue,
      }),
      { [ROW_INDEX_COLUMN_FIELD]: rowIdx },
    )
    return [...acc, row]
  }, [])

import { DataPoint, DataPoints } from "src/common/dataPoints"
import {
  CellMapping,
  Sheet,
  SheetCoords,
  SheetWithContents,
  Table,
} from "src/common/types"
import { SheetStatus, TableBounds } from "./types"

import { columnToIndex } from "src/common/utils"

/**
 * Count the number of rows / columns (depending on orientation) covered by
 * these tables
 */
export const countExtractedRowsOrColumns = (tables: Table[]): number =>
  tables
    .filter(t => t.confirmed)
    .reduce((acc, t) => {
      const numRowsOrColumns =
        t.orientation === "horizontal"
          ? columnToIndex(t.bottom_right.column) -
            columnToIndex(t.top_left.column)
          : t.bottom_right.row - t.top_left.row

      return acc + numRowsOrColumns
    }, 0)

/**
 * Build up a mapping from row numbers, to column numbers, to CSS classes, for
 * all the given tables
 */

export type CellClassNameMapping = CellMapping<string[]>

interface TableBoundsClassNames {
  top: string[]
  bottom: string[]
  left: string[]
  right: string[]
}

interface MinMaxIndices {
  minCol: number
  maxCol: number
  minRow: number
  maxRow: number
}

export const makeCellClassNames = (
  tables: Table[],
  tablePreview: TableBounds | null,
  activeTableId: Table["id"] | null,
  dataPoints: DataPoints,
  activeDataPointId: DataPoint["field"] | null,
): CellClassNameMapping => {
  const mapping: CellClassNameMapping = {}

  const addClassName = (
    rowIdx: number,
    colIdx: number,
    classNames: string[],
  ): void => {
    const rowMapping = mapping[rowIdx] ?? {}
    const colMapping = rowMapping[colIdx] ?? []
    rowMapping[colIdx] = [...colMapping, ...classNames]
    mapping[rowIdx] = rowMapping
  }

  const addTableBoundsClassNames = (
    { minCol, maxCol, minRow, maxRow }: MinMaxIndices,
    classNames: TableBoundsClassNames,
  ): void => {
    for (let col = minCol; col <= maxCol; col++) {
      addClassName(minRow, col, ["otto-border-top", ...classNames.top])
      addClassName(maxRow, col, ["otto-border-bottom", ...classNames.bottom])
    }

    for (let row = minRow; row <= maxRow; row++) {
      addClassName(row, minCol, ["otto-border-left", ...classNames.left])
      addClassName(row, maxCol, ["otto-border-right", ...classNames.right])
    }
  }

  const toTableBoundsClassNames = (
    side: string,
    isActiveTable: boolean,
    isConfirmedTable: boolean,
  ): string[] => {
    const className = isActiveTable ? [`otto-border-${side}-active`] : []

    isConfirmedTable && className.push("otto-border-confirmed-table")

    return className
  }

  for (const table of tables) {
    const isActiveTable = table.id === activeTableId
    const isConfirmedTable = table.confirmed

    const { row: minRow1Indexed, column: minColLetter } = table.top_left
    const { row: maxRow1Indexed, column: maxColLetter } = table.bottom_right

    // We need 0-indexed rows for the highlighting, but `table` contains
    // Excel-style 1-indexed rows
    const minRow = minRow1Indexed - 1
    const maxRow = maxRow1Indexed - 1

    const minCol = columnToIndex(minColLetter)
    const maxCol = columnToIndex(maxColLetter)

    const offset = table.orientation === "horizontal" ? minRow : minCol

    // Highlights
    dataPoints.forEach(({ field }) => {
      const className =
        field === activeDataPointId
          ? ["otto-cell-highlight", "otto-cell-highlight-active"]
          : ["otto-cell-highlight"]

      isConfirmedTable && className.push("otto-cell-confirmed-table")

      const indices = table.extracted[field]?.map(idx => idx + offset) ?? []
      if (isActiveTable) {
        if (table.orientation === "vertical") {
          for (const col of indices) {
            for (let row = minRow; row <= maxRow; row++) {
              addClassName(row, col, className)
            }
          }
        } else {
          for (const row of indices) {
            for (let col = minCol; col <= maxCol; col++) {
              addClassName(row, col, className)
            }
          }
        }
      }
    })

    // Table bounds
    addTableBoundsClassNames(
      { minCol, maxCol, minRow, maxRow },
      {
        top: toTableBoundsClassNames("top", isActiveTable, isConfirmedTable),
        bottom: toTableBoundsClassNames(
          "bottom",
          isActiveTable,
          isConfirmedTable,
        ),
        left: toTableBoundsClassNames("left", isActiveTable, isConfirmedTable),
        right: toTableBoundsClassNames(
          "right",
          isActiveTable,
          isConfirmedTable,
        ),
      },
    )
  }

  if (tablePreview) {
    const minCol = columnToIndex(tablePreview.top_left.column)
    const minRow = tablePreview.top_left.row - 1
    const maxCol = columnToIndex(tablePreview.bottom_right.column)
    const maxRow = tablePreview.bottom_right.row - 1

    addTableBoundsClassNames(
      { minCol, maxCol, minRow, maxRow },
      {
        top: ["otto-border-top-preview"],
        bottom: ["otto-border-bottom-preview"],
        left: ["otto-border-left-preview"],
        right: ["otto-border-right-preview"],
      },
    )
  }

  return mapping
}

/**
 * Are the given cell coordinates within the given table?
 *
 * Note that the row and column numbers should be 0-indexed and relative to the
 * whole grid, not relative to the table
 */
export const cellWithinActiveTableHeader = (
  activeTable: Table | null,
  row: number,
  col: number,
): boolean => {
  if (!activeTable) {
    return false
  }

  const { orientation, top_left, bottom_right } = activeTable

  switch (orientation) {
    case "vertical":
    case "unknown": {
      return (
        row === top_left.row - 1 &&
        col >= columnToIndex(top_left.column) &&
        col <= columnToIndex(bottom_right.column)
      )
    }
    case "horizontal": {
      return (
        col === columnToIndex(top_left.column) &&
        row >= top_left.row - 1 &&
        row <= bottom_right.row - 1
      )
    }
  }
}

/**
 * Is the given row number within the table?
 *
 * Note that the row number should be 0-indexed
 */
export const rowWithinActiveTable = (
  activeTable: Table | null,
  rowIdx: number,
): boolean => {
  if (!activeTable) {
    return false
  }

  const { top_left, bottom_right } = activeTable

  return rowIdx >= top_left.row - 1 && rowIdx <= bottom_right.row - 1
}

/**
 * Does the given row number refer to the table header row?
 *
 * Note that the row number should be 0-indexed
 */
export const rowIsActiveTableHeader = (
  activeTable: Table | null,
  rowIdx: number,
): boolean => {
  if (!activeTable) {
    return false
  }

  const { orientation, top_left } = activeTable

  return orientation !== "horizontal" && rowIdx === top_left.row - 1
}

/**
 * Extract the raw data for the table's header row / column
 */
export const getTableHeader = (s: SheetWithContents, t: Table): unknown[] => {
  const { top_left, bottom_right, orientation } = t

  switch (orientation) {
    case "vertical":
    case "unknown": {
      // Take a slice of the first row
      const firstRow = s.contents[top_left.row - 1] ?? []

      const startCol = columnToIndex(top_left.column)
      const endCol = columnToIndex(bottom_right.column)

      return firstRow.slice(startCol, endCol + 1)
    }
    case "horizontal": {
      // Slice vertically, across rows
      const startRow = top_left.row - 1
      const endRow = bottom_right.row - 1

      const firstColIdx = columnToIndex(top_left.column)

      return s.contents.reduce<unknown[]>((acc, row, i) => {
        if (i < startRow || i > endRow) {
          return acc
        } else {
          return [...acc, row[firstColIdx]]
        }
      }, [])
    }
  }
}

/**
 * Determine whether the header row / column is different between two tables
 */
export const tableHeaderHasChanged = (
  oldTable: Table,
  newTable: Table,
): boolean => {
  if (oldTable.orientation !== newTable.orientation) {
    return true
  }

  const leftBoundChanged = oldTable.top_left.column !== newTable.top_left.column

  const rightBoundChanged =
    oldTable.bottom_right.column !== newTable.bottom_right.column

  const topRowChanged = oldTable.top_left.row !== newTable.top_left.row

  const bottomRowChanged =
    oldTable.bottom_right.row !== newTable.bottom_right.row

  switch (newTable.orientation) {
    case "vertical":
    case "unknown": {
      return leftBoundChanged || rightBoundChanged || topRowChanged
    }
    case "horizontal": {
      return leftBoundChanged || topRowChanged || bottomRowChanged
    }
  }
}

/**
 * When a table's bounds change we need to adjust the indices of its `extracted`
 * field to make sure that:
 *
 * A) The mapped rows / columns don't appear to move. For example if there is a
 * header row and the table bounds moved two columns to the left, we need to
 * increase all the mapped indices by two to compensate; otherwise they would
 * move along with the table
 *
 * B) The mapped rows / columns still fall within the table's new bounds, since
 * the table may now be smaller than it was before
 */
export const adjustExtractedIndices = (
  dataPoints: DataPoints,
  oldTopLeft: SheetCoords,
  table: Table,
): Table["extracted"] => {
  const {
    top_left: newTopLeft,
    bottom_right: newBottomRight,
    orientation,
    extracted,
  } = table

  let offset: number
  let tableWidthOrHeight: number
  switch (orientation) {
    case "vertical":
    case "unknown":
      offset =
        columnToIndex(newTopLeft.column) - columnToIndex(oldTopLeft.column)
      tableWidthOrHeight =
        columnToIndex(newBottomRight.column) - columnToIndex(newTopLeft.column)
      break
    case "horizontal":
      offset = newTopLeft.row - oldTopLeft.row
      tableWidthOrHeight = newBottomRight.row - newTopLeft.row
      break
  }

  return dataPoints.reduce<Table["extracted"]>((acc, dp) => {
    const indices = extracted[dp.field] ?? []
    return {
      ...acc,
      [dp.field]: indices
        .map(i => i - offset)
        .filter(i => i >= 0 && i <= tableWidthOrHeight),
    }
  }, {})
}

/**
 * Create a new table, sans ID
 */
export const mkEmptyTable = ({
  sheet_id,
  bottom_right,
  top_left,
}: {
  sheet_id: Sheet["id"]
  bottom_right: SheetCoords
  top_left: SheetCoords
}): Omit<Table, "id"> => {
  return {
    bottom_right,
    extracted: {},
    orientation: "vertical",
    score: null,
    sheet_id,
    top_left,
    confirmed: false,
    currency: null,
  }
}

/**
 * Returns one of three potential statuses
 * (confirmed, incomplete or empty)
 * depending on the sheet's status
 */
export const toSheetStatus = (
  sheetFullyConfirmed: boolean,
  sheetHasNoTables: boolean,
): SheetStatus => {
  if (sheetHasNoTables) return "empty"

  return sheetFullyConfirmed ? "confirmed" : "incomplete"
}

import { Switch } from "@headlessui/react"
import classNames from "classnames"
import { FC, memo } from "react"

export interface ToggleProps {
  title: string
  checked: boolean
  onChange: (value: boolean) => void
}

const Toggle: FC<ToggleProps> = ({ title, checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={classNames(
        "otto-focus relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent motion-safe:transition-colors motion-safe:duration-200 motion-safe:ease-in-out",
        {
          "bg-otto-pop forced-colors:bg-SystemHighlight": checked,
          "bg-otto-grey-400": !checked,
        },
      )}
    >
      <span className="sr-only">{title}</span>
      <span
        className={classNames(
          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 motion-safe:transition motion-safe:duration-200 motion-safe:ease-in-out",
          {
            "translate-x-5": checked,
            "translate-x-0": !checked,
          },
        )}
      >
        <span
          className={classNames(
            "absolute inset-0 flex h-full w-full items-center justify-center motion-safe:transition-opacity",
            {
              "opacity-0 motion-safe:duration-100 motion-safe:ease-out":
                checked,
              "opacity-100 motion-safe:duration-200 motion-safe:ease-in":
                !checked,
            },
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-otto-grey-500"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            "absolute inset-0 flex h-full w-full items-center justify-center motion-safe:transition-opacity",
            {
              "opacity-100 motion-safe:duration-200 motion-safe:ease-in":
                checked,
              "opacity-0 motion-safe:duration-100 motion-safe:ease-out":
                !checked,
            },
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-otto-pop"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  )
}

export default memo(Toggle)

import { v4 as uuidv4 } from "uuid"

import { DataPoints } from "src/common/dataPoints"

import { SheetWithContents, Table } from "src/common/types"
import {
  ASSET_UNIQUE_ID_FIELD,
  CellStatus,
  SOVAsset,
  SOVAssets,
  ValidationStatus,
} from "./types"

import { columnToIndex, printCount } from "src/common/utils"
import { parseValue } from "src/common/validation"

import { combineCells } from "./combineCells"

import { dequal } from "dequal"

export const mkEmptyAssets = (dataPoints: DataPoints, n: number): SOVAssets =>
  Array.from(new Array(n), () =>
    dataPoints.reduce<SOVAsset>((acc, dp) => ({ ...acc, [dp.field]: null }), {
      [ASSET_UNIQUE_ID_FIELD]: uuidv4(),
    }),
  )

export const tableToAssets = (
  dataPoints: DataPoints,
  sheetWithContents: SheetWithContents["contents"],
  table: Table,
): SOVAssets => {
  const { top_left, bottom_right, orientation, extracted } = table

  // Provided there is a table-level currency set, any currency data point
  // that hasn't been mapped to a row or column should be populated with the
  // table-level currency
  const dataPointsToFillWithTableCurrency =
    table.currency === null
      ? []
      : dataPoints.filter(
          dp =>
            dp.type === "currency" && (extracted[dp.field] ?? []).length === 0,
        )

  const createAsset = (
    offset: number,
    getCell: (idx: number) => unknown,
  ): SOVAsset | null => {
    let allValuesEmpty = true

    const asset = dataPoints.reduce<SOVAsset>(
      (acc, dp) => {
        const indices = extracted[dp.field]?.map(i => i + offset) ?? []

        const val = combineCells(indices.map(getCell), dp)

        allValuesEmpty =
          allValuesEmpty && parseValue(dp, val).status === "empty"

        return { ...acc, [dp.field]: val }
      },
      { [ASSET_UNIQUE_ID_FIELD]: uuidv4() },
    )

    // Don't include assets that are entirely empty
    if (allValuesEmpty) {
      return null
    }

    // Populate the table-level currency where relevant
    const assetWithFilledCurrencies = dataPointsToFillWithTableCurrency.reduce(
      (acc, dp) => ({ ...acc, [dp.field]: table.currency }),
      asset,
    )

    return assetWithFilledCurrencies
  }

  switch (orientation) {
    case "vertical":
    case "unknown": {
      const offset = columnToIndex(top_left.column)
      // Rows are 1-indexed but we deliberately don't subtract 1 here so that
      // the header row is excluded
      const startIdx = top_left.row
      const endIdx = bottom_right.row

      const assets: SOVAssets = []

      const rows = sheetWithContents.slice(startIdx, endIdx)
      rows.forEach(row => {
        const asset = createAsset(offset, colIdx => row[colIdx])
        if (asset) assets.push(asset)
      })

      return assets
    }
    case "horizontal": {
      const offset = top_left.row - 1
      // Columns are 0-indexed, so we add one here so that the header column is
      // excluded
      const startIdx = columnToIndex(top_left.column) + 1
      const endIdx = columnToIndex(bottom_right.column)

      const assets: SOVAssets = []

      const columns = sheetWithContents[0]
      columns.forEach((_col, colIdx) => {
        if (colIdx < startIdx || colIdx > endIdx) {
          return
        }

        const asset = createAsset(
          offset,
          rowIdx => sheetWithContents[rowIdx][colIdx],
        )
        if (asset) assets.push(asset)
      })

      return assets
    }
  }
}

export const sheetsToAssets = (
  dataPoints: DataPoints,
  sheets: SheetWithContents[],
  tables: Table[],
): SOVAssets =>
  sheets.flatMap(s =>
    tables
      .filter(t => t.sheet_id === s.id)
      .filter(t => t.confirmed)
      .flatMap(t => tableToAssets(dataPoints, s.contents, t)),
  )

export const mkCellStatuses = (
  dataPoints: DataPoints,
  assets: SOVAssets,
): CellStatus[] => {
  const statuses: CellStatus[] = []

  assets.forEach((asset, rowIdx) => {
    dataPoints.forEach((dp, colIdx) => {
      const parseResult = parseValue(dp, asset[dp.field])

      const status: ValidationStatus =
        parseResult.status === "invalid" ||
        parseResult.status === "unparseable" ||
        (parseResult.status === "empty" && dp.required)
          ? "invalid"
          : "valid"

      statuses.push({ dataPointId: dp.field, colIdx, rowIdx, status })
    })
  })

  return statuses
}

interface AssetsDiff {
  added: number
  modified: number
  removed: number
}

export const diffAssets = (
  oldAssets: SOVAssets,
  newAssets: SOVAssets,
): AssetsDiff => {
  const oldIds = new Set()
  const newIds = new Set()

  // Mapping from the `assetUniqueId` to the asset for faster lookups
  const oldAssetMapping: Partial<Record<string, SOVAsset>> = {}

  for (const oldAsset of oldAssets) {
    oldIds.add(oldAsset.assetUniqueId)
    oldAssetMapping[oldAsset.assetUniqueId] = oldAsset
  }

  let added = 0,
    modified = 0
  for (const newAsset of newAssets) {
    newIds.add(newAsset.assetUniqueId)

    if (!oldIds.has(newAsset.assetUniqueId)) {
      added += 1
    }

    const oldAsset = oldAssetMapping[newAsset.assetUniqueId]
    if (!(oldAsset === undefined || dequal(newAsset, oldAsset))) {
      modified += 1
    }
  }

  let removed = 0
  for (const oldId of oldIds) {
    if (!newIds.has(oldId)) {
      removed += 1
    }
  }

  return { added, modified, removed }
}

export const prettyPrintAssetDiff = (diff: AssetsDiff): string => {
  const { added, modified, removed } = diff

  const messages = []
  if (added > 0) {
    messages.push(`${printCount(added, "row")} added`)
  }

  if (modified > 0) {
    messages.push(`${printCount(modified, "row")} updated`)
  }

  if (removed > 0) {
    messages.push(`${printCount(removed, "row")} removed`)
  }

  if (messages.length === 0) {
    return "No rows were updated. Please update manually"
  }

  return (
    new Intl.ListFormat("en", {
      style: "long",
      type: "conjunction",
    }).format(messages) + " successfully"
  )
}

import { StrictMode, useEffect } from "react"
import { createRoot } from "react-dom/client"
import {
  RouteObject,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import axios from "axios"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { Replay } from "@sentry/replay"
import { LicenseManager } from "@ag-grid-enterprise/core"

import { Toast } from "@appia/ui-components"

import "./App.css"
import "./index.css"

import "core-js/es/object/has-own" // Polyfill for Uppy, for Safari

import { ApiClient } from "./api"
import { ApiClientContext } from "./contexts/ApiClientContext"
import { TokenProvider } from "./contexts/TokenContext"
import ErrorBoundary from "./components/ErrorBoundary"
import Bootstrap from "./Bootstrap"
import appRouter from "./Routes"
import ErrorScreen from "./ErrorScreen"

// Load env vars onto the window object so we can access them at runtime. We
// do it this way so that we can build the JS bundle a single time and then
// reuse it across different environments.
const [, domain, tld] = document.location.hostname.split(".")
const protocol = document.location.protocol
const apiDomain = `${protocol}//api.${domain}.${tld}`
const envUrl = `${apiDomain}/env.js`

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

const renderSuccessfully = (): void => {
  window.HOST = apiDomain

  Sentry.init({
    enabled:
      window.SENTRY_ENVIRONMENT === "production" ||
      window.SENTRY_ENVIRONMENT === "staging",
    dsn: window.SOV_SENTRY_DSN || window.SENTRY_DSN,
    environment: window.SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          (routes, location) =>
            matchRoutes<RouteObject>(routes, location, undefined),
        ),
        tracingOrigins: [window.SENTRY_ORIGIN],
      }),
      new Replay({
        useCompression: true,
        maskAllText: false,
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      }),
    ],

    // Set sample rates to 1.0 to capture 100% of transactions for performance
    // monitoring.
    tracesSampleRate: window.SENTRY_RATE,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: window.SENTRY_RATE,
  })

  LicenseManager.setLicenseKey(window.AG_GRID_LICENSE)

  const apiClient: ApiClient = axios.create({
    baseURL: window.HOST,
    headers: {
      http_referrer: document.referrer || document.location.origin,
    },
    withCredentials: true,
  })

  root.render(
    <StrictMode>
      <ErrorBoundary>
        <ApiClientContext.Provider value={apiClient}>
          <Toast.Provider>
            <TokenProvider>
              <Bootstrap router={appRouter(apiClient)} />
            </TokenProvider>
          </Toast.Provider>
        </ApiClientContext.Provider>
      </ErrorBoundary>
    </StrictMode>,
  )
}

const renderUnsuccessfully = (): void =>
  root.render(<ErrorScreen message="Something went wrong, please try again." />)

fetch(envUrl)
  .then(res => {
    res.text().then(eval).then(renderSuccessfully)
  })
  .catch(() => renderUnsuccessfully())

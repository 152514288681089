import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react"
import { SpinnerIcon, Tabs, Toast } from "@appia/ui-components"
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom"

import { AgGridReact } from "@ag-grid-community/react"

import StepOneSidebar from "./StepOneSidebar"
import StepTwoSidebar from "./StepTwoSidebar"
import Spreadsheet from "./Spreadsheet"
import ExtractionModal from "./Modal"

import PageWrapper from "src/components/PageWrapper"
import PageLayout from "src/components/PageLayout"
import ErrorScreen from "src/ErrorScreen"

import { TableBounds } from "./types"
import { Sheet, SheetWithContents, Table } from "src/common/types"
import { getSheetWithContents } from "src/api"
import { DataPoint, DataPoints } from "src/common/dataPoints"

import * as Sentry from "@sentry/react"
import useApiClient from "src/contexts/ApiClientContext"

import {
  RunAsyncActionWithToast,
  UpdateTable,
  onAddTable,
  onChangeTableCurrency,
  onDeleteTable,
  onGoToDataPoint,
  onSaveTables,
  onSetTableBounds,
  scrollToCell,
} from "./handlers"

const SpreadsheetWithTabs: FC<{
  activeDataPointId: DataPoint["field"] | null
  activeSheet: Sheet
  activeTableId: Table["id"] | null
  dataPoints: DataPoints
  gridRef: RefObject<AgGridReact>
  onExtract: (tableId: Table["id"], extracted: Table["extracted"]) => void
  setActiveSheetIdx: (i: number) => void
  sheetTables: Table[]
  sheets: Sheet[]
  sheetWithContentList: SheetWithContents[]
  tablePreview: TableBounds | null
  populateSheetContentsAndSelect: (sheet: Sheet, selectSheet: boolean) => void
  setActiveTableId: (tableId: Table["id"] | null) => void
  allTables: Table[]
}> = ({
  activeDataPointId,
  activeSheet,
  activeTableId,
  dataPoints,
  gridRef,
  onExtract,
  setActiveSheetIdx,
  sheetTables,
  sheets,
  sheetWithContentList,
  tablePreview,
  populateSheetContentsAndSelect,
  setActiveTableId,
  allTables,
}) => {
  const activeSheetContent = sheetWithContentList.find(
    s => s.name === activeSheet.name,
  )
  return (
    <Tabs.Root
      value={activeSheet.name}
      onValueChange={(name: string) => {
        const newActiveSheet = sheets.find(s => s.name === name)
        if (newActiveSheet) {
          setActiveSheetIdx(sheets.indexOf(newActiveSheet))
          const newActiveTableId =
            allTables.find(t => t.sheet_id === newActiveSheet.id)?.id ?? null
          setActiveTableId(newActiveTableId)
          populateSheetContentsAndSelect(newActiveSheet, true)
        }
      }}
    >
      {sheets.map(({ name }, idx) => (
        <Tabs.Content
          key={idx}
          value={name}
          id="sov-extraction-view"
          className="absolute top-0 bottom-8 left-0 right-0"
        >
          {activeSheetContent ? (
            <Spreadsheet
              activeDataPointId={activeDataPointId}
              activeTableId={activeTableId}
              data={activeSheetContent.contents}
              dataPoints={dataPoints}
              gridRef={gridRef}
              onExtract={onExtract}
              tablePreview={tablePreview}
              tables={sheetTables}
            />
          ) : (
            <SpinnerIcon label="Loading" className="mx-auto mt-8 w-8" />
          )}
        </Tabs.Content>
      ))}

      <div className="absolute bottom-0 left-0 right-0 h-8 overflow-hidden rounded-b-md border-t border-t-otto-grey-400">
        <Tabs.List
          className="h-full max-w-min divide-x divide-otto-grey-300"
          aria-label="Workbook sheet"
        >
          {sheets.map(({ name }, idx) => (
            <Tabs.Trigger key={idx} value={name} className="px-4 text-sm">
              {name}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </div>
    </Tabs.Root>
  )
}

interface ExtractionTransitionState {
  fileName: string
}

export interface ExtractionScreenLoader {
  tables: Table[]
  sheets: Sheet[]
}

const ExtractionScreen: FC = () => {
  const { sovId } = useParams<{ sovId: string }>()
  const { state } = useLocation()
  const { fileName = "" } = state as ExtractionTransitionState

  if (sovId === undefined) {
    throw new Error("Unknown SOV")
  }

  const apiClient = useApiClient()
  const dataPoints = useRouteLoaderData("withSov") as DataPoints
  const { tables: initialTables, sheets } =
    useLoaderData() as ExtractionScreenLoader
  const navigate = useNavigate()

  const [tables, setTables] = useState<Table[]>(initialTables)

  const updateTable = useCallback<UpdateTable>(
    (tableId, partialTable) => {
      setTables(ts =>
        ts.map(table =>
          table.id === tableId ? { ...table, ...partialTable } : table,
        ),
      )
    },
    [setTables],
  )

  const [activeSheetIdx, setActiveSheetIdx] = useState<number>(0)
  const activeSheet = sheets[activeSheetIdx]

  const [selectedSheetWithContent, setSelectedSheetWithContent] =
    useState<SheetWithContents | null>(null)

  const [sheetWithContentList, setSheetWithContentList] = useState<
    SheetWithContents[]
  >([])

  const sheetTables = tables.filter(t => t.sheet_id === activeSheet.id)

  const [activeTableId, setActiveTableId] = useState<Table["id"] | null>(
    sheetTables[0]?.id ?? null,
  )

  const [tablePreview, setTablePreview] = useState<TableBounds | null>(null)

  const gridRef = useRef<AgGridReact>(null)

  const [activeDataPointId, setActiveDataPointId] = useState<
    DataPoint["field"] | null
  >(null)

  const [toastParams, setToastParams] = useState<{
    message: string
    type: Toast.ToastType
  }>({ message: "", type: "success" })

  const {
    open: toastOpen,
    onOpenChange: onToastOpenChange,
    triggerToast,
  } = Toast.useToastState()

  const runAsyncActionWithToast = useCallback<RunAsyncActionWithToast>(
    async (action, successMsg, errorMsg, callbacks) => {
      try {
        await action()
        setToastParams({ type: "success", message: successMsg })
        triggerToast()
        if (callbacks?.onSuccess) callbacks.onSuccess()
      } catch (e) {
        Sentry.captureException(e)
        setToastParams({ type: "error", message: errorMsg })
        triggerToast()
        if (callbacks?.onError) callbacks.onError()
      } finally {
        if (callbacks?.onFinally) callbacks.onFinally()
      }
    },
    [setToastParams, triggerToast],
  )

  const [isExtractionModalOpen, setExtractionModalOpen] =
    useState<boolean>(false)

  const fillMissingSheetContents = async (): Promise<SheetWithContents[]> => {
    let allSheets = sheetWithContentList
    const sheetIds = sheets.map(sh => sh.id)
    const sheetWithContentIds = sheetWithContentList.map(shw => shw.id)

    const missingSheetIds = sheetIds.filter(
      x => !sheetWithContentIds.includes(x),
    )

    for (const missingId of missingSheetIds) {
      try {
        const { data: _sheetWithContent } = await getSheetWithContents(
          apiClient,
          sovId,
          missingId,
        )
        allSheets = [...allSheets, _sheetWithContent]
      } catch (e) {
        Sentry.captureException(e)

        if (e instanceof Error) {
          setToastParams({
            type: "error",
            message: `Could not load content for all sheets. Please try again.`,
          })
          triggerToast()
        }
      }
    }
    return allSheets
  }

  const populateSheetContentsAndSelect = useCallback(
    async (sheet: Sheet, selectSheet = false): Promise<void> => {
      const existingSheetWithContents = sheetWithContentList.find(
        s => s.id === sheet.id,
      )

      // If we already have the sheet contents, just set it as selected
      if (existingSheetWithContents) {
        if (selectSheet) {
          setSelectedSheetWithContent(existingSheetWithContents)
        }
      } else {
        // Otherwise we need to fetch and store the contents
        try {
          const { data: sheetWithContents } = await getSheetWithContents(
            apiClient,
            sovId,
            sheet.id,
          )

          setSheetWithContentList([...sheetWithContentList, sheetWithContents])

          if (selectSheet) {
            setSelectedSheetWithContent(sheetWithContents)
          }
        } catch (e) {
          Sentry.captureException(e)
          const message = `Could not load content for sheet ${sheet.name}. Please try again.`

          if (e instanceof Error) {
            setToastParams({
              type: "error",
              message: message,
            })
            triggerToast()
            throw e
          } else {
            throw new Error(message)
          }
        }
      }
    },
    [apiClient, sheetWithContentList, sovId, triggerToast],
  )

  useEffect(() => {
    // Attempt to populate sheets when the activeSheet is altered
    if (activeSheet) {
      populateSheetContentsAndSelect(activeSheet)
    }
  }, [activeSheet, populateSheetContentsAndSelect])

  return (
    <PageWrapper
      onSkip={() =>
        navigate(`/${sovId}/summary`, {
          state: {
            populateBlankAssets: true,
          },
        })
      }
    >
      {sheets.length > 0 ? (
        <PageLayout
          pageHeading="Tag SOV data"
          sidebarSectionHeading="Manage tables"
          sidebar={
            selectedSheetWithContent ? (
              <StepTwoSidebar
                dataPoints={dataPoints}
                onAddTable={async sheetId => {
                  const sheet = sheetWithContentList.find(s => s.id === sheetId)
                  if (!sheet) {
                    return
                  }

                  await onAddTable(
                    apiClient,
                    sovId,
                    setTables,
                    runAsyncActionWithToast,
                    sheet,
                  )
                }}
                onChangeTableCurrency={(tableId, manualOrMapped) => {
                  const table = tables.find(({ id }) => id === tableId)
                  if (!table) {
                    return
                  }

                  onChangeTableCurrency(
                    dataPoints,
                    updateTable,
                    table,
                    manualOrMapped,
                  )
                }}
                onChangeTableMapping={(tableId, field, indices) => {
                  const table = tables.find(({ id }) => id === tableId)
                  if (!table) {
                    return
                  }

                  updateTable(table.id, {
                    extracted: { ...table.extracted, [field]: indices },
                  })
                }}
                onDeleteTable={async tableId => {
                  if (tableId === activeTableId) {
                    setActiveTableId(null)
                  }

                  await onDeleteTable(
                    apiClient,
                    sovId,
                    setTables,
                    runAsyncActionWithToast,
                    tableId,
                  )
                }}
                onGoToDataPoint={(tableId, dataPointId) => {
                  const table = tables.find(({ id }) => id === tableId)
                  if (!table) {
                    return
                  }

                  setActiveDataPointId(dataPointId)
                  setActiveTableId(table.id)

                  onGoToDataPoint(gridRef, dataPointId, table)
                }}
                onJumpToTable={(sheetId, tableId) => {
                  const targetTable = tables.find(({ id }) => id === tableId)
                  if (!targetTable) {
                    return
                  }

                  setActiveSheetIdx(sheets.findIndex(s => s.id === sheetId))
                  setActiveTableId(tableId)
                  scrollToCell(gridRef, targetTable.top_left)
                }}
                onSaveTables={onSaveTables(
                  apiClient,
                  sovId,
                  updateTable,
                  runAsyncActionWithToast,
                )}
                setTableBounds={async (tableId, bounds) => {
                  const table = tables.find(t => t.id === tableId)
                  const sheet = sheetWithContentList.find(
                    s => s.id === table?.sheet_id,
                  )
                  if (!table || !sheet) {
                    return
                  }

                  await onSetTableBounds(
                    apiClient,
                    sovId,
                    dataPoints,
                    updateTable,
                    runAsyncActionWithToast,
                    sheet,
                    table,
                    bounds,
                  )
                }}
                setTablePreview={setTablePreview}
                setActiveTableId={setActiveTableId}
                onClickBack={() => {
                  setSelectedSheetWithContent(null)
                }}
                sheet={selectedSheetWithContent}
                tables={tables}
              />
            ) : (
              <StepOneSidebar
                sheets={sheets}
                tables={tables}
                setActiveSheetIdx={setActiveSheetIdx}
                setActiveTableId={setActiveTableId}
                setExtractionModalOpen={setExtractionModalOpen}
                populateSheetContentsAndSelect={populateSheetContentsAndSelect}
              />
            )
          }
          toolbar={
            <div className="rounded-t-md bg-otto-night py-1 px-2 text-white forced-colors:border forced-colors:border-otto-grey-400">
              <p className="truncate text-base">{fileName}</p>
            </div>
          }
          spreadsheetSectionHeading="Original SOV"
          spreadsheet={
            <SpreadsheetWithTabs
              activeDataPointId={activeDataPointId}
              activeSheet={activeSheet}
              activeTableId={activeTableId}
              gridRef={gridRef}
              dataPoints={dataPoints}
              onExtract={(tableId, extracted) => {
                // If any of the currency data points now have a mapping, clear
                // the table-level currency
                if (
                  dataPoints
                    .filter(dp => dp.type === "currency")
                    .some(dp => (extracted[dp.field] ?? []).length > 0)
                ) {
                  updateTable(tableId, { extracted, currency: undefined })
                } else {
                  updateTable(tableId, { extracted })
                }
              }}
              setActiveSheetIdx={setActiveSheetIdx}
              setActiveTableId={setActiveTableId}
              allTables={tables}
              sheetTables={sheetTables}
              sheets={sheets}
              sheetWithContentList={sheetWithContentList}
              tablePreview={tablePreview}
              populateSheetContentsAndSelect={populateSheetContentsAndSelect}
            />
          }
        />
      ) : (
        <ErrorScreen
          message={`No sheets were found in the uploaded file ${fileName}`}
        />
      )}

      <ExtractionModal
        isOpen={isExtractionModalOpen}
        onClose={() => setExtractionModalOpen(false)}
        onContinue={async () => {
          const allSheets = await fillMissingSheetContents()
          setExtractionModalOpen(false)
          navigate(`/${sovId}/summary`, {
            state: { sheets: allSheets, tables: tables },
          })
        }}
        sheets={sheets}
        tables={tables}
      />

      <Toast.Toast
        open={toastOpen}
        onOpenChange={onToastOpenChange}
        type={toastParams.type}
        message={toastParams.message}
      />
    </PageWrapper>
  )
}

export default ExtractionScreen

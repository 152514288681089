import { FC, useEffect, useId, useState } from "react"

import {
  Button,
  ErrorMessage,
  ExclamationIcon,
  Input,
  InputLabel,
  Select,
} from "@appia/ui-components"

import { indexToColumn, printCoords } from "src/common/utils"
import { tableHeaderHasChanged } from "../utils"

import { FormField } from "@appia/form-data"
import { isBottomRightSmallerThanTopLeft, validateCoords } from "./validation"

import { TableBounds } from "../types"
import { SheetCoords, Table } from "src/common/types"

import classNames from "classnames"

const CoordsInput: FC<{
  title: string
  errorTestId: string
  exampleCoords: SheetCoords
  globalErrorId?: string
  label: string
  minOrMax: "min" | "max"
  onChange: (s: string) => void
  showErrors: boolean
  formField: FormField<SheetCoords>
}> = ({
  title,
  errorTestId,
  exampleCoords,
  globalErrorId,
  label,
  minOrMax,
  onChange,
  showErrors,
  formField,
}) => {
  const hasLocalError = formField.validated.valid === false
  const hasGlobalError = !!globalErrorId

  const inputId = useId()
  const errorId = useId()

  return (
    <>
      <label
        htmlFor={inputId}
        className="-mb-2 flex items-center gap-2 xl:mb-0"
      >
        <div
          aria-hidden
          className={classNames("h-4 w-4 border-2 border-otto-grey-700", {
            "border-b-dotted border-r-dotted": minOrMax === "min",
            "border-t-dotted border-l-dotted": minOrMax === "max",
          })}
        />
        <span>
          {label}
          <span className="sr-only">. </span>
          {minOrMax === "min" ? "Min" : "Max"}: {printCoords(exampleCoords)}
        </span>
      </label>

      <Input
        title={title}
        id={inputId}
        aria-describedby={
          showErrors === false
            ? undefined
            : hasLocalError && hasGlobalError
            ? `${errorId} ${globalErrorId}`
            : hasLocalError
            ? errorId
            : hasGlobalError
            ? globalErrorId
            : undefined
        }
        hasError={showErrors && (hasLocalError || hasGlobalError)}
        placeholder={`e.g. ${printCoords(exampleCoords)}`}
        value={formField.raw}
        onChange={s => onChange(s.toUpperCase())}
      />

      {showErrors && formField.validated.valid === false && (
        <div className="-mt-3 xl:col-start-2">
          <ErrorMessage
            id={errorId}
            data-testid={errorTestId}
            message={formField.validated.error}
          />
        </div>
      )}
    </>
  )
}

const OrientationInput: FC<{
  onChange: (o: Table["orientation"]) => void
  showErrors: boolean
  value: Table["orientation"]
}> = ({ onChange, showErrors, value }) => {
  const errorId = useId()

  const hasError = showErrors && value === "unknown"

  return (
    <>
      <InputLabel data-cy="orientation-label" label="Orientation">
        <Select
          errorMessageId={hasError ? errorId : undefined}
          placeholder="Select orientation"
          selectedValue={value === "unknown" ? null : value}
          onSelect={s => {
            if (s !== "horizontal" && s !== "vertical") {
              onChange("unknown")
            } else {
              onChange(s)
            }
          }}
          options={[
            { label: "Header row", value: "vertical" },
            { label: "Header column", value: "horizontal" },
          ]}
          truncateItems={false}
        />
      </InputLabel>

      {hasError && (
        <div className="-mt-3">
          <ErrorMessage
            id={errorId}
            data-testid="orientation-error"
            message="Select a table orientation"
          />
        </div>
      )}
    </>
  )
}

export interface TableBoundsFormProps {
  numSheetColumns: number
  numSheetRows: number
  onSubmit: (ts: TableBounds) => void
  setTablePreview: (ts: TableBounds | null) => void
  table: Table
}

const TableBoundsForm: FC<TableBoundsFormProps> = ({
  numSheetColumns,
  numSheetRows,
  onSubmit,
  setTablePreview,
  table,
}) => {
  // Store the user's working changes, which may or may not be valid
  const [formFields, setFormFields] = useState<{
    bottom_right: FormField<SheetCoords>
    orientation: Table["orientation"]
    top_left: FormField<SheetCoords>
  }>({
    bottom_right: {
      raw: printCoords(table.bottom_right),
      validated: { valid: true, data: table.bottom_right },
    },
    orientation: table.orientation,
    top_left: {
      raw: printCoords(table.top_left),
      validated: { valid: true, data: table.top_left },
    },
  })

  useEffect(() => {
    // Update the table preview as the user changes the draft table coordinates.
    // If the draft coordinates aren't valid, use the existing coordinates.

    const topLeftCoords = formFields.top_left.validated.valid
      ? formFields.top_left.validated.data
      : table.top_left

    const bottomRightCoords = formFields.bottom_right.validated.valid
      ? formFields.bottom_right.validated.data
      : table.bottom_right

    setTablePreview({
      top_left: topLeftCoords,
      bottom_right: bottomRightCoords,
      orientation: formFields.orientation,
    })

    // When this component unmounts, remove the table preview
    return () => {
      setTablePreview(null)
    }
  }, [setTablePreview, formFields, table])

  const [showErrors, setShowErrors] = useState<boolean>(false)

  const maxSheetCol = indexToColumn(numSheetColumns - 1)

  const minPossibleCoords: SheetCoords = { column: "A", row: 1 }
  const maxPossibleCoords: SheetCoords = {
    column: maxSheetCol,
    row: numSheetRows,
  }

  const bottomRightSmallerThanTopLeft = isBottomRightSmallerThanTopLeft(
    formFields.bottom_right.validated,
    formFields.top_left.validated,
  )

  const showFormError = showErrors && bottomRightSmallerThanTopLeft

  const newTable: Table = {
    ...table,
    bottom_right: formFields.bottom_right.validated.valid
      ? formFields.bottom_right.validated.data
      : table.bottom_right,
    orientation: formFields.orientation,
    top_left: formFields.top_left.validated.valid
      ? formFields.top_left.validated.data
      : table.top_left,
  }

  const showHeaderMappingWarning = tableHeaderHasChanged(table, newTable)

  const formHeadingId = useId()
  const formErrorId = useId()

  return (
    <form
      aria-labelledby={formHeadingId}
      className="rounded border border-otto-grey-400 bg-white p-4 text-sm shadow-lg"
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()

        setShowErrors(false)

        if (
          formFields.top_left.validated.valid === false ||
          formFields.bottom_right.validated.valid === false ||
          formFields.orientation === "unknown" ||
          bottomRightSmallerThanTopLeft
        ) {
          setShowErrors(true)
          return
        }

        onSubmit({
          bottom_right: formFields.bottom_right.validated.data,
          orientation: formFields.orientation,
          top_left: formFields.top_left.validated.data,
        })
      }}
    >
      <h4 id={formHeadingId} className="mb-2 text-lg font-bold">
        Table range
      </h4>

      <div className="grid gap-4">
        <p className="text-base">
          Please set the top left and bottom right bounds for your SOV,
          including the header{" "}
          {formFields.orientation === "horizontal" ? "column" : "row"}.
        </p>

        <div className="grid items-center gap-4 xl:grid-cols-[auto,1fr]">
          <CoordsInput
            title="Top left input"
            errorTestId="top-left-error"
            exampleCoords={minPossibleCoords}
            globalErrorId={showFormError ? formErrorId : undefined}
            label="Top left"
            minOrMax="min"
            onChange={s => {
              const validated = validateCoords(
                minPossibleCoords,
                maxPossibleCoords,
                s,
              )
              setFormFields(wfs => ({
                ...wfs,
                top_left: { raw: s, validated },
              }))
            }}
            showErrors={showErrors}
            formField={formFields.top_left}
          />

          <CoordsInput
            title="Bottom right input"
            errorTestId="bottom-right-error"
            exampleCoords={maxPossibleCoords}
            globalErrorId={showFormError ? formErrorId : undefined}
            label="Bottom right"
            minOrMax="max"
            onChange={s => {
              const validated = validateCoords(
                minPossibleCoords,
                maxPossibleCoords,
                s,
              )
              setFormFields(wfs => ({
                ...wfs,
                bottom_right: { raw: s, validated },
              }))
            }}
            showErrors={showErrors}
            formField={formFields.bottom_right}
          />

          {showFormError && (
            <ErrorMessage
              id={formErrorId}
              data-testid="form-error"
              className="col-span-2"
              message="Enter a top left that has smaller values than the bottom right"
            />
          )}
        </div>

        <OrientationInput
          onChange={orientation =>
            setFormFields(wfs => ({ ...wfs, orientation }))
          }
          showErrors={showErrors}
          value={formFields.orientation}
        />

        {showHeaderMappingWarning && (
          <p
            className="flex items-start gap-2 text-sm text-otto-yellow-900"
            data-testid="header-warning"
          >
            <ExclamationIcon className="mt-0.5 w-4 flex-shrink-0 text-otto-yellow-600" />
            When confirming this range, all existing mappings will be removed
            and replaced with our suggestions.
          </p>
        )}

        <Button
          data-cy="confirm-table-bounds-form-button"
          className="justify-self-end"
          label="Confirm"
          size="small"
          stretch="none"
          style="filled"
          theme="night"
        />
      </div>
    </form>
  )
}

export default TableBoundsForm

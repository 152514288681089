import { FC, useState } from "react"

import { CalculateIcon, Callout, Checkbox } from "@appia/ui-components"

import ErrorCorrectionSidebarTemplate from "./ErrorCorrectionSidebarTemplate"

import { DataPointNumber, DataPoints } from "src/common/dataPoints"

import { SOVAssets } from "../../types"

import {
  BI_FIELD,
  PD_FIELD,
  TIV_FIELD,
  autoFillBi,
  autoFillPd,
  autoFillTiv,
} from "../../money"

const NumberErrorCorrectionSidebar: FC<{
  dataPoints: DataPoints
  dataPoint: DataPointNumber
  hasErrors: boolean
  onApply: (f: (a: SOVAssets) => SOVAssets) => void
  onBack: () => void
}> = ({ dataPoints, dataPoint, hasErrors, onApply, onBack }) => {
  const [checked, setChecked] = useState<boolean>(false)

  return (
    <ErrorCorrectionSidebarTemplate
      heading={dataPoint.label}
      ctaDisabled={!checked || !hasErrors}
      onApply={() => {
        switch (dataPoint.field) {
          case BI_FIELD:
            onApply(assets => autoFillBi(dataPoints, assets))
            break
          case PD_FIELD:
            onApply(assets => autoFillPd(dataPoints, assets))
            break
          case TIV_FIELD:
            onApply(assets => autoFillTiv(dataPoints, assets))
            break
          default:
            throw new Error(`Unexpected number field: ${dataPoint.field}`)
        }
      }}
      onBack={onBack}
    >
      {hasErrors ? (
        <label className="flex items-start gap-2">
          <CalculateIcon className="w-6 shrink-0" />

          <span>Calculate values where possible in empty cells</span>

          <div className="ml-auto">
            <Checkbox checked={checked} onChange={setChecked} />
          </div>
        </label>
      ) : (
        <Callout type="success">
          All rows are valid for {dataPoint.label}
        </Callout>
      )}
    </ErrorCorrectionSidebarTemplate>
  )
}

export default NumberErrorCorrectionSidebar

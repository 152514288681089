import { DataPoint } from "src/common/dataPoints"
import { SOVAssets } from "../../types"

export interface ReplacementState<T> {
  value: unknown // Unique
  displayValue: string // Not unique
  replacement: T | null
}

export const mkReplacementState = <T>(value: unknown): ReplacementState<T> => ({
  value,
  displayValue: value === null || value === undefined ? "" : `${value}`,
  replacement: null,
})

export const applyReplacements = <T>(
  field: DataPoint["field"],
  mapReplacement: (t: T) => string,
  replacementStates: ReplacementState<T>[],
  assets: SOVAssets,
): SOVAssets =>
  replacementStates.reduce((acc, { value, replacement }) => {
    return acc.map(asset => {
      const dpValue = asset[field]

      if (dpValue === value && replacement !== null) {
        return { ...asset, [field]: mapReplacement(replacement) }
      } else {
        return asset
      }
    })
  }, assets)

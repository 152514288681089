import { createContext, useContext } from "react"
import { AxiosInstance } from "axios"

import { ApiClient } from "src/api"

const apiClient = {} as AxiosInstance

export const ApiClientContext = createContext<ApiClient>(apiClient)

const useApiClient = (): ApiClient => {
  const apiClient = useContext(ApiClientContext)
  if (apiClient === null) {
    throw new Error("Tried to access uninitialised API client")
  }

  return apiClient
}

export default useApiClient

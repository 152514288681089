import { FC, useId, useState } from "react"

import {
  Select,
  SelectMultiple,
  SelectOption,
  ToggleTabs,
} from "@appia/ui-components"
import LabelWithJumpButton from "./LabelWithJumpButton"

import { DataPoint, DataPointCurrency } from "src/common/dataPoints"

import { Table } from "src/common/types"

const MAPPED_CURRENCY = "mappedCurrency" as const
const MANUAL_CURRENCY = "manualCurrency" as const

type CurrencyTabs = {
  value: typeof MAPPED_CURRENCY | typeof MANUAL_CURRENCY
  label: string
}[]

const currencyTabs: CurrencyTabs = [
  {
    value: MAPPED_CURRENCY,
    label: "Column",
  },
  {
    value: MANUAL_CURRENCY,
    label: "Set manually",
  },
]

export type ManualOrMappedCurrency =
  | { type: typeof MANUAL_CURRENCY; value: string }
  | {
      type: typeof MAPPED_CURRENCY
      field: DataPoint["field"]
      indices: number[]
    }

interface MappedCurrencyConfig {
  options: SelectOption[]
  selectedValues: string[]
}

interface Props {
  dataPoint: DataPointCurrency
  manualCurrencyOptions: SelectOption[]
  mappedCurrencyConfig: MappedCurrencyConfig
  onChange: (c: ManualOrMappedCurrency) => void
  onGoToDataPoint: (dpId: DataPoint["field"]) => void
  stringToIdx: (s: string) => number
  setActiveTableId: (tableId: Table["id"]) => void
  table: Table
}

export const CurrencyLayout: FC<Props> = ({
  dataPoint,
  manualCurrencyOptions,
  mappedCurrencyConfig,
  onChange,
  onGoToDataPoint,
  stringToIdx,
  setActiveTableId,
  table,
}) => {
  const { orientation, currency } = table

  const hasCurrencyColumnMapped = mappedCurrencyConfig.selectedValues.length > 0

  const [activeCurrencyTab, setActiveCurrencyTab] = useState<string>(
    hasCurrencyColumnMapped ? MAPPED_CURRENCY : MANUAL_CURRENCY,
  )

  const selectId = useId()

  return (
    <>
      <hr aria-hidden className="border-top col-span-2 border-otto-grey-300" />

      <LabelWithJumpButton
        dataPoint={dataPoint}
        inputId={selectId}
        onGoToDataPoint={onGoToDataPoint}
        showJumpButton={hasCurrencyColumnMapped}
      />

      <ToggleTabs.Root
        onTabChange={setActiveCurrencyTab}
        activeTab={activeCurrencyTab}
      >
        <div className="mb-2">
          <ToggleTabs.List tabs={currencyTabs} activeTab={activeCurrencyTab} />
        </div>

        <ToggleTabs.Content value={MAPPED_CURRENCY}>
          <SelectMultiple
            id={selectId}
            placeholder={`Select ${
              orientation === "horizontal" ? "rows" : "columns"
            }`}
            options={mappedCurrencyConfig.options}
            selectedValues={mappedCurrencyConfig.selectedValues}
            onFocus={() => setActiveTableId(table.id)}
            onSelect={vs => {
              const indices: number[] = vs.map(s => s.trim()).map(stringToIdx)
              onChange({
                type: MAPPED_CURRENCY,
                field: dataPoint.field,
                indices,
              })
            }}
          />
        </ToggleTabs.Content>

        <ToggleTabs.Content value={MANUAL_CURRENCY}>
          <Select
            id={selectId}
            placeholder="Set manually"
            selectedValue={currency}
            onFocus={() => setActiveTableId(table.id)}
            onSelect={c => {
              onChange({ type: MANUAL_CURRENCY, value: c })
            }}
            options={manualCurrencyOptions}
            truncateItems={false}
          />
        </ToggleTabs.Content>
      </ToggleTabs.Root>
    </>
  )
}

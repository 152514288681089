import { FC } from "react"
import DOMPurify from "dompurify"
import { Callout } from "./index"

const ErrorCallout: FC<{
  message: string
  error?: Error
}> = ({ message, error }) => {
  const sanitizedHTML = DOMPurify.sanitize(message)

  return (
    <Callout type="error">
      <p dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      {error && <code className="text-sm">{error.message}</code>}
    </Callout>
  )
}

export default ErrorCallout

import { SuccessResponse, Uppy, UppyFile } from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"

type UppyError =
  | { type: "error"; error: Error }
  | { type: "restriction-failed"; error: Error; file?: UppyFile }
  | { type: "upload-error"; error: Error; file?: UppyFile }

export const initialiseGcsUppy = (
  id: string,
  maxMegabytes: number,
  acceptedDocumentTypes: string[],
  getUploadParameters: (
    file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
    uppy: Uppy,
  ) => Promise<{
    url: string
    headers: Record<string, string>
    method: "PUT"
  }>,
  onStartLoad: () => void,
  onProgress: (percentage: number) => void,
  onError: (e: UppyError) => void,
  onSuccess: (
    response: SuccessResponse,
    file?: UppyFile<Record<string, unknown>, Record<string, unknown>>,
  ) => void,
): Uppy => {
  const maxBytes = maxMegabytes * 1_000_000

  const uppy = new Uppy({
    id,
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxFileSize: maxBytes,
      minFileSize: 1,
      maxTotalFileSize: maxBytes,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: acceptedDocumentTypes,
    },
    debug: true,
  })

  uppy
    .use(AwsS3, {
      limit: 1,
      getUploadParameters: async (
        file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
      ) => {
        onStartLoad()
        const params = await getUploadParameters(file, uppy)
        return {
          method: params.method,
          headers: params.headers || {},
          url: params.url,
        }
      },
    })
    .on("error", (error: Error) => onError({ type: "error", error }))
    .on("restriction-failed", (file: UppyFile | undefined, error: Error) =>
      onError({ type: "restriction-failed", file, error }),
    )
    .on("upload-error", (file: UppyFile | undefined, error: Error) =>
      onError({ type: "upload-error", file, error }),
    )
    .on("progress", (progress: number) => onProgress(progress))
    .on(
      "upload-success",
      (file: UppyFile | undefined, response: SuccessResponse) =>
        onSuccess(response, file),
    )

  return uppy
}

import { FC, PropsWithChildren, useId } from "react"

import { ArrowLeftIcon, Button } from "@appia/ui-components"

import { useFocusHeading } from "src/common/hooks"

type CTAType = "apply" | "delete"

const ErrorCorrectionSidebar: FC<
  PropsWithChildren<{
    ctaType?: CTAType
    ctaDisabled: boolean
    heading: string
    onApply: () => void
    onBack: () => void
  }>
> = ({
  ctaType = "apply",
  ctaDisabled,
  heading,
  onApply,
  onBack,
  children,
}) => {
  const headingId = useId()
  const headingRef = useFocusHeading()

  const buttonLabel = "Return to validation overview"

  return (
    <div className="h-full">
      <form
        className="flex h-full flex-col shadow-none"
        aria-labelledby={headingId}
        noValidate
        onSubmit={e => {
          e.preventDefault()
          onApply()
        }}
      >
        <button
          onClick={onBack}
          title={buttonLabel}
          aria-label={buttonLabel}
          className="flex items-center gap-4 rounded-t-lg border border-otto-grey-300 bg-white p-4"
        >
          <ArrowLeftIcon className="w-6" />

          <span className="text-xl font-bold">{heading}</span>
        </button>

        <h3 id={headingId} ref={headingRef} tabIndex={-1} className="sr-only">
          {heading}
        </h3>

        <div className="h-0 flex-[1_1_auto] overflow-y-auto rounded-b-lg">
          <div
            data-cy="error-correction-sidebar-content"
            className="border-x border-otto-grey-300 bg-white p-4"
          >
            {children}
          </div>

          <div className="sticky bottom-0 rounded-b-lg border border-otto-grey-300 bg-white p-4">
            <Button
              label={ctaType === "apply" ? "Apply" : "Delete"}
              aria-label={
                ctaType === "apply"
                  ? "Apply selected changes"
                  : "Delete selected rows"
              }
              size="regular"
              theme={ctaType === "apply" ? "night" : "destructive"}
              style="filled"
              stretch="space-around"
              disabled={ctaDisabled}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ErrorCorrectionSidebar

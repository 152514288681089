import { Switch } from "@headlessui/react"
import { FC, memo } from "react"
import Toggle, { ToggleProps } from "./Toggle"

export interface DescriptionProps {
  title: string
  description: string
}

const ToggleWithDescription: FC<ToggleProps & DescriptionProps> = props => {
  const { title, description } = props

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-base font-medium" passive>
          {title}
        </Switch.Label>

        <Switch.Description as="span" className="text-base text-otto-grey-700">
          {description}
        </Switch.Description>
      </span>

      <Toggle {...props} />
    </Switch.Group>
  )
}

export default memo(ToggleWithDescription)

import { createContext, useContext } from "react"

export interface Config {
  acceptedDocumentTypes: string[]
  sizeLimitMb: number
  redirectUri?: string
  failureUri?: string
  intercomEnabled?: boolean
  intercomId?: string
  intercomUserEmail?: string
  intercomUserHmac?: string
}

export const ConfigContext = createContext<Config>({} as Config)

const useConfig = (): Config => {
  const config = useContext(ConfigContext)

  if (config === null) {
    throw new Error(
      "Something went wrong in asking for my configuration. Please try refreshing.",
    )
  }

  sessionStorage.setItem("sov_config", JSON.stringify(config))

  return config
}

export default useConfig

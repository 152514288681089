import { FC, useId } from "react"

import { Select, SelectOption } from "@appia/ui-components"

import { Table } from "src/common/types"
import { DataPoint } from "src/common/dataPoints"

import { columnToIndex } from "src/common/utils"

import { ICellRendererParams } from "@ag-grid-community/core"

interface HeaderCellRendererProps extends ICellRendererParams {
  activeTable: Table
  options: SelectOption[]
  onMapRowOrColumn: (dpId: DataPoint["field"] | null) => void
}

const HeaderCellRenderer: FC<HeaderCellRendererProps> = ({
  activeTable,
  options,
  onMapRowOrColumn,
  rowIndex,
  value,
  colDef,
}) => {
  const selectId = useId()

  if (!colDef || !colDef.field || !activeTable) {
    return value
  }

  const { orientation, extracted, top_left } = activeTable

  let idx: number
  switch (orientation) {
    case "vertical":
    case "unknown": {
      idx = columnToIndex(colDef.field) - columnToIndex(top_left.column)
      break
    }
    case "horizontal": {
      idx = rowIndex - top_left.row + 1
      break
    }
  }

  const mappedDataPoint: string | null =
    Object.keys(extracted).find(dp => (extracted[dp] ?? []).includes(idx)) ??
    null

  return (
    <div
      data-cy="header-cell-renderer"
      className="flex h-full flex-col justify-between gap-2 py-2 leading-normal"
    >
      <p className="truncate">{`${value ?? ""}`}</p>

      <div>
        <label className="sr-only" htmlFor={selectId}>
          {`Map data point to ${
            orientation === "horizontal"
              ? `row ${rowIndex + 1}`
              : `column ${colDef.field}`
          }`}
        </label>

        <Select
          id={selectId}
          placeholder="Select field"
          options={options}
          onSelect={v => onMapRowOrColumn(v === "none" ? null : v)}
          selectedValue={mappedDataPoint}
          truncateItems={false}
        />
      </div>
    </div>
  )
}

export default HeaderCellRenderer

import { FC, ReactNode, useLayoutEffect, useRef, useState } from "react"
import * as Tabs from "@radix-ui/react-tabs"
import classNames from "classnames"

interface Tab {
  label: string
  value: string
}

export const Root: FC<{
  activeTab: string
  onTabChange: (value: string) => void
  children: ReactNode
}> = ({ activeTab, onTabChange, children }) => (
  <Tabs.Root
    value={activeTab}
    onValueChange={onTabChange}
    orientation="horizontal"
    activationMode="manual"
  >
    {children}
  </Tabs.Root>
)

export const List: FC<{
  tabs: Tab[]
  activeTab: string
}> = ({ tabs, activeTab }) => {
  const activeTabIdx = tabs.findIndex(t => t.value === activeTab)

  const tabListRef = useRef<HTMLDivElement>(null)

  const [highlightPos, setHighlightPos] = useState<{
    left: number
    width: number
  } | null>(null)

  useLayoutEffect(() => {
    const activeTabElement = tabListRef.current?.querySelectorAll(
      '[role="tab"]',
    )[activeTabIdx] as HTMLElement | undefined

    if (activeTabElement) {
      setHighlightPos({
        left: activeTabElement.offsetLeft,
        width: activeTabElement.offsetWidth,
      })
    }
  }, [activeTabIdx])

  return (
    <Tabs.List
      loop={false}
      className="relative isolate grid w-max auto-cols-auto grid-flow-col grid-rows-1 items-center rounded-full border-2 border-otto-mist bg-otto-mist py-2"
      ref={tabListRef}
    >
      <div
        role="presentation"
        className={classNames(
          "absolute left-0 top-0 bottom-0 z-0 transform self-stretch rounded-full bg-white p-2 forced-colors:bg-SystemHighlight motion-safe:transition-[width,transform] motion-safe:duration-200 motion-safe:ease-in-out",
          // Hide the element until it already has a position set, otherwise it
          // will animate from 0 to its initial position when the page loads
          { hidden: highlightPos === null },
        )}
        style={{
          width: `${highlightPos?.width || 0}px`,
          transform: `translateX(${highlightPos?.left || 0}px)`,
        }}
      />

      {tabs.map((tab, i) => (
        <Tabs.Trigger
          key={i}
          value={tab.value}
          className="otto-focus relative z-10 cursor-pointer rounded-full bg-transparent px-2 text-center text-base"
        >
          {/* When this tab is selected, the label switches to a bold font,
              which causes the tabs to jump slightly as the width changes. To
              avoid this we render a hidden `span` that's always bold, so that
              the element is already the correct width. The 'real' `span` that
              the user sees is positioned over the top of the hidden one.
          */}
          <span aria-hidden className="select-none font-bold opacity-0">
            {tab.label}
          </span>
          <span
            className={classNames("absolute inset-0", {
              "font-bold": tab.value === activeTab,
            })}
          >
            {tab.label}
          </span>
        </Tabs.Trigger>
      ))}
    </Tabs.List>
  )
}

export const Content: FC<Tabs.TabsContentProps> = ({ className, ...props }) => (
  <Tabs.Content
    {...props}
    className={classNames("otto-focus-inset", className)}
  />
)

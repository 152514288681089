import {
  DataPoint,
  DataPointCountry,
  DataPointCurrency,
  DataPointNumber,
} from "src/common/dataPoints"

export const ASSET_UNIQUE_ID_FIELD = "assetUniqueId" as const

export type SOVAsset = Record<DataPoint["field"], unknown> &
  Record<typeof ASSET_UNIQUE_ID_FIELD, string>

export type SOVAssets = SOVAsset[]

export type ValidationStatus = "invalid" | "valid"

export interface CellStatus {
  dataPointId: DataPoint["field"]
  colIdx: number
  rowIdx: number
  status: ValidationStatus
}

/**
 * A mapping like
 *
 * {
 *   "USD": { bi: 1, pd: 2, tiv: 3 },
 *   "GBP": { bi: 4, pd: 5, tiv: 9 },
 * }
 */
export type TotalsPerCurrency = Record<
  string,
  Record<DataPoint["field"], number>
>

export type DataPointWithEditingView =
  | DataPointCountry
  | DataPointCurrency
  | DataPointNumber

export type EditingView =
  // Default = show all rows for editing, with or without errors
  | { mode: "default" }
  // Show all rows that have errors
  | { mode: "allInvalid" }
  // Show only rows where the field for a particular data point has an error
  | { mode: "dataPoint"; dataPoint: DataPointWithEditingView }

export const isDataPointWithEditingView = (
  dp: DataPoint,
): dp is DataPointWithEditingView =>
  dp.type === "country" || dp.type === "currency" || dp.type === "number"

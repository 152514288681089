import { FC } from "react"
import classNames from "classnames"

import {
  CheckFilledCircleIcon,
  ExclamationFilledCircleIcon,
  Icon,
  InformationFilledCircleIcon,
} from "./Icons"

export interface MessageProps {
  className?: string
  id?: string
  message: string
  "data-testid"?: string
}

const Base: FC<
  MessageProps & {
    textColor: string
    iconColor: string
    Icon: Icon
  }
> = ({
  className,
  "data-testid": testId,
  iconColor,
  Icon,
  id,
  message,
  textColor,
}) => (
  <p
    className={classNames("flex items-center text-sm", textColor, className)}
    id={id}
    data-testid={testId}
  >
    <Icon className={classNames("mr-1 w-4 shrink-0", iconColor)} aria-hidden />
    <span>{message}</span>
  </p>
)

export const ErrorMessage: FC<MessageProps> = props => (
  <Base
    textColor="text-error"
    iconColor="text-otto-bright-red"
    Icon={ExclamationFilledCircleIcon}
    {...props}
  />
)

export const SuccessMessage: FC<MessageProps> = props => (
  <Base
    textColor="text-otto-grey-700"
    iconColor="text-otto-deep-green"
    Icon={CheckFilledCircleIcon}
    {...props}
  />
)

export const WarningMessage: FC<MessageProps> = props => (
  <Base
    textColor="text-otto-grey-700"
    iconColor="text-otto-pop-900"
    Icon={InformationFilledCircleIcon}
    {...props}
  />
)

import { ChangeEventHandler, FC, ReactNode, Ref, useState } from "react"

import classNames from "classnames"

const UploadIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    role="img"
    focusable={false}
    aria-hidden
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="124" height="124" rx="62" fill="#F7F7F7" />
    <path
      d="M66.6663 38.6666L80.6663 52.6666V80.6666C80.6663 81.9043 80.1747 83.0913 79.2995 83.9664C78.4243 84.8416 77.2374 85.3333 75.9997 85.3333H47.9997C46.762 85.3333 45.575 84.8416 44.6998 83.9664C43.8247 83.0913 43.333 81.9043 43.333 80.6666V43.3333C43.333 42.0956 43.8247 40.9086 44.6998 40.0335C45.575 39.1583 46.762 38.6666 47.9997 38.6666H66.6663ZM75.9997 80.6666V55H64.333V43.3333H47.9997V80.6666H75.9997ZM61.9997 62L71.333 71.3333H65.4997V78.3333H58.4997V71.3333H52.6663L61.9997 62Z"
      fill="#8C8C8C"
    />
  </svg>
)

export interface FileUploadAreaProps {
  input: ReactNode
  label: ReactNode
}

export const FileUploadArea: FC<FileUploadAreaProps> = ({ input, label }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false)

  return (
    <label
      className={classNames(
        "otto-focus-within relative block cursor-pointer rounded border border-dashed bg-white p-8 text-center text-otto-grey-700",
        {
          "border-otto-grey-500": !isDragging,
          "border-otto-pop": isDragging,
        },
      )}
      onDragEnter={() => setIsDragging(true)}
      onDragOver={e => e.preventDefault()}
      onDragLeave={() => setIsDragging(false)}
      onDrop={() => setIsDragging(false)}
    >
      <UploadIcon className="mx-auto mb-4" />

      {label}

      {input}
    </label>
  )
}

export interface FileUploadProps {
  acceptedFileTypes: string[]
  inputRef?: Ref<HTMLInputElement>
  label: ReactNode
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const FileUpload: FC<FileUploadProps> = ({
  acceptedFileTypes,
  inputRef,
  label,
  onChange,
}) => (
  <FileUploadArea
    label={label}
    input={
      <input
        accept={acceptedFileTypes.join(",")}
        className="sr-only"
        ref={inputRef}
        type="file"
        onChange={onChange}
      />
    }
  />
)

import { ReactElement, createContext, useEffect, useState } from "react"

const getToken = (): string => {
  const params = new URLSearchParams(window.location.search.replace("?", ""))
  const token = params.get("signing_token")

  const state = sessionStorage.getItem("sov_token")

  if (state && (token === null || state === token)) {
    return state
  }

  if (!token) {
    throw new Error("Token not provided. Exiting")
  }

  return token
}

const TokenContext = createContext<string>("")

export const TokenProvider = ({
  children,
}: React.PropsWithChildren): ReactElement => {
  const [token] = useState<string>(() => getToken())

  useEffect(() => {
    sessionStorage.setItem("sov_token", token)
  }, [token])

  return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
}

export const TokenConsumer = TokenContext.Consumer

export default TokenContext

import { SuccessResponse, Uppy, UppyFile } from "@uppy/core"
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3"
import { UppyError } from "./uppyError"

export const initialiseAwsS3Uppy = (
  id: string,
  maxMegabytes: number,
  acceptedDocumentTypes: string[],
  getUploadParameters: (
    file: UppyFile,
    uppy: Uppy,
  ) => Promise<AwsS3UploadParameters>,
  onStartLoad: () => void,
  onProgress: (percentage: number) => void,
  onError: (e: UppyError) => void,
  onSuccess: (response: SuccessResponse, file?: UppyFile) => void,
): Uppy => {
  const maxBytes = maxMegabytes * 1_000_000

  const uppy = new Uppy({
    id,
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxFileSize: maxBytes,
      minFileSize: 1,
      maxTotalFileSize: maxBytes,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: acceptedDocumentTypes,
    },
    debug: true,
  })

  uppy
    .use(AwsS3, {
      limit: 1,
      getUploadParameters: async (
        file: UppyFile,
      ): Promise<AwsS3UploadParameters> => {
        onStartLoad()
        const params = await getUploadParameters(file, uppy)
        return {
          url: params.url,
          fields: params.fields,
        }
      },
    })
    .on("error", (error: Error) => onError({ type: "error", error }))
    .on("restriction-failed", (file: UppyFile | undefined, error: Error) =>
      onError({ type: "restriction-failed", file, error }),
    )
    .on("upload-error", (file: UppyFile | undefined, error: Error) =>
      onError({ type: "upload-error", file, error }),
    )
    .on("progress", (progress: number) => onProgress(progress))
    .on(
      "upload-success",
      (file: UppyFile | undefined, response: SuccessResponse) =>
        onSuccess(response, file),
    )

  return uppy
}

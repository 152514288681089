import { FC, memo } from "react"
import { Link } from "./CallToAction"

export interface ActionStackedListItemProps {
  title: string
  action: [title: string, href: string]
}

const ActionStackedListItem: FC<ActionStackedListItemProps> = ({
  title,
  action,
}) => {
  return (
    <div className="px-4 py-4">
      <div className="flex items-center justify-between">
        <div className="min-w-0 flex-1">
          <p className="truncate text-base font-medium">{title}</p>
        </div>

        <div>
          <Link
            label={action[0]}
            theme="night"
            style="outlined"
            href={action[1]}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(ActionStackedListItem)

import { FC, Fragment } from "react"

import { Button, CheckCircleIcon, EmptyIcon } from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"

import { Sheet, Table } from "src/common/types"
import { countExtractedRowsOrColumns } from "./utils"

interface Props {
  isOpen: boolean
  onClose: () => void
  onContinue: () => void
  sheets: Sheet[]
  tables: Table[]
}

const ExtractionModal: FC<Props> = ({
  isOpen,
  onClose,
  onContinue,
  sheets,
  tables,
}) => (
  <ModalTemplate
    dataCy="extraction-page-confirmation-modal"
    actionsReflowBreakpoint="sm"
    actions={[
      <Button
        key="cancel"
        style="outlined"
        label="Cancel"
        theme="night"
        onClick={onClose}
      />,
      <Button
        key="continue"
        style="filled"
        label="Continue"
        theme="night"
        onClick={onContinue}
      />,
    ]}
    title="Have you included and tagged all SOV data?"
    isOpen={isOpen}
    onClose={onClose}
    content={sheets.map((sheet, sheetIdx) => {
      const extractedTable: Table[] = tables.filter(
        t => t.sheet_id === sheet.id,
      )
      const extractedRowsOrColumns = countExtractedRowsOrColumns(extractedTable)
      const numberOfTables = extractedTable.length
      const numberOfSheets = sheets.length

      return (
        <Fragment key={sheet.id}>
          <h3 className="mb-2 text-lg">
            <span>Sheet {sheetIdx + 1}: </span>
            <span className="font-bold">{sheet.name}</span>
          </h3>

          <div className="mb-3 text-lg">
            {extractedRowsOrColumns > 0 ? (
              <div className="flex items-center">
                <CheckCircleIcon className="w-8 text-otto-green-900" />
                <p className="ml-2">
                  {extractedRowsOrColumns}{" "}
                  {extractedRowsOrColumns === 1 ? "row" : "rows"} across{" "}
                  {numberOfTables} {numberOfTables === 1 ? "table" : "tables"}
                </p>
              </div>
            ) : (
              <div className="flex items-center">
                <EmptyIcon className="w-8 text-otto-grey-700" />
                <p className="ml-2">No data included</p>
              </div>
            )}
          </div>

          {numberOfSheets !== sheetIdx + 1 && (
            <hr className="mb-3 border-0 border-b border-otto-grey-300" />
          )}
        </Fragment>
      )
    })}
  />
)

export default ExtractionModal

import { AxiosInstance, AxiosResponse } from "axios"

import { DataPoint, DataPoints } from "./common/dataPoints"
import { SOV, Sheet, SheetWithContents, Table } from "./common/types"

export type ApiClient = AxiosInstance

export type ApiResponse<T> = AxiosResponse<T>

/**
 * Load the config to boot the app
 */
export interface Settings {
  accepted_document_types: string[]
  size_limit_mb: number
  redirect_uri: string
  failure_uri: string
  intercom: {
    app_id: string
    user_email: string
    user_hmac: string
  } | null
}

export const getSettings = (
  client: ApiClient,
  token: string,
): Promise<ApiResponse<Settings>> =>
  client.get("/api/sov/settings", {
    params: {
      signing_token: token,
    },
  })

export interface SOVStubCreation {
  id: SOV["id"]
  status: SOV["status"]
  redirect_uri: string
  failure_uri: string
}

export const startStubFlow = (
  client: ApiClient,
  token: string,
): Promise<ApiResponse<SOVStubCreation>> =>
  client.post(
    "/api/sov/stub",
    {},
    {
      params: {
        signing_token: token,
      },
    },
  )

/**
 * Upload a new file and mark it as confirmed or failed
 */
export interface NewDocumentRequest {
  name: string
  mimetype: string
  extension: string
}

export interface UploadUrlResponse {
  id: string
  sov_id: string
  url: string
  fields: Record<string, string>
  redirect_uri: string
  failure_uri: string
}

export const startUploadFlow = (
  client: ApiClient,
  token: string,
  body: NewDocumentRequest,
): Promise<ApiResponse<UploadUrlResponse>> =>
  client.post(`/api/sov/upload`, body, {
    params: {
      signing_token: token,
    },
  })

export interface SOVConfig {
  id: SOV["id"]
  datapoints: DataPoints
}

export const getConfig = (
  client: ApiClient,
  sovId: SOV["id"],
): Promise<ApiResponse<SOVConfig>> => client.get(`/api/sov/${sovId}/config`)

export const confirmUpload = (
  client: ApiClient,
  sovId: SOV["id"],
): Promise<ApiResponse<string>> => client.post(`/api/sov/${sovId}/confirm`)

export const failUpload = (
  client: ApiClient,
  sovId: SOV["id"],
): Promise<ApiResponse<string>> => client.post(`/api/sov/${sovId}/failed`)

/**
 * Get the processing status of an SOV file
 */
export interface UploadStatus {
  document_status: "uploading" | "uploaded" | "failed" | "extracted"
}

export const getStatus = (
  client: ApiClient,
  sovId: SOV["id"],
  documentId: string,
): Promise<ApiResponse<UploadStatus>> =>
  client.get(`/api/sov/${sovId}/ready/${documentId}`)

/**
 * Load the extracted tables
 */
export interface ExtractionsResult {
  id: string
  tables: Table[]
  sheets: Sheet[]
}

export const getExtractions = (
  client: ApiClient,
  sovId: SOV["id"],
): Promise<ApiResponse<ExtractionsResult>> =>
  client.get(`/api/sov/${sovId}/tables`)

/**
 * Load individual sheets
 */
export const getSheetWithContents = (
  client: ApiClient,
  sovId: SOV["id"],
  sheetId: Sheet["id"],
): Promise<ApiResponse<SheetWithContents>> =>
  client.get(`/api/sov/${sovId}/sheet/${sheetId}`)

/**
 * CRUD individual tables
 */
export const createTable = (
  client: ApiClient,
  sovId: SOV["id"],
  table: Omit<Table, "id">,
): Promise<ApiResponse<Table>> => client.post(`/api/sov/${sovId}/table`, table)

export const updateTables = (
  client: ApiClient,
  sovId: SOV["id"],
  tables: Table[],
): Promise<ApiResponse<Table[]>> =>
  client.put(`/api/sov/${sovId}/tables`, tables)

export const deleteTable = (
  client: ApiClient,
  sovId: SOV["id"],
  tableId: Table["id"],
): Promise<ApiResponse<null>> =>
  client.delete(`/api/sov/${sovId}/table/${tableId}`)

export const extractHeaderMapping = (
  client: ApiClient,
  sovId: SOV["id"],
  tableHeader: unknown[],
): Promise<
  ApiResponse<{
    extracted: Table["extracted"]
    header: unknown[]
  }>
> => client.post(`/api/sov/${sovId}/header_extract`, tableHeader)

/**
 * Submit the user's finalised data
 */
export interface FinalisedAsset {
  field: DataPoint["field"]
  type: DataPoint["type"]
  value: unknown
}

export const finaliseSOVData = (
  client: ApiClient,
  sovId: SOV["id"],
  assets: FinalisedAsset[][],
): Promise<ApiResponse<unknown>> =>
  client.post(`/api/sov/${sovId}/finalise`, assets)

export const finaliseSOVStubData = (
  client: ApiClient,
  sovId: SOV["id"],
  assets: FinalisedAsset[][],
): Promise<ApiResponse<unknown>> =>
  client.post(`/api/sov/stub/${sovId}/finalise`, assets)

export const completeFlow = (
  client: ApiClient,
  sovId: SOV["id"],
): Promise<ApiResponse<unknown>> => client.post(`/api/sov/${sovId}/complete`)

/**
 * Search results for countries or currencies
 */
export interface ApiDataSearchResult {
  key: string
  value: string
}

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"

import { ApiClient, ApiDataSearchResult } from "src/api"

import { SelectAsync, SelectAsyncRefType } from "@appia/ui-components"

import { ICellEditorParams } from "@ag-grid-community/core"
import { ICellEditorReactComp } from "@ag-grid-community/react"

import { DataPointCountry, DataPointCurrency } from "src/common/dataPoints"
import { RowData } from "./columns"
import { parseValue } from "src/common/validation"

export interface SelectCellEditorParams {
  apiClient: ApiClient
  dataPoint: DataPointCountry | DataPointCurrency
  mapResultToLabel: (res: ApiDataSearchResult) => string
  mapResultToReturnValue: (res: ApiDataSearchResult) => string
  placeholder: string
}

const SelectCellEditor = forwardRef<
  ICellEditorReactComp,
  ICellEditorParams<RowData, unknown> & SelectCellEditorParams
>(
  (
    {
      apiClient,
      column,
      dataPoint,
      mapResultToLabel,
      mapResultToReturnValue,
      placeholder,
      stopEditing,
      charPress,
      value,
    },
    ref,
  ) => {
    const inputRef = useRef<SelectAsyncRefType<ApiDataSearchResult>>(null)

    const initialValue = useMemo<ApiDataSearchResult | null>(() => {
      const parseResult = parseValue(dataPoint, value)
      if (parseResult.status === "valid") {
        const { parsedValue } = parseResult

        const option = Object.entries(dataPoint.options).find(
          ([k, v]) => k === parsedValue || v === parsedValue,
        )

        if (option) {
          return { key: option[0], value: option[1] }
        }
      }

      return null
    }, [dataPoint, value])

    const [selectedValue, setSelectedValue] =
      useState<ApiDataSearchResult | null>(initialValue)

    // See example at
    // https://www.ag-grid.com/react-data-grid/component-cell-editor/#simple-cell-editor
    useImperativeHandle<ICellEditorReactComp, ICellEditorReactComp>(
      ref,
      () => ({
        // When cell editing ends, report the new cell value to ag-grid. If the
        // user hasn't selected a new value, return the old one.
        getValue: () =>
          selectedValue ? mapResultToReturnValue(selectedValue) : value,
      }),
      [mapResultToReturnValue, selectedValue, value],
    )

    // Focus the dropdown when this component is mounted (i.e. when the user
    // starts editing this cell)
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [])

    // Tell ag-grid to stop editing this cell once the value has changed, meaning
    // the user has selected a dropdown item. This will unmount this component. We
    // do this in a `useEffect` to guarantee that `selectedValue` will be up to
    // date by the time editing ends
    useEffect(() => {
      if (selectedValue?.key !== initialValue?.key) {
        stopEditing()
      }
    }, [initialValue, selectedValue, stopEditing])

    return (
      <div
        // Match the column width so that the dropdown appears to be "full width"
        // within the cell, even though it's in a popup container
        style={{
          width: `${column.getActualWidth()}px`,
        }}
      >
        <SelectAsync
          defaultMenuIsOpen
          inputRef={inputRef}
          loadResults={async query => {
            const { data: results } = await apiClient.get(
              `${dataPoint.endpoint}?q=${query}`,
            )
            return results
          }}
          mapResultToLabel={mapResultToLabel}
          mapResultToValue={result => result.key}
          placeholder={placeholder}
          selectedValue={selectedValue}
          truncateItems={false}
          onSelect={setSelectedValue}
          defaultInputValue={charPress ?? undefined}
        />
      </div>
    )
  },
)

export default SelectCellEditor

import { FC, Fragment, useEffect, useId, useState } from "react"

import { Callout, Checkbox, TrashIcon } from "@appia/ui-components"

import ErrorCorrectionSidebarTemplate from "./ErrorCorrectionSidebarTemplate"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { CellStatus, SOVAssets } from "../../types"
import { mkEmptyAssets } from "../../assets"
import { RowStatusCounts } from "../statuses"

const shouldUpdateCheckedStates = (
  checkedStates: Record<DataPoint["field"], boolean>,
  dataPointsWithErrors: DataPoint[],
): boolean =>
  Object.keys(checkedStates).length !== dataPointsWithErrors.length ||
  dataPointsWithErrors.some(({ field }) => checkedStates[field] === undefined)

const InvalidRowsErrorCorrectionSidebar: FC<{
  cellStatuses: CellStatus[]
  dataPoints: DataPoints
  onApply: (f: (a: SOVAssets) => SOVAssets) => void
  onBack: () => void
  rowStatusCounts: RowStatusCounts
}> = ({ cellStatuses, dataPoints, onApply, onBack, rowStatusCounts }) => {
  const dataPointsWithErrors = dataPoints.filter(
    ({ field }) => rowStatusCounts.dataPoints[field] > 0,
  )

  const [checkedStates, setCheckedStates] = useState<
    Record<DataPoint["field"], boolean>
  >(
    dataPointsWithErrors.reduce(
      (acc, { field }) => ({ ...acc, [field]: false }),
      {},
    ),
  )

  // When the list of `dataPointsWithErrors` changes externally, we need to
  // update our state. Otherwise we may show checkboxes for errors that no
  // longer exist or fail to show checkboxes for new errors
  useEffect(() => {
    if (shouldUpdateCheckedStates(checkedStates, dataPointsWithErrors)) {
      setCheckedStates(cs =>
        dataPointsWithErrors.reduce(
          (acc, { field }) => ({ ...acc, [field]: cs[field] ?? false }),
          {},
        ),
      )
    }
  }, [checkedStates, dataPointsWithErrors])

  const hasErrors = rowStatusCounts.invalid > 0

  const noneSelected = dataPointsWithErrors.every(
    ({ field }) => !checkedStates[field],
  )

  const baseId = useId()

  return (
    <ErrorCorrectionSidebarTemplate
      heading="Invalid rows"
      ctaType="delete"
      ctaDisabled={!hasErrors || noneSelected}
      onApply={() => {
        const rowIdsToRemove = new Set(
          cellStatuses
            .filter(
              c =>
                c.status === "invalid" && checkedStates[c.dataPointId] === true,
            )
            .map(c => c.rowIdx),
        )

        onApply(assets => {
          const filteredAssets = assets.filter(
            (_asset, i) => !rowIdsToRemove.has(i),
          )

          return filteredAssets.length === 0
            ? mkEmptyAssets(dataPoints, 1)
            : filteredAssets
        })
      }}
      onBack={onBack}
    >
      {hasErrors ? (
        <div className="grid w-full grid-cols-[auto,1fr,auto] items-center gap-x-4 gap-y-8">
          {dataPointsWithErrors.map(dp => {
            const id = `${baseId}-${dp.field}`
            return (
              <Fragment key={dp.field}>
                <TrashIcon className="w-6 shrink-0" />

                <label htmlFor={id}>Delete invalid {dp.label} rows</label>

                <Checkbox
                  id={id}
                  checked={checkedStates[dp.field] ?? false}
                  onChange={v => {
                    setCheckedStates(cs => ({ ...cs, [dp.field]: v }))
                  }}
                />
              </Fragment>
            )
          })}
        </div>
      ) : (
        <Callout type="success">All rows are valid</Callout>
      )}
    </ErrorCorrectionSidebarTemplate>
  )
}

export default InvalidRowsErrorCorrectionSidebar

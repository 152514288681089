import { FC, PropsWithChildren } from "react"
import classNames from "classnames"

import { ExclamationCircleIcon } from "./index"

export interface TipBannerProps {
  id?: string
  className?: string
}

const TipBanner: FC<PropsWithChildren<TipBannerProps>> = ({
  children,
  id,
  className,
}) => (
  <div
    id={id}
    className={classNames(
      "flex items-start gap-2 rounded-lg border border-otto-pop-800 bg-otto-pop-50 py-1 px-2 text-base text-otto-pop-900",
      className,
    )}
  >
    <ExclamationCircleIcon className="w-5 shrink-0" label="Tip" />

    <p>{children}</p>
  </div>
)

export default TipBanner

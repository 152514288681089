import { FC } from "react"

import { ArrowLeftIcon, Button, Card, PlusIcon } from "@appia/ui-components"

import TableCard, { ManualOrMappedCurrency } from "./TableCard"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import { Sheet, SheetWithContents, Table } from "src/common/types"
import { TableBounds } from "./types"

import { useFocusHeading } from "src/common/hooks"

const StepTwoSidebar: FC<{
  dataPoints: DataPoints
  onAddTable: (sheetId: Sheet["id"]) => void
  onChangeTableCurrency: (
    tableId: Table["id"],
    c: ManualOrMappedCurrency,
  ) => void
  onChangeTableMapping: (
    tableId: Table["id"],
    field: DataPoint["field"],
    indices: number[],
  ) => void
  onDeleteTable: (tableId: Table["id"]) => void
  onGoToDataPoint: (tableId: Table["id"], dpId: DataPoint["field"]) => void
  onJumpToTable: (sheetId: Sheet["id"], tableId: Table["id"]) => void
  onSaveTables: (table: Table[]) => void
  setTableBounds: (tableId: Table["id"], bounds: TableBounds) => void
  setTablePreview: (tp: TableBounds | null) => void
  setActiveTableId: (tableId: Table["id"]) => void
  onClickBack: () => void
  sheet: SheetWithContents
  tables: Table[]
}> = ({
  dataPoints,
  onAddTable,
  onChangeTableCurrency,
  onChangeTableMapping,
  onDeleteTable,
  onGoToDataPoint,
  onJumpToTable,
  onSaveTables,
  setTableBounds,
  setTablePreview,
  setActiveTableId,
  onClickBack,
  sheet,
  tables,
}) => {
  const sheetTables = tables.filter(t => t.sheet_id === sheet.id)
  const sheetHasNoTables = sheetTables.length < 1
  const sheetHasData = sheet.contents.length > 0
  const backButtonLabel = "Return to extraction overview"
  const showConfirmButton = sheetTables.length > 0 && sheetHasData
  const sheetHasMultipleTables = sheetTables.length > 1
  const confirmButtonLabel = `Confirm ${
    sheetHasMultipleTables ? "tables" : "table"
  }`

  const headingRef = useFocusHeading()

  return (
    <Card padding={0} className="flex h-full flex-col">
      <button
        data-cy="back-to-sheet-select"
        onClick={onClickBack}
        title={backButtonLabel}
        aria-label={backButtonLabel}
        className="flex cursor-pointer items-center gap-4 rounded-t-lg border-b border-otto-grey-300 bg-white p-4"
      >
        <ArrowLeftIcon className="w-6" />

        <span className="text-xl font-bold">{sheet.name}</span>
      </button>

      <h3 ref={headingRef} tabIndex={-1} className="sr-only">
        {sheet.name}
      </h3>

      <div className="relative h-0 flex-grow overflow-y-auto">
        <div className="my-4 grid gap-4 p-2">
          {sheetTables.map((table, i) => (
            <TableCard
              key={table.id}
              dataPoints={dataPoints}
              numGlobalTables={tables.length}
              onChangeCurrency={manualOrMapped =>
                onChangeTableCurrency(table.id, manualOrMapped)
              }
              onChangeMapping={(field, indices) =>
                onChangeTableMapping(table.id, field, indices)
              }
              onDeleteTable={() => onDeleteTable(table.id)}
              onGoToDataPoint={dpId => onGoToDataPoint(table.id, dpId)}
              onJumpToTable={() => onJumpToTable(sheet.id, table.id)}
              setTableBounds={bounds => setTableBounds(table.id, bounds)}
              setTablePreview={setTablePreview}
              setActiveTableId={setActiveTableId}
              sheet={sheet}
              table={table}
              tableName={`Table ${i + 1}`}
            />
          ))}

          {sheetHasNoTables && (
            <p className="mx-4">
              This sheet has no {sheetHasData ? "tables" : "data"}.
            </p>
          )}

          {sheetHasData && (
            <Button
              data-cy="add-table-button"
              label="Add table"
              size="small"
              icon={{ position: "left", icon: <PlusIcon /> }}
              theme="night"
              style="text"
              onClick={() => onAddTable(sheet.id)}
            />
          )}
        </div>
      </div>

      {showConfirmButton && (
        <div className="sticky bottom-0 rounded-b-lg border border-otto-grey-300 bg-white p-4">
          <Button
            label={confirmButtonLabel}
            aria-label={confirmButtonLabel}
            size="regular"
            theme="night"
            style="outlined"
            stretch="space-around"
            onClick={() => {
              onSaveTables(sheetTables)
              onClickBack()
            }}
          />
        </div>
      )}
    </Card>
  )
}

export default StepTwoSidebar

import { FC, useId, useState } from "react"
import { useMatch, useNavigate } from "react-router"

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  CheckFilledCircleIcon,
  IconLink,
} from "@appia/ui-components"

import ModalTemplate from "./ModalTemplate"

import classNames from "classnames"
import KiLogo from "./KiLogo"

type PageStatus = "not_started" | "in_progress" | "complete"

const UPLOAD_LABEL = "Upload SOV"
const EXTRACTION_LABEL = "Tag SOV data"
const SUMMARY_LABEL = "Review & submit"

const MenuItem: FC<{
  status: PageStatus
  label: string
  stepNum: number
}> = ({ status, label, stepNum }) => (
  <li
    data-cy="navigation-bar-item"
    className={classNames("flex items-center gap-2 px-2", {
      "border-b border-b-white text-white": status === "in_progress",
      "text-otto-grey-600": status === "not_started",
      "text-otto-green-700": status === "complete",
    })}
  >
    {status === "complete" ? (
      <CheckFilledCircleIcon className="w-4" label="Complete" />
    ) : (
      <div
        aria-label={`Step ${stepNum}. ${
          status === "in_progress" ? "Current" : "Future"
        } step`}
        className="h-4 w-4 rounded-full border border-current text-center text-sm leading-[1]"
      >
        {stepNum}
      </div>
    )}

    <span className="truncate font-bold">{label}</span>
  </li>
)

// TODO Potentially add this version of the text CTA to the component library
const DarkModeTextButton: FC<
  Pick<Parameters<typeof Button>[0], "icon" | "label" | "onClick">
> = props => (
  <Button
    {...props}
    size="small"
    style="text"
    theme="night"
    className="text-white hover:!text-otto-grey-400"
  />
)

const MenuBar: FC<{
  showBackModal?: boolean
  onSkip?: () => void
}> = ({ showBackModal = true, onSkip }) => {
  const navigate = useNavigate()

  const isUpload = useMatch("/upload") !== null
  const isExtraction = useMatch("/:sovId/extraction") !== null
  const isUploadSummary = useMatch("/:sovId/summary") !== null
  const isManualSummary = useMatch("/:sovId/manual") !== null

  const headingId = useId()
  const [isGoBackModalOpen, setGoBackModalOpen] = useState<boolean>(false)

  // Go back one page
  const navigateBack = (): void => navigate(-1)

  const onModalContinue: () => void = () => {
    setGoBackModalOpen(false)
    navigateBack()
  }

  return (
    <header
      aria-labelledby={headingId}
      className="grid max-w-full grid-cols-[1fr,auto,1fr] items-center gap-4 bg-otto-night px-4 text-white shadow-md forced-colors:border-b forced-colors:border-otto-grey-400"
    >
      <div className="flex items-center justify-between gap-2 justify-self-start py-1">
        <IconLink
          label="Go to Ki pipeline"
          href="https://ki-insurance.com/ki/pipeline/available"
          icon={<KiLogo />}
          size={10}
          color="text-white"
        />

        <DarkModeTextButton
          icon={{ position: "left", icon: <ArrowLeftIcon /> }}
          label="Back"
          onClick={() =>
            showBackModal ? setGoBackModalOpen(true) : navigateBack()
          }
        />
      </div>

      <ModalTemplate
        dataCy="extraction-page-go-back-modal"
        actionsReflowBreakpoint="sm"
        actions={[
          <Button
            key="cancel"
            style="outlined"
            label="Cancel"
            theme="night"
            onClick={() => setGoBackModalOpen(false)}
          />,
          <Button
            key="continue"
            style="filled"
            label="Continue"
            theme="night"
            onClick={onModalContinue}
          />,
        ]}
        title="Are you sure you want to go back?"
        isOpen={isGoBackModalOpen}
        onClose={() => setGoBackModalOpen(false)}
        content={
          <p className="text-lg">
            Any changes you have made on this screen will be lost.
          </p>
        }
      />

      <nav aria-label="Journey progress" className="self-stretch">
        <ol className="flex h-full items-stretch gap-4 text-base">
          <MenuItem
            label={UPLOAD_LABEL}
            status={isUpload ? "in_progress" : "complete"}
            stepNum={1}
          />

          {!isManualSummary && (
            <MenuItem
              label={EXTRACTION_LABEL}
              status={
                isUpload
                  ? "not_started"
                  : isExtraction
                  ? "in_progress"
                  : "complete"
              }
              stepNum={2}
            />
          )}

          <MenuItem
            label={SUMMARY_LABEL}
            status={
              isUploadSummary || isManualSummary ? "in_progress" : "not_started"
            }
            stepNum={isManualSummary ? 2 : 3}
          />
        </ol>
      </nav>

      <div className="justify-self-end">
        {onSkip && (
          <DarkModeTextButton
            icon={{ position: "right", icon: <ArrowRightIcon /> }}
            label="Enter data manually"
            onClick={onSkip}
          />
        )}
      </div>
    </header>
  )
}

export default MenuBar

import type { SVGComponent } from "./IconWrapper"

const CheckSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.705L19.3851 5.295L8.79508 15.875Z"
      fill="currentColor"
    />
  </svg>
)

export default CheckSVG

import { FC, ReactNode } from "react"

import {
  IconButton,
  Modal,
  ModalDescription,
  ModalTitle,
  XIcon,
} from "@appia/ui-components"
import classNames from "classnames"

type Breakpoint = "sm" | "lg"

const ModalTemplate: FC<{
  actions: ReactNode[]
  actionsReflowBreakpoint?: Breakpoint
  allowOverflow?: boolean
  className?: string
  content: ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  dataCy?: string
}> = ({
  actions,
  actionsReflowBreakpoint = "lg",
  allowOverflow,
  className,
  content,
  isOpen,
  onClose,
  title,
  dataCy,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    allowOverflow={allowOverflow}
    className={className}
  >
    <div className="relative" data-cy={dataCy}>
      {/* The close button is placed last in the source order because it should
          be the last interactive element the user tabs to. However, we want it
          to display visually in the top-right corner, so we use a grid layout
          here with an empty cell to 'reserve' some space for it.
          */}
      <ModalTitle className="mb-2 grid grid-cols-[1fr,2rem] gap-1 border-b border-otto-grey-300 p-4 text-2xl font-bold">
        {title}
      </ModalTitle>

      <ModalDescription as="div" className="mb-2 px-4 pt-2 pb-2">
        {content}
      </ModalDescription>

      <div
        className={classNames(
          "flex flex-col items-center justify-end gap-4 border-t border-otto-grey-300 p-4",
          { "sm:flex-row": actionsReflowBreakpoint === "sm" },
          { "lg:flex-row": actionsReflowBreakpoint === "lg" },
        )}
      >
        {actions}
      </div>

      <div className="absolute right-4 top-4">
        <IconButton label="Close dialog" icon={<XIcon />} onClick={onClose} />
      </div>
    </div>
  </Modal>
)

export default ModalTemplate

import { FC } from "react"
import "./Checkbox.css"

import classNames from "classnames"

export interface CheckboxProps {
  "data-cy"?: string
  checked: boolean
  id?: string
  onChange: (b: boolean) => void
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  "data-cy": dataCy,
  id,
  onChange,
}) => (
  <input
    type="checkbox"
    checked={checked}
    onChange={e => onChange(e.target.checked)}
    className={classNames(
      "ui-components-checkbox",
      "otto-focus h-6 w-6 cursor-pointer appearance-none rounded border border-otto-grey-900 bg-white",
      `checked:bg-otto-grey-900 checked:before:block checked:before:h-full checked:before:text-center checked:before:text-white`,
    )}
    data-cy={dataCy}
    id={id}
  />
)

export default Checkbox

import { FC } from "react"

const MagnifyingGlassIcon: FC<{ className: string }> = ({ className }) => (
  <svg
    role="img"
    focusable={false}
    aria-hidden
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="48" cy="48" r="48" fill="#EDEEEF" />
    <path
      d="M50.2425 50.4145L47.7148 52.9421L53.8484 59.0757L56.3761 56.5481L50.2425 50.4145Z"
      fill="#EDEEEF"
      stroke="#8D959E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.3378 56.4766L53.5431 59.2714C52.7275 60.0869 52.6201 61.3018 53.3032 61.9848L68.0463 76.728C68.7294 77.411 69.9442 77.3036 70.7598 76.4881L73.5546 73.6933C74.3701 72.8778 74.4775 71.6629 73.7944 70.9799L59.0513 56.2367C58.3682 55.5537 57.1534 55.6611 56.3378 56.4766Z"
      fill="#EDEEEF"
      stroke="#8D959E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.4799 56.9597C50.2383 56.9597 58.9597 48.2383 58.9597 37.4799C58.9597 26.7214 50.2383 18 39.4799 18C28.7214 18 20 26.7214 20 37.4799C20 48.2383 28.7214 56.9597 39.4799 56.9597Z"
      fill="#EDEEEF"
      stroke="#8D959E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5 51C46.9558 51 53 44.9558 53 37.5C53 30.0442 46.9558 24 39.5 24C32.0442 24 26 30.0442 26 37.5C26 44.9558 32.0442 51 39.5 51Z"
      stroke="#8D959E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export interface NoRowsOverlayProps {
  message: string
}

const NoRowsOverlay: FC<NoRowsOverlayProps> = ({ message }) => (
  <div className="flex flex-col items-center gap-4">
    <MagnifyingGlassIcon className="w-32 shrink-0" />

    <p className="text-base">{message}</p>
  </div>
)

export default NoRowsOverlay

import { FC } from "react"

import "./Tabs.css"
import classNames from "classnames"

import * as Tab from "@radix-ui/react-tabs"

export const Root: FC<Tab.TabsProps> = ({ children, ...props }) => (
  <Tab.Root activationMode="manual" orientation="horizontal" {...props}>
    {children}
  </Tab.Root>
)

export const List: FC<Tab.TabsListProps & { "aria-label": string }> = ({
  "aria-label": ariaLabel,
  children,
  className,
  ...props
}) => (
  <Tab.List
    className={classNames("flex overflow-x-auto overflow-y-clip", className)}
    aria-label={ariaLabel}
    {...props}
  >
    {children}
  </Tab.List>
)

interface TabsTriggerContainerProps {
  /** Whether the Trigger should grow to fill the available horizontal space. */
  grow?: boolean
}

export const Trigger: FC<Tab.TabsTriggerProps & TabsTriggerContainerProps> = ({
  children,
  className,
  grow = true,
  ...props
}) => (
  <Tab.Trigger
    className={classNames(
      "tabs-trigger otto-focus-inset relative text-center hover:cursor-pointer disabled:pointer-events-none forced-colors:border",
      { "flex-1": grow },
    )}
    {...props}
  >
    <span className={classNames("block whitespace-nowrap", className)}>
      {children}
    </span>
    <span
      aria-hidden
      className="tabs-trigger-active-highlight absolute inset-x-0 bottom-0 h-0.5 bg-otto-pop"
    ></span>
  </Tab.Trigger>
)

export const Content: FC<Tab.TabsContentProps> = ({ className, ...props }) => (
  <Tab.Content {...props} className={classNames("otto-focus", className)} />
)

import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { ApiClient } from "src/api"

import { DataPoint, DataPoints } from "src/common/dataPoints"
import {
  ASSET_UNIQUE_ID_FIELD,
  CellStatus,
  EditingView,
  SOVAssets,
} from "../types"

import { CellValueChangedEvent } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import { ClipboardModule } from "@ag-grid-enterprise/clipboard"
import { MenuModule } from "@ag-grid-enterprise/menu"
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection"
import { RichSelectModule } from "@ag-grid-enterprise/rich-select"

import { RowData, makeColumnDefs } from "./columns"
import { assetsToRowData, setValue } from "./data"

import SpreadsheetStatusBar from "src/components/SpreadsheetStatusBar"
import NoRowsOverlay, { NoRowsOverlayProps } from "./NoRowsOverlay"

import {
  fillOperation,
  getContextMenuItems,
  onCellClicked,
  onCellContextMenu,
  onCellDoubleClicked,
  onRangeSelectionChanged,
  processDataFromClipboard,
} from "./grid"

import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-alpine.css"
import "src/common/agGrid.css"
import "./index.css"

// Should ag-grid show the row at the given index?
export const shouldDisplayRow = (
  statusesForRow: CellStatus[],
  editingView: EditingView,
): boolean => {
  switch (editingView.mode) {
    case "default":
      return true
    case "allInvalid":
      return statusesForRow.some(c => c.status === "invalid")
    case "dataPoint":
      return statusesForRow.some(
        c =>
          c.status === "invalid" &&
          c.dataPointId === editingView.dataPoint.field,
      )
  }
}

const Spreadsheet: FC<{
  apiClient: ApiClient
  assets: SOVAssets
  cellStatuses: CellStatus[]
  dataPoints: DataPoints
  editingView: EditingView
  gridRef: RefObject<AgGridReact<RowData>>
  setAssets: Dispatch<SetStateAction<SOVAssets>>
}> = ({
  apiClient,
  assets,
  cellStatuses,
  dataPoints,
  editingView,
  gridRef,
  setAssets,
}) => {
  const [sumOfValues, setSumOfValues] = useState<string>("")

  const rowData = useMemo<RowData[]>(() => assetsToRowData(assets), [assets])

  const columnDefs = useMemo(
    () => makeColumnDefs(apiClient, dataPoints, cellStatuses),
    [apiClient, dataPoints, cellStatuses],
  )

  // Tell the grid when either the `editingView` or the underlying data changes
  // so that it can reapply the filtering logic and re-render the cells
  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.onFilterChanged()
      gridRef.current.api.refreshCells({ force: true })
    }
  }, [gridRef, editingView, assets])

  // We compute this ahead of time to avoid doing it every single time
  // `shouldDisplayRow` is called
  const cellStatusesPerRow: Record<number, CellStatus[]> = useMemo(
    () =>
      cellStatuses.reduce<Record<number, CellStatus[]>>((acc, status) => {
        const statusesForRow = acc[status.rowIdx] ?? []
        return { ...acc, [status.rowIdx]: [...statusesForRow, status] }
      }, {}),
    [cellStatuses],
  )

  // Show the `NoRowsOverlay` when there are no rows, and hide it otherwise.
  // Unfortunately ag-grid can't do this by itself because we're using an
  // 'external filter' that it doesn't keep track of
  useEffect(() => {
    const allRowsHidden =
      rowData.filter(row =>
        shouldDisplayRow(cellStatusesPerRow[row.rowIndex], editingView),
      ).length === 0

    if (gridRef.current?.api) {
      if (allRowsHidden) {
        gridRef.current.api.showNoRowsOverlay()
      } else {
        gridRef.current.api.hideOverlay()
      }
    }
  }, [cellStatuses, cellStatusesPerRow, editingView, gridRef, rowData])

  const onCellValueChanged = useCallback(
    (e: CellValueChangedEvent<RowData>) => {
      const {
        newValue,
        data: { rowIndex },
        colDef: { field },
      } = e

      if (field === undefined) {
        return
      }

      setAssets(as => setValue(field, rowIndex, newValue, as))
    },
    [setAssets],
  )

  let noRowsMessage: string
  switch (editingView.mode) {
    case "default":
      noRowsMessage = "No rows"
      break
    case "allInvalid":
      noRowsMessage = "No invalid rows"
      break
    case "dataPoint":
      noRowsMessage = `No invalid ${editingView.dataPoint.label} rows`
  }

  const noRowsOverlayComponentParams: NoRowsOverlayProps = {
    message: noRowsMessage,
  }

  const fieldToTypeMap = dataPoints.reduce((acc, dp) => {
    return { ...acc, [dp.field]: dp.type }
  }, {} as { [field: string]: DataPoint["type"] })

  return (
    <div className="otto-summary-screen ag-theme-alpine flex h-full w-full flex-col">
      <AgGridReact<RowData>
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        enableFillHandle
        fillOperation={params => fillOperation(params, fieldToTypeMap)}
        enableRangeSelection
        enterMovesDownAfterEdit
        getContextMenuItems={getContextMenuItems(dataPoints, setAssets)}
        getRowId={params => params.data[ASSET_UNIQUE_ID_FIELD]}
        onCellDoubleClicked={onCellDoubleClicked(dataPoints, setAssets)}
        onCellValueChanged={onCellValueChanged}
        onRangeSelectionChanged={e =>
          onRangeSelectionChanged(e, setSumOfValues)
        }
        processDataFromClipboard={processDataFromClipboard(
          dataPoints,
          setAssets,
        )}
        suppressMultiRangeSelection
        suppressRowHoverHighlight
        rowSelection="multiple"
        onCellClicked={onCellClicked}
        onCellContextMenu={onCellContextMenu}
        suppressRowClickSelection
        // Enable filtering
        isExternalFilterPresent={() => editingView.mode !== "default"}
        doesExternalFilterPass={node => {
          const { data } = node
          if (!data) {
            return true
          }

          return shouldDisplayRow(
            cellStatusesPerRow[data.rowIndex],
            editingView,
          )
        }}
        noRowsOverlayComponent={NoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        modules={[
          ClientSideRowModelModule,
          ClipboardModule,
          MenuModule,
          RangeSelectionModule,
          RichSelectModule,
        ]}
      />
      <SpreadsheetStatusBar sumOfValues={sumOfValues} />
    </div>
  )
}

export default Spreadsheet

import { FC, useEffect, useRef } from "react"

import { ModalOverlay, SpinnerIcon } from "@appia/ui-components"

const LoadingOverlay: FC<{ message: string }> = ({ message }) => {
  const ref = useRef<HTMLDivElement>(null)

  // Set focus once on mount
  useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <ModalOverlay
      bgColor="bg-white"
      isOpen
      onClose={() => {
        return
      }}
    >
      <div ref={ref} className="isolate inline-block text-center" tabIndex={-1}>
        <p className="mb-4 text-5xl font-bold" role="alert">
          {message}
        </p>
        <SpinnerIcon className="mx-auto w-12 text-otto-pop" />
      </div>
    </ModalOverlay>
  )
}

export default LoadingOverlay

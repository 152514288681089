import { FC, PropsWithChildren } from "react"

import MenuBar from "./MenuBar"

export interface PageWrapperProps {
  showBackModal?: boolean
  onSkip?: () => void
}

const PageWrapper: FC<PropsWithChildren<PageWrapperProps>> = ({
  showBackModal,
  onSkip,
  children,
}) => {
  return (
    <div className="grid h-screen grid-rows-[auto,minmax(0,1fr)]">
      <MenuBar showBackModal={showBackModal} onSkip={onSkip} />

      {children}
    </div>
  )
}

export default PageWrapper

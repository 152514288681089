import { FC, useId } from "react"

import {
  CheckCircleIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@appia/ui-components"

import { DataPoints } from "src/common/dataPoints"
import { EditingView, isDataPointWithEditingView } from "../../types"
import { RowStatusCounts } from "../statuses"

import { printCount } from "src/common/utils"
import { useFocusHeading } from "src/common/hooks"

import classNames from "classnames"

const ValidationButton: FC<{
  label: string
  numRows: number
  onClick: () => void
}> = ({ label, numRows, onClick }) => {
  return (
    <button
      data-cy={`${label}-invalid-rows-button`}
      className={classNames(
        "otto-focus-inset w-full bg-transparent p-4 text-left",
        // Because the container card has a border radius the last button needs
        // one too, or else its hover background won't quite line up with the
        // corners of the container
        "last-of-type:rounded-b-[calc(theme('borderRadius.lg')-1px)] hover:bg-otto-pop-50",
      )}
      onClick={onClick}
    >
      <div
        className="grid w-full grid-cols-[1rem,4rem,1fr,auto] items-center justify-start gap-4"
        aria-hidden
      >
        <ExclamationCircleIcon className="w-6 shrink-0 text-otto-red-600" />

        <span
          data-cy={`${label}-invalid-rows-copy`}
          className="text-otto-red-900"
        >
          {printCount(numRows, "row")}
        </span>

        <span>{label}</span>

        <ChevronRightIcon className="w-6" />
      </div>

      <span className="sr-only">
        Fix {label} errors for {printCount(numRows, "row")}
      </span>
    </button>
  )
}

const ValidationCard: FC<{
  dataPoints: DataPoints
  onFixErrorsClick: (v: EditingView) => void
  rowStatusCounts: RowStatusCounts
}> = ({ dataPoints, onFixErrorsClick, rowStatusCounts }) => {
  const {
    valid: numValidRows,
    invalid: numInvalidRows,
    dataPoints: dataPointsRowCounts,
  } = rowStatusCounts

  const headingId = useId()
  const headingRef = useFocusHeading()

  return (
    <section aria-labelledby={headingId} className="text-sm">
      <h3 className="sr-only" id={headingId} ref={headingRef} tabIndex={-1}>
        Validation status
      </h3>

      <p className="grid grid-cols-[1rem,4rem,1fr] items-center justify-start gap-4 p-4">
        <CheckCircleIcon className="w-6 shrink-0 text-otto-green-900" />

        <span data-cy="valid-rows-copy" className="text-otto-green-900">
          {printCount(numValidRows, "row")}
          <span className="sr-only">. </span>
        </span>

        <span>Valid</span>
      </p>

      {numInvalidRows > 0 && (
        <hr className="border-0 border-b border-otto-grey-300" />
      )}

      {dataPoints.filter(isDataPointWithEditingView).map(dp => {
        const numRows = dataPointsRowCounts[dp.field]

        if (numRows === 0) {
          return null
        }

        return (
          <ValidationButton
            key={dp.field}
            numRows={numRows}
            label={dp.label}
            onClick={() =>
              onFixErrorsClick({ mode: "dataPoint", dataPoint: dp })
            }
          />
        )
      })}

      {numInvalidRows > 0 && (
        <ValidationButton
          numRows={numInvalidRows}
          label="All Invalid"
          onClick={() => onFixErrorsClick({ mode: "allInvalid" })}
        />
      )}
    </section>
  )
}

export default ValidationCard

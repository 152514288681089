/**
 * Functions that manipulate the grid data
 */
import { ROW_INDEX_COLUMN_FIELD } from "src/common/agGrid"
import { DataPoint, DataPoints } from "src/common/dataPoints"
import { parseValue } from "src/common/validation"

import { mkEmptyAssets } from "../assets"
import { SOVAsset, SOVAssets } from "../types"
import { RowData } from "./columns"

/**
 * Convert SOVAssets into a format that can be read by ag-grid
 */
export const assetsToRowData = (assets: SOVAssets): RowData[] =>
  assets.map((asset, idx) => ({ ...asset, [ROW_INDEX_COLUMN_FIELD]: idx }))

/**
 * Update the given value at the given field and rowIdx
 */
export const setValue = (
  field: DataPoint["field"],
  rowIdx: number,
  value: unknown,
  assets: SOVAssets,
): SOVAssets =>
  assets.map((asset, i) =>
    i === rowIdx && field in asset ? { ...asset, [field]: value } : asset,
  )

/**
 * Insert some number of empty rows above or below the given rowIdx
 */
const insertRows =
  (dir: "above" | "below") =>
  (
    dataPoints: DataPoints,
    rowIdx: number,
    numRows: number,
    assets: SOVAssets,
  ): SOVAssets => {
    if (dataPoints.length === 0 || rowIdx > assets.length - 1 || rowIdx < 0) {
      return assets
    }

    const emptyAssets = mkEmptyAssets(dataPoints, numRows)

    return [
      ...assets.slice(0, dir === "above" ? rowIdx : rowIdx + 1),
      ...emptyAssets,
      ...assets.slice(dir === "above" ? rowIdx : rowIdx + 1),
    ]
  }

export const insertRowsAbove = insertRows("above")
export const insertRowsBelow = insertRows("below")

/**
 * Remove the selected rows from the assets
 */
export const removeRows = (
  dataPoints: DataPoints,
  selectedRows: SOVAssets,
  assets: SOVAssets,
): SOVAssets => {
  const remainingAssets = assets.filter(
    asset =>
      !selectedRows.find(
        selectedRow => selectedRow.assetUniqueId === asset.assetUniqueId,
      ),
  )

  return remainingAssets.length === 0
    ? mkEmptyAssets(dataPoints, 1)
    : remainingAssets
}

/**
 * Repeatedly copy a selection of values into the cells below the selection. If
 * the first set of cells below the selection is empty, continue until we hit a
 * set that contains data. If the first set of cells below the selection is
 * (partially) filled, continue until we hit an empty set. If the user selected
 * values that span multiple rows, iterate over that selection as we fill down.
 */
export const fillDown = (
  selectedDataPoints: DataPoint[],
  selectionStartRowIdx: number,
  selectionEndRowIdx: number,
  assets: SOVAssets,
): SOVAssets => {
  const minRowIdx = Math.min(selectionStartRowIdx, selectionEndRowIdx)
  const maxRowIdx = Math.max(selectionStartRowIdx, selectionEndRowIdx)

  const selectedAssets = assets.filter(
    (_, i) => i >= minRowIdx && i <= maxRowIdx,
  )

  const allSelectedAssetsEmpty = selectedAssets.every(asset =>
    selectedDataPoints.every(
      dp => parseValue(dp, asset[dp.field]).status === "empty",
    ),
  )

  const firstAssetBelowSelection: SOVAsset | null =
    assets[maxRowIdx + 1] ?? null

  // If the user has only selected empty values, do nothing. If the user has
  // selected the bottom row, so we can't fill down, so do nothing.
  if (allSelectedAssetsEmpty || firstAssetBelowSelection === null) {
    return assets
  }

  const firstAssetEmpty = selectedDataPoints.every(
    dp => parseValue(dp, firstAssetBelowSelection[dp.field]).status === "empty",
  )

  type FillType = "fillWhileEmpty" | "fillWhileNotEmpty"
  const fillType: FillType = firstAssetEmpty
    ? "fillWhileEmpty"
    : "fillWhileNotEmpty"

  let shouldTryFillValues = false

  return assets.map((asset, i) => {
    // When we reach the end of the user's selection, start trying to fill
    // values from the next row onwards
    if (i === maxRowIdx) {
      shouldTryFillValues = true
      return asset
    }

    if (i > maxRowIdx && shouldTryFillValues) {
      const valuesToOverrideStatuses = selectedDataPoints.map(
        dp => parseValue(dp, asset[dp.field]).status,
      )

      // We would like to fill in these values, but can we?
      const canFillValues =
        fillType === "fillWhileEmpty"
          ? valuesToOverrideStatuses.every(s => s === "empty")
          : valuesToOverrideStatuses.every(s => s !== "empty")

      // If we can't fill then we've reached the end of the fillable area
      if (!canFillValues) {
        shouldTryFillValues = false
        return asset
      }

      // If we can fill values, which values do we actually copy in? The user
      // may have selected multiple rows, which we need to iterate through
      const idxToFillFrom = (i - maxRowIdx - 1) % selectedAssets.length

      return selectedDataPoints.reduce(
        (acc, { field }) => ({
          ...acc,
          [field]: selectedAssets[idxToFillFrom][field],
        }),
        asset,
      )
    }

    // If this is an asset before the end of the user's selection, or we've
    // finished filling, don't change anything
    return asset
  })
}

/**
 * Map the partial `data` onto the `assets`, starting from the given rowIdx. If
 * necessary, insert new rows to accommodate the new data
 */
export const setDataAndInsertRows = (
  dataPoints: DataPoints,
  data: Partial<SOVAsset>[],
  startRowIdx: number,
  assets: SOVAssets,
): SOVAssets => {
  const lastGridRow = assets.length - 1

  if (dataPoints.length === 0 || startRowIdx < 0 || startRowIdx > lastGridRow) {
    return assets
  }

  const distanceFromEnd = lastGridRow - startRowIdx + 1
  const numRowsToAdd = Math.max(0, data.length - distanceFromEnd)

  const assetsWithExtraRows = insertRowsBelow(
    dataPoints,
    lastGridRow,
    numRowsToAdd,
    assets,
  )

  return assetsWithExtraRows.map((asset, i) =>
    i < startRowIdx ? asset : { ...asset, ...data[i - startRowIdx] },
  )
}

import type { SVGComponent } from "./IconWrapper"

const TrashSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 4L9.5 3H14.5L15.5 4H19V6H5V4H8.5ZM8 21C6.9 21 6 20.1 6 19V7H18V19C18 20.1 17.1 21 16 21H8ZM16 19V9H8V19H16Z"
      fill="currentColor"
    />
  </svg>
)

export default TrashSVG

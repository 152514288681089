import { FC, ReactElement, useContext, useEffect, useState } from "react"
import { RouterProvider } from "react-router-dom"

import * as RD from "@appia/remote-data"

import { Settings, getSettings } from "./api"
import { init as initCookieConsent } from "./CookieConsent"
import { ConfigContext } from "./contexts/ConfigContext"
import useApiClient from "./contexts/ApiClientContext"
import ErrorScreen from "./ErrorScreen"
import { SpinnerIcon } from "@appia/ui-components"
import { Router } from "@remix-run/router"
import TokenContext from "./contexts/TokenContext"

const ResolvedApp: FC<{ router: Router }> = ({ router }) => {
  if (
    window.SENTRY_ENVIRONMENT === "production" ||
    window.SENTRY_ENVIRONMENT === "staging"
  ) {
    initCookieConsent()
  }

  return <RouterProvider router={router} />
}

const Bootstrap: FC<{
  router: Router
}> = ({ router }): ReactElement => {
  const apiClient = useApiClient()
  const token = useContext(TokenContext)

  const [settingsReq, setSettingsReq] = useState<
    RD.RemoteData<Error, Settings>
  >(RD.NotAsked)

  const fetchSettings = async (): Promise<void> => {
    setSettingsReq(RD.Loading)
    try {
      const settings = await getSettings(apiClient, token)
      setSettingsReq(RD.Success(settings.data))
    } catch (e) {
      if (e instanceof Error) {
        setSettingsReq(RD.Failure(e))
      }
    }
  }

  useEffect(() => {
    if (token) {
      fetchSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return RD.match(
    settingsReq,
    <SpinnerIcon label="Loading" className="mx-auto mt-8 w-8" />,
    <SpinnerIcon label="Loading" className="mx-auto mt-8 w-8" />,
    settings => (
      <ConfigContext.Provider
        value={{
          acceptedDocumentTypes: settings.accepted_document_types,
          sizeLimitMb: settings.size_limit_mb,
          redirectUri: settings.redirect_uri,
          failureUri: settings.failure_uri,
          intercomEnabled: settings.intercom !== null,
          intercomId: settings.intercom?.app_id,
          intercomUserEmail: settings.intercom?.user_email,
          intercomUserHmac: settings.intercom?.user_hmac,
        }}
      >
        <ResolvedApp router={router} />
      </ConfigContext.Provider>
    ),
    () => <ErrorScreen message="Failed to load application configuration" />,
  )
}

export default Bootstrap

import { AriaRole, FC, ReactElement, forwardRef } from "react"
import { Link as RouterLink } from "react-router-dom"
import classNames from "classnames"

type Theme = "bordered" | "borderless"

const mkClassNames = (theme?: Theme): string =>
  classNames("inline-block otto-focus rounded", {
    "otto-focus-night": theme === "bordered",
  })

interface IconCallToActionBaseProps {
  className?: string
  label: string
  icon: ReactElement
  color?: string
  size?: number
  theme?: Theme
}

export interface IconLinkProps extends IconCallToActionBaseProps {
  href: string
  state?: unknown
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const IconLink = forwardRef<HTMLAnchorElement, IconLinkProps>(
  (
    { label, icon, href, state, onClick, color, size, theme, ...props },
    ref,
  ) => (
    <RouterLink
      to={href}
      aria-label={label}
      className={mkClassNames(theme)}
      title={label}
      onClick={onClick}
      state={state}
      ref={ref}
      {...props}
    >
      <IconCallToActionInner
        icon={icon}
        color={color}
        size={size}
        theme={theme}
      />
    </RouterLink>
  ),
)

export interface IconButtonProps extends IconCallToActionBaseProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  role?: AriaRole
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { label, icon, onClick, color, disabled, role, size, theme, ...props },
    ref,
  ) => (
    <button
      type="button"
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={label}
      className={mkClassNames(theme)}
      title={label}
      onClick={onClick}
      ref={ref}
      role={role}
      {...props}
    >
      <IconCallToActionInner
        icon={icon}
        color={color}
        disabled={disabled}
        size={size}
        theme={theme}
      />
    </button>
  ),
)

const IconCallToActionInner: FC<{
  icon: ReactElement
  color?: string
  disabled?: boolean
  size?: number
  theme?: Theme
}> = ({ icon, color, disabled = false, size = 8, theme = "borderless" }) => {
  const colorWhenNotDisabled = color || "text-otto-night"

  return (
    <div
      className={classNames(`w-${size} rounded-md`, {
        [colorWhenNotDisabled]: !disabled,
        "cursor-not-allowed text-otto-grey-500": disabled,
        "border border-otto-night p-1 ": theme === "bordered",
        "hover:bg-otto-night hover:text-otto-day":
          !disabled && theme === "bordered",
      })}
    >
      {icon}
    </div>
  )
}

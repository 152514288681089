import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"

import "./Toast.css"

import * as Base from "@radix-ui/react-toast"

import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from "./Icons"

export const Provider: FC<{ children: ReactNode }> = ({ children }) => (
  <Base.Provider duration={7000} swipeDirection="down">
    {children}
  </Base.Provider>
)

export const Viewport: FC<Base.ToastViewportProps> = ({
  className,
  ...props
}) => (
  <Base.Viewport
    {...props}
    className={classNames(
      className,
      // This z-index should be higher than the `Modal`'s, so that any toasts
      // will appear on top of the modal overlay
      "z-[1001] py-[var(--toast-viewport-padding)]",
    )}
  />
)

export type ToastType = "success" | "error"

export const Toast: FC<{
  message: ReactNode
  onOpenChange: (b: boolean) => void
  open: boolean
  type: ToastType
}> = ({ message, open, onOpenChange, type }) => {
  let Icon: typeof XIcon, bgColor: string
  switch (type) {
    case "success":
      Icon = CheckCircleIcon
      bgColor = "bg-otto-night"
      break
    case "error":
      Icon = ExclamationCircleIcon
      bgColor = "bg-otto-red-700"
      break
  }

  return (
    <Base.Root
      open={open}
      onOpenChange={onOpenChange}
      type="foreground"
      className={classNames(
        "toast-root",
        "otto-focus flex w-fit min-w-[20rem] items-start gap-2 rounded p-4 text-white shadow-lg forced-colors:border",
        bgColor,
      )}
    >
      <Icon aria-hidden className="w-5" />

      <Base.Description className="text-base">{message}</Base.Description>

      <Base.Close
        aria-label="Close notification"
        title="Close"
        className="otto-focus ml-auto rounded"
      >
        <XIcon className="w-5" />
      </Base.Close>
    </Base.Root>
  )
}

export const useToastState = (): {
  open: boolean
  onOpenChange: (b: boolean) => void
  triggerToast: () => void
} => {
  const [open, setOpen] = useState<boolean>(false)

  const timerRef = useRef(0)

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  const triggerToast = useCallback(() => {
    setOpen(false)
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setOpen(true)
    }, 100)
  }, [timerRef, setOpen])

  return { open, onOpenChange: setOpen, triggerToast }
}

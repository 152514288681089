import { FC } from "react"
import { Icon } from "@appia/ui-components"
import classNames from "classnames"

const SidebarStatus: FC<{
  message: string
  Icon: Icon
  textColor: string
  iconColor: string
  label?: string
}> = ({ message, Icon, textColor, iconColor, label }) => (
  <p
    data-cy="sidebar-status-message"
    className={classNames("flex items-center text-sm", textColor)}
  >
    <Icon
      label={label}
      className={classNames("mr-3 w-6 shrink-0", iconColor)}
      aria-hidden
    />
    <span>{message}</span>
  </p>
)

export default SidebarStatus

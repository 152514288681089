import { AriaRole, FC, ReactNode } from "react"

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "./index"

import classNames from "classnames"

export type CalloutType = "error" | "information" | "success" | "warning"

export type CalloutStyle = "outlined" | "filled"

export type CalloutSize = "small" | "regular"

const Callout: FC<{
  children: ReactNode
  className?: string
  rounded?: boolean
  style?: CalloutStyle
  type: CalloutType
  size?: CalloutSize
  role?: AriaRole
  id?: string
}> = ({
  children,
  className,
  rounded = true,
  style = "outlined",
  type,
  size = "regular",
  role,
  id,
}) => {
  let Icon: typeof ExclamationCircleIcon
  let ariaRole = role
  let bgColor: string
  let borderColor: string
  let iconColor: string
  switch (type) {
    case "error":
      Icon = ExclamationCircleIcon
      ariaRole = role ?? "alert"
      bgColor = style === "filled" ? "bg-otto-red-50" : "bg-white"
      borderColor = "border-otto-bright-red"
      iconColor = "text-otto-bright-red"
      break

    case "information":
      Icon = InformationCircleIcon
      ariaRole = role ?? "status"
      bgColor = style === "filled" ? "bg-otto-pop-50" : "bg-white"
      borderColor = "border-otto-pop-800"
      iconColor = "text-otto-pop-800"
      break

    case "success":
      Icon = CheckCircleIcon
      ariaRole = role ?? "alert"
      bgColor = style === "filled" ? "bg-otto-green-50" : "bg-white"
      borderColor = "border-otto-deep-green"
      iconColor = "text-otto-deep-green"
      break

    case "warning":
      Icon = ExclamationCircleIcon
      ariaRole = role ?? "alert"
      bgColor = style === "filled" ? "bg-otto-yellow-50" : "bg-white"
      borderColor = "border-otto-yellow-600"
      iconColor = "text-otto-yellow-600"
      break
  }

  return (
    <div
      id={id}
      role={ariaRole}
      className={classNames(
        { "p-2 text-sm": size === "small" },
        { "p-4 text-base": size === "regular" },
        "flex items-start gap-2 border text-primary",
        { rounded: rounded },
        bgColor,
        borderColor,
        className,
      )}
    >
      <Icon className={classNames("w-5 shrink-0", iconColor)} aria-hidden />

      <div className="grid grow grid-cols-1 gap-4">{children}</div>
    </div>
  )
}

export default Callout

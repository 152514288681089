import { FC } from "react"

import ErrorCallout from "@appia/ui-components/src/Error"

const ErrorScreen: FC<{
  message: string
  error?: Error
}> = ({ message, error }) => {
  return (
    <section className="mx-auto mt-8 px-4">
      <ErrorCallout message={message} error={error} />
    </section>
  )
}

export default ErrorScreen

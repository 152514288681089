import { FC, Fragment, useEffect, useId, useState } from "react"

import { ApiDataSearchResult } from "src/api"
import useApiClient from "src/contexts/ApiClientContext"

import {
  ArrowRightAltIcon,
  Callout,
  ExclamationCircleIcon,
  SelectAsync,
} from "@appia/ui-components"

import ErrorCorrectionSidebarTemplate from "./ErrorCorrectionSidebarTemplate"

import {
  DataPoint,
  DataPointCountry,
  DataPointCurrency,
} from "src/common/dataPoints"
import { CellStatus, SOVAssets } from "../../types"

import {
  ReplacementState,
  applyReplacements,
  mkReplacementState,
} from "./replacements"

export const getUniqueInvalidValues = (
  assets: SOVAssets,
  cellStatuses: CellStatus[],
  field: DataPoint["field"],
): unknown[] =>
  cellStatuses
    .filter(c => c.status === "invalid" && c.dataPointId === field)
    .map(c => assets[c.rowIdx][field])
    .filter((v, i, self) => self.indexOf(v) === i)

const shouldUpdateReplacementStates = (
  replacementStates: ReplacementState<ApiDataSearchResult>[],
  uniqueInvalidValues: unknown[],
): boolean =>
  replacementStates.length !== uniqueInvalidValues.length ||
  replacementStates.some((r, i) => r.value !== uniqueInvalidValues[i])

const BoundedErrorCorrectionSidebar: FC<{
  assets: SOVAssets
  cellStatuses: CellStatus[]
  dataPoint: DataPointCountry | DataPointCurrency
  hasErrors: boolean
  mapResultToLabel: (r: ApiDataSearchResult) => string
  mapResultToReturnValue: (r: ApiDataSearchResult) => string
  onApply: (f: (a: SOVAssets) => SOVAssets) => void
  onBack: () => void
  placeholder: string
}> = ({
  assets,
  cellStatuses,
  dataPoint,
  hasErrors,
  mapResultToLabel,
  mapResultToReturnValue,
  onApply,
  onBack,
  placeholder,
}) => {
  const apiClient = useApiClient()

  const uniqueInvalidValues = getUniqueInvalidValues(
    assets,
    cellStatuses,
    dataPoint.field,
  )

  const [replacementStates, setReplacementStates] = useState(
    uniqueInvalidValues.reduce<ReplacementState<ApiDataSearchResult>[]>(
      (acc, v) => [...acc, mkReplacementState(v)],
      [],
    ),
  )

  // When the list of `uniqueInvalidValues` changes externally, we need to
  // update our state. Otherwise we may show dropdowns for errors that no longer
  // exist or fail to show dropdowns for new errors
  useEffect(() => {
    if (shouldUpdateReplacementStates(replacementStates, uniqueInvalidValues)) {
      setReplacementStates(rs =>
        uniqueInvalidValues.reduce<ReplacementState<ApiDataSearchResult>[]>(
          (acc, v) => {
            const existingState = rs.find(r => r.value === v)
            return [...acc, existingState ?? mkReplacementState(v)]
          },
          [],
        ),
      )
    }
  }, [replacementStates, uniqueInvalidValues])

  const baseId = useId()

  const noReplacementsSelected = replacementStates.every(
    r => r.replacement === null,
  )

  return (
    <ErrorCorrectionSidebarTemplate
      heading={dataPoint.label}
      ctaDisabled={!hasErrors || noReplacementsSelected}
      onApply={() =>
        onApply(assets =>
          applyReplacements(
            dataPoint.field,
            mapResultToReturnValue,
            replacementStates,
            assets,
          ),
        )
      }
      onBack={onBack}
    >
      {hasErrors ? (
        <div className="grid grid-cols-[auto,auto,1fr] items-center gap-x-4 gap-y-4">
          <p>Current</p>
          <p className="col-start-3">Replacement</p>

          {replacementStates.map((state, i) => {
            // Include `i` here since `displayValue` is not guaranteed to be unique
            const id = `${baseId}-${state.displayValue.replaceAll(
              " ",
              "-",
            )}-${i}`

            return (
              <Fragment key={id}>
                <label
                  data-cy={`${dataPoint.label}-label`}
                  htmlFor={id}
                  className="flex items-center gap-1"
                >
                  <ExclamationCircleIcon className="w-6 text-otto-red-600" />
                  <span className="sr-only">
                    {state.displayValue === ""
                      ? "Replace empty value"
                      : "Replace value"}
                  </span>{" "}
                  {state.displayValue}
                </label>

                <ArrowRightAltIcon className="w-6" />

                <SelectAsync
                  id={id}
                  loadResults={async query => {
                    const { data: results } = await apiClient.get(
                      `${dataPoint.endpoint}?q=${query}`,
                    )
                    return results
                  }}
                  mapResultToLabel={mapResultToLabel}
                  mapResultToValue={result => result.key}
                  placeholder={placeholder}
                  selectedValue={state.replacement}
                  truncateItems
                  onSelect={replacement => {
                    setReplacementStates(rs =>
                      rs.map(r =>
                        r.value === state.value
                          ? { ...r, replacement: replacement }
                          : r,
                      ),
                    )
                  }}
                />
              </Fragment>
            )
          })}
        </div>
      ) : (
        <Callout type="success">
          All rows are valid for {dataPoint.label}
        </Callout>
      )}
    </ErrorCorrectionSidebarTemplate>
  )
}

export default BoundedErrorCorrectionSidebar

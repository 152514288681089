import type { SVGComponent } from "./IconWrapper"

const PlusSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
      fill="currentColor"
    />
  </svg>
)

export default PlusSVG

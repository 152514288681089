import parseCurrency from "currency.js"

import { DataPoint } from "src/common/dataPoints"

export type ParseResult<T = unknown> =
  | { status: "empty" }
  | { status: "unparseable" }
  | { status: "invalid"; parsedValue: T }
  | { status: "valid"; parsedValue: T }

const isEmpty = (value: unknown): boolean =>
  value === null || value === undefined || value === ""

const parsePositiveNumber = (n: number): ParseResult<number> => {
  if (isNaN(n)) {
    return { status: "unparseable" }
  } else if (n < 0) {
    return { status: "invalid", parsedValue: n }
  } else {
    return { status: "valid", parsedValue: n }
  }
}

export const parseNumber = (value: unknown): ParseResult<number> => {
  if (isEmpty(value)) {
    return { status: "empty" }
  }

  // A valid number must be positive and not NaN
  if (typeof value === "number") {
    return parsePositiveNumber(value)
  }

  if (typeof value === "string") {
    // Any letters present = unparseable
    if (value.match(/.*[a-zA-Z].*/)) {
      return { status: "unparseable" }
    }

    // If we've got this far, do our best to parse the string. We use a
    // precision of 4 decimal places rather than 2 to avoid any rounding issues
    // when working with these numbers later
    const parsedValue = parseCurrency(value, { precision: 4 }).value
    return parsePositiveNumber(parsedValue)
  }

  // Any other data type = unparseable
  return { status: "unparseable" }
}

// `parseValue` gets called very frequently, so constantly checking through
// `dp.options` to validate bounded data points is actually a big performance
// hit. To cut down on duplicated work we use this cache / lookup table. Note
// that this assumes the data points are immutable once loaded into the app,
// since cache entries are only initialised the first time a data point is seen.
const parseValueCache: Record<
  DataPoint["field"],
  Partial<Record<string, string>>
> = {}

export const parseValue = (dp: DataPoint, value: unknown): ParseResult => {
  if (isEmpty(value)) {
    return { status: "empty" }
  }

  switch (dp.type) {
    case "country":
    case "currency": {
      if (typeof value !== "string") {
        return { status: "unparseable" }
      }

      if (!parseValueCache[dp.field]) {
        parseValueCache[dp.field] = {}
        for (const [k, v] of Object.entries(dp.options)) {
          parseValueCache[dp.field][k.toLowerCase()] = k
          parseValueCache[dp.field][v.toLowerCase()] = v
        }
      }

      const match = parseValueCache[dp.field][value.toLowerCase()]
      if (match) {
        return { status: "valid", parsedValue: match }
      }

      return { status: "unparseable" }
    }

    case "number": {
      return parseNumber(value)
    }

    case "date": {
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        value instanceof Date
      ) {
        const date = new Date(value)
        if (!isNaN(date.getTime())) {
          return { status: "valid", parsedValue: value }
        }
      }

      return { status: "unparseable" }
    }

    case "text":
      return { status: "valid", parsedValue: value }
  }
}
